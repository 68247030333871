
import api, { unwrapAxiosResult } from 'shared/utils/api'
import { API_URLs } from 'shared/utils/constants'
import { getArgs } from 'shared/utils/utils'
import { FetchApiErrorLogs } from './types'

const ERROR_LOGS_URL = API_URLs.POSTGRE.ERROR_LOGS

export const ERRORLOGS_POSTGRE_FETCHS: FetchApiErrorLogs = {
  getErrorLogs: payload => unwrapAxiosResult(
    api.get(`${ERROR_LOGS_URL}/${payload.sellerCode}?${getArgs(payload)}`)
  ),
  getErrorLogsDetails: payload => unwrapAxiosResult(
    api.get(`${ERROR_LOGS_URL}/${payload.sellerCode}/details?${getArgs(payload)}`)
  ),
}