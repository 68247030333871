import { Checkbox, Col, Divider, Form, Input, Radio, Row } from "antd"
import { FC } from "react"
import { useTranslation } from "react-i18next"

interface ISellerFormMailAlert {
    areFieldsEnabled: boolean
}

export const SellerFormMailAlert: FC<ISellerFormMailAlert> = ({ areFieldsEnabled }) => {
    const { t } = useTranslation()
    const gutter = 16;
    const colSpan = 5;

    return <>
        <Divider orientation="left">{t("Account.Details.MailAlert")}</Divider>
        <Row gutter={gutter}>
            <Col span={colSpan}>
                <span>{t("Account.Details.EmailFunction")}</span>
            </Col>
            <Col span={colSpan}>
                <Form.Item
                    name={['mailAlert', 'sendEmails']}>
                    <Radio.Group>
                        <Radio value={true}>{t("Generic.Actions.Yes")}</Radio>
                        <Radio value={false}>{t("Generic.Actions.No")}</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col>
                <span>{" " + t("Account.Details.SendEmailForOrdersWaitingForR1Validation")}</span>
            </Col>

            <Col>
                <Form.Item
                    name={['mailAlert', 'sendEmailForOrdersWaitingForR1Validation']}
                    valuePropName="checked"

                >
                    <Checkbox disabled={!areFieldsEnabled} />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={gutter}>
            <Col>
                <span>{" " + t("Account.Details.SendEmailForOrdersDenied")}</span>
            </Col>
            <Col>
                <Form.Item
                    name={['mailAlert', 'sendEmailForOrdersDenied']}
                    valuePropName="checked">
                    <Checkbox disabled={!areFieldsEnabled} />
                </Form.Item>
            </Col>
        </Row>


        <Row gutter={gutter}>
            <Col >
                <span>{t("Account.Details.EmailsUsers")} :</span>
            </Col>
            <Col span={5}>
                <Form.List name={['mailAlert', 'mailLists']}>
                    {fields =>
                        fields.map((field) =>
                            <Form.Item rules={[
                                {
                                    type: 'email',
                                    message: t("Generic.Validation.Email"),
                                },
                            ]} key={field.key} name={field.name}>
                                <Input disabled={!areFieldsEnabled} />
                            </Form.Item>
                        )
                    }
                </Form.List>
            </Col>
        </Row>
    </>
}