import { Button, Card, Col, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditableCell, EditableRow } from "shared/components/Columns/EditableTable";
import { twoDigitRounded } from 'shared/components/Render/TwoDigitsRounder';
import { useItemsColumns } from '../hooks/useItemsColumns';
import {
  actionCreators,
  orderByRefDataSelector, orderByRefLoadingSelector, OrderItem, orderItemsLoadingSelector, orderItemsSelector, UpdateOrderItems
} from '../stores';

export const ItemsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingItems = useSelector(orderItemsLoadingSelector);
  const loadingOrder = useSelector(orderByRefLoadingSelector)
  const orderItems = useSelector(orderItemsSelector);
  const orderByRef = useSelector(orderByRefDataSelector);
  let itemsColumns = useItemsColumns()

  const [itemsData, setItemsData] = useState<OrderItem[]>([])
  const [updateOrderItemsList, setUpdateOrderItemsList] = useState<UpdateOrderItems[]>([]);

  useEffect(() => {
    orderItems && setItemsData(orderItems.map((item: OrderItem) => {
      return {
        ...item,
        key: item.id,
        unitPrice: twoDigitRounded(item.price),
        amount: twoDigitRounded((item.price * (1 - item.discountRate / 100)) * item.deliveredQuantity),
      }
    }))
  }, [orderItems])

  itemsColumns = itemsColumns.map((column: any) => {
    if (!(orderByRef?.status === 0 && (column.dataIndex === 'wantedQuantity'))) {
      return column
    }

    const handlePriceChange = (record: OrderItem) => {
      const updateOrderItems: UpdateOrderItems = {
        orderId: orderByRef.id,
        orderItemId: record.id.toString(),
        fields: {
          wantedQuantity: record.wantedQuantity
        }
      };
      // Refresh data in table
      setItemsData(itemsData.map(x => (x.id.toString() === updateOrderItems.orderItemId) ? { ...x, wantedQuantity: updateOrderItems.fields.wantedQuantity } : x))

      setUpdateOrderItemsList([...updateOrderItemsList.filter((x: UpdateOrderItems) => x.orderItemId !== updateOrderItems.orderItemId), updateOrderItems])
    }

    return {
      ...column,
      onCell: (record: OrderItem) => ({
        record,
        editable: true,
        dataIndex: column.dataIndex,
        title: column.title,
        handleSave: handlePriceChange,
      }),
    }
  });

  const pagination = {
    showSizeChanger: true,
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = () => {
    dispatch(actionCreators.updateOrderItemsWantedQuantityList(updateOrderItemsList));
    setUpdateOrderItemsList([])
  }

  return (
    <Col span={24}>
      <Card>
        <Table
          components={components}
          size="small"
          loading={loadingItems || loadingOrder}
          dataSource={itemsData}
          columns={itemsColumns}
          pagination={pagination}
          rowClassName={() => 'editable-row'}
        />
        {orderByRef?.status === 0 && updateOrderItemsList.length > 0 && <Button type="primary" onClick={handleSave}>{t("Generic.Actions.Save")}</Button>}
      </Card>
    </Col>
  );
};
