import { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PageContainer } from 'shared/GlobalStyles';
import { BuyersClientCodesTable } from "../components/BuyerClientCodesTable"
import { BuyerForm } from "../components/BuyerForm"
import { BuyerInfo } from "../components/BuyerInfo"
import { Form } from "antd"
import {
  accountOracleActions,
  buyerByIdDataSelector,
  buyerByIdLoadingSelector,
} from '../stores';
import { ButtonBack } from 'shared/components/Buttons/ButtonBack';
import { CenteredSpin, DetailsRow, InfosRow } from 'shared/components/Styles';

/**
*  Details DMS Info page
*/
export const BuyerDetails: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const buyer = useSelector(buyerByIdDataSelector)
  const loading = useSelector(buyerByIdLoadingSelector)
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(accountOracleActions.loadBuyerById(id!))
  }, [])

  useEffect(() => {
    if (buyer)
      form.resetFields()
  }, [buyer])

  let dmsAccountInfo;

  if (buyer) {
    let tempDmsAccountInfo = buyer.dmsAccountInfos[0];
    tempDmsAccountInfo.managecreditlimit = buyer.dmsAccountInfos[0].managecreditlimit ? "true" : "false"

    if (buyer?.dmsAccountInfos[0].automaticordertransfer === "Y")
      tempDmsAccountInfo.automaticordertransfer = "true"
    else if (buyer?.dmsAccountInfos[0].automaticordertransfer === "N")
      tempDmsAccountInfo.automaticordertransfer = "false"


    dmsAccountInfo = tempDmsAccountInfo
  }

  return (
    <PageContainer>
      <div>
        <ButtonBack linkTo={"/accounts/tracking"} />
        <InfosRow gutter={30}>
          <h4>{t("Account.Details.BuyerInfo")}</h4>
          {buyer && !loading ? <BuyerInfo buyer={buyer} form={form} /> : <CenteredSpin size="large" />}
          <h4>{t("Account.Details.BuyerSettings")}</h4>
          {dmsAccountInfo && !loading && buyer ? (<DetailsRow><BuyerForm buyer={buyer} data={dmsAccountInfo} form={form} /></DetailsRow>) : <CenteredSpin size="large" />}

          <h4>{t("Account.Details.ClientCodesInValidity")}</h4>
          {buyer && !loading ? <BuyersClientCodesTable accountClientCodes={buyer.accountClientCodes} /> : <CenteredSpin size="large" />}
        </InfosRow>
      </div>
    </PageContainer >
  );
};