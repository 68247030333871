import { useTranslation } from "react-i18next";

export function useRegionalStockList() {
    const { t } = useTranslation();
    const regionalStockList = [
        "RPSDISBE",
        "RPSDISPL",
        "RPSDISRO",
        "RPSDISRU",
        "RPSDISUK",
        "DPRDIS",
        "RPSDISTR",
        "RPSDISDE",
        "ORDENAL",
        "RPSDISNL",
        "RPSDISAT",
        "RPSDISIT",
        "ORDENALPT",
        "LOCAL",
        "RPSDISCH"
    ]

    let result: { value: string, label: string }[] = [];

    regionalStockList.forEach(regionalStock => {
        result = [
            ...result,
            {
                value: regionalStock,
                label: t(`Account.RegionalStock.${regionalStock}`)
            }
        ]
    })

    return result
}