import styled from "styled-components";
import { Carousel, Button } from 'antd';
import imgHomeBG3Tab from './../../assets/img/home_background_3_tablet.jpg';
import imgHomeBG2Tab from './../../assets/img/home_background_2_tablet.jpg';
import imgHomeBG1Tab from './../../assets/img/home_background_1_tablet.jpg';
import imgHomeBG1 from './../../assets/img/home_background_1.jpg';
import imgHomeBG2 from './../../assets/img/home_background_2.jpg';
import imgHomeBG3 from './../../assets/img/home_background_3.jpg';
import { PageContainer } from '../../shared/GlobalStyles';
import { Link } from "react-router-dom";

export const HomePageContainer = styled(PageContainer)`
    padding: 0;
`

export const CustomCarousel = styled(Carousel)`
    .slick-list {
        @media only screen and (min-width: 1824px) {
            height: 92.5vh;
        }
        @media only screen and (max-width: 1824px) {
            height: 90vh;
        }
    }
`;

export const HomeBGWrapper = styled.div`
    display: flex;
`;

export const HomeBGContent = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 10;
    font-family: Read;
    top: 40%;
    height: 140px;
    position: absolute;
    background-color: hsla(0,0%,100%,.8);
    margin-left: 10px;
    padding: 5px;
    width: 640px;
`;

export const HomeBGText = styled.div`
    margin: 5px;
    height: auto;
    padding-bottom: 12px;
    font-family: Read;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0 0 0);
    white-space: pre-line;
`;

export const CustomButton = styled(Button)`
    width: 50%;
    margin-left: 5px;
    background-color: rgb(0 0 0);
    color: #e1e1e1;
    border: none;
    :hover {
        color: #fff;
        text-decoration: none!important;
        background-color: rgba(255, 204, 51, 0.7);
    }
`;

export const CustomLink = styled(Link)`
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-left: 5px;
    background-color: rgb(0 0 0);
    color: #e1e1e1;
    :hover {
        color: #fff;
        text-decoration: none!important;
        background-color: rgba(255, 204, 51, 0.7);
    }
`;

export const HomeBG1 = styled.img`
    content:url(${imgHomeBG1});
    width: 100%;
    @media only screen and (min-width: 768px) {
        content:url(${imgHomeBG1Tab});
    }
`;

export const HomeBG2 = styled.img`
    content:url(${imgHomeBG2});
    width: 100%;
    @media only screen and (min-width: 768px) {
        content:url(${imgHomeBG2Tab});
    }
`;

export const HomeBG3 = styled.img`
    content:url(${imgHomeBG3});
    width: 100%;
    @media only screen and (min-width: 768px) {
        content:url(${imgHomeBG3Tab});
    }
`;
