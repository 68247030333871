import styled from 'styled-components';
import { WarehouseCode, WarehouseColors } from './stores';

/**
 * All Orders Styles
 */

export const ThingsContainer = styled.div`
  background: #F0F2F5;
  border-radius: 2px;
  padding: .5em;
`;

export const OrderLink = styled.span`
  font-weight: bold;
  :hover {
    pointer-events: initial;
  }
`;

export const StatusFilterWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 195px;
`;

export const StatusFilterList = styled.div`
  display: flex;
`;

export const StatusFilterInput = styled.input`
  margin: 8px;
`;

export const ThingsHelperTable = styled.table`
  border: 1px solid lightgrey;
  border-collapse: collapse;
  & th, & td {
    border: 1px solid lightgrey;
    padding: 3px;
    border-collapse: collapse;
  }
`;

export const StockCaps = styled.span`
  height: 25px; 
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${(props: { stock: WarehouseCode }) => WarehouseColors[props.stock]};
`;

export const ItemsStocksWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemsStocksAvailableQuantity = styled.span`
  font-weight: bold;
  font-size: 12px; 
`;