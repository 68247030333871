import { Button } from "antd";
import { useTranslation } from "react-i18next";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";

interface ButtonProps {
    onClick: Function
}

export const ButtonSearch = (props: ButtonProps) => {
    return (
        <Button type="primary" onClick={() => props.onClick()} icon={<SearchOutlined />} size="small" style={{ width: 90 }} />
    )
}

export const ButtonReset = (props: ButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button onClick={() => props.onClick()} size="small" style={{ width: 90 }}>
            {t("Generic.Actions.Reset")}
        </Button>
    )
}