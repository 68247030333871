import i18n from 'assets/i18n';
import { Action } from 'redux-actions';
import { put, take, takeLatest, race, delay } from 'redux-saga/effects';
import { setBirCodeData } from 'screens/context/stores';
import { contextActions } from 'screens/context/stores/actions';
import { Order as OrderOracle, getByRef, actionCreators as ordersOracleActions } from "screens/orders_oracle/stores";
import { alertActions } from 'shared/components/RPAlert/stores/actions';
import { apiCallWrapper } from 'shared/utils/api';
import { ApiActionLaunch, IPage, UpdateRecordResponse } from 'shared/utils/types';
import { createSuccessMsg } from 'shared/utils/utils';
import { Type, ordersActions } from './actions';
import { FetchApiOrders, Order, OrderEvents, OrderItems, SearchByRef } from './types';
import { ORDERS_POSTGRE_FETCHS } from './utils';
import { Type as ContextType } from '../../context/stores/actions';

function* getOrders(action: Action<ApiActionLaunch<FetchApiOrders["getOrders"]>>) {
  try {
    const orders: IPage<Order> = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.getOrders, action.payload)
    yield put(ordersActions.getOrdersSuccess(orders))
  } catch (error) {
    yield put(ordersActions.getOrdersError(error))
  }
}

function* getOrder(action: Action<ApiActionLaunch<FetchApiOrders["getOrder"]>>) {
  try {
    const order: IPage<Order> = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.getOrder, action.payload);
    if (order && order.content && order.content[0]) {
      yield put(ordersActions.getOrderSuccess(order))
    } else {
      yield put(ordersActions.getOrderError())
    }
  } catch (error) {
    yield put(ordersActions.getOrderError(error))
  }
}

function* getOrderByRef(action: Action<SearchByRef>) {
  try {
    const ref = action.payload.payload.orderRef!;
    const navigate = action.payload.navigate;
    const orderPostgre: IPage<Order> = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.getOrderByRef, action.payload)

    if (orderPostgre && orderPostgre.content && orderPostgre.content[0]) {
      yield put(ordersActions.getOrderByRefSuccess(orderPostgre));
      setBirCodeData(orderPostgre.content[0].sellerCode);
      const { success, error, timeout } = yield race({
        success: take(ContextType.SET_MY_BIRCODE_DATA_SUCCESS),
        error: take(ContextType.SET_MY_BIRCODE_DATA_ERROR),
        timeout: delay(5000)
      });
      if (!!error || !!timeout || !success) {
        yield put(contextActions.setOrderPopoverMsg(i18n.t("Countries.ThisOrderDoesNotExistOrIsInaccessible")))
      }
      else {
        // order matched by reference, or superorder with only one order matched by reference
        if (orderPostgre.content.length === 1) {
          yield navigate(`/orders-RDS/tracking/by-ref/${ref}`);
          yield put(contextActions.setOrderPopoverVisible(false));
        }
        // superorder aggregating multiple orders matched by reference
        else {
          yield navigate(`/orders-RDS/tracking/by-ref/multiple-orders/${ref}`);
          yield put(contextActions.setOrderPopoverVisible(false));
          yield put(ordersActions.setOrdersFromSuperOrder(orderPostgre.content));
        }
        yield put(ordersActions.getOrderByRefError());
      }
    } else {
      const orderOracle: OrderOracle = yield apiCallWrapper(getByRef, ref)
      if (orderOracle) {
        yield put(ordersOracleActions.searchByRefSuccess(orderOracle));
        setBirCodeData(orderOracle.sellerBirCode);
        const { success, error, timeout } = yield race({
          success: take(ContextType.SET_MY_BIRCODE_DATA_SUCCESS),
          error: take(ContextType.SET_MY_BIRCODE_DATA_ERROR),
          timeout: delay(5000)
        });
        if (!!error || !!timeout || !success) {
          yield put(contextActions.setOrderPopoverMsg(i18n.t("Countries.ThisOrderDoesNotExistOrIsInaccessible")))
        }
        else {
          yield navigate(`/orders/tracking/${ref}`);
          yield put(contextActions.setOrderPopoverVisible(false));
        }
      } else {
        yield put(contextActions.setOrderPopoverMsg(i18n.t("Countries.ThisOrderDoesNotExistOrIsInaccessible")))
      }

      yield put(ordersActions.getOrderByRefError());
    }
  } catch (error) {
    yield put(ordersActions.getOrderByRefError(error))
  }
}

function* getOrderEvents(action: Action<ApiActionLaunch<FetchApiOrders["getOrderEvents"]>>) {
  try {
    const orderEvents: OrderEvents[] = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.getOrderEvents, action.payload)
    yield put(ordersActions.getOrderEventsSuccess(orderEvents))
  } catch (error) {
    yield put(ordersActions.getOrderEventsError(error))
  }
}

function* getOrderItems(action: Action<ApiActionLaunch<FetchApiOrders["getOrderItems"]>>) {
  try {
    const orderItems: OrderItems[] = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.getOrderItems, action.payload)
    yield put(ordersActions.getOrderItemsSuccess(orderItems))
  } catch (error) {
    yield put(ordersActions.getOrderItemsError(error))
  }
}

function* updateOrderListStatus(action: Action<ApiActionLaunch<FetchApiOrders["updateOrderList"]>>) {
  try {
    const res: UpdateRecordResponse<Order> = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.updateOrderList, action.payload)
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Generic.Alert.RowsUpdated", { numberRows: res.updatedRecordsNumber }))))
    yield put(ordersActions.updateOrderListSuccess(res))
  } catch (error) {
    yield put(ordersActions.updateOrderListError(error))
  }
}


function* updateOrderItemsWantedQuantity(action: Action<ApiActionLaunch<FetchApiOrders["updateOrderItemsListWantedQuantities"]>>) {
  try {
    const res: UpdateRecordResponse<OrderItems> = yield apiCallWrapper(ORDERS_POSTGRE_FETCHS.updateOrderItemsListWantedQuantities, action.payload)
    yield put(ordersActions.updateOrderListWantedQuantitySuccess(res))
  } catch (error) {
    yield put(ordersActions.updateOrderListWantedQuantityError(error))
  }
}

export default function* () {
  yield takeLatest(Type.GET_ORDERS, getOrders)
  yield takeLatest(Type.GET_ORDER, getOrder)
  yield takeLatest(Type.GET_ORDER_BY_REF, getOrderByRef)
  yield takeLatest(Type.GET_ORDER_EVENTS, getOrderEvents)
  yield takeLatest(Type.GET_ORDER_ITEMS, getOrderItems)
  yield takeLatest(Type.UPDATE_ORDER_LIST, updateOrderListStatus)
  yield takeLatest(Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY, updateOrderItemsWantedQuantity)
}