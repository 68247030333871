import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { FC } from "react";
import { useDispatch } from 'react-redux';
import { Order, UpdateOrderActionEnum, ordersActions } from '../stores';

import { useTranslation } from "react-i18next";

interface OrderTableMessageProps {
  message: string;
  order: Order;
}

export const OrderTableMessage: FC<OrderTableMessageProps> = ({ message, order }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleMarkOrderCommentAsRead = () => {
    if (order) {
      dispatch(ordersActions.updateOrderList({
        orderIds: [order.id],
        action: UpdateOrderActionEnum.MARK_AS_READ
      }));
    }
  };

  const title = `${t(`Orders.Others.Order`)} ${order.orderRef}`;
  const content = <div>
    <p>{message} </p>
    <p>{!order.messageToSellerReadFlag &&
      <Button type="primary" onClick={handleMarkOrderCommentAsRead}>{t("Orders.Actions.MarkAsRead")}</Button>
    }</p>
  </div>;

  return (
    <Popover trigger="click" placement="left" title={title} content={content}>
      {order.messageToSellerReadFlag ? <InfoCircleOutlined /> : <InfoCircleTwoTone />}
    </Popover>
  );
};
