import { put, takeLatest } from 'redux-saga/effects';
import { apiCallWrapper } from 'shared/utils/api';
import { getBirCode, getSelectedCountry } from 'shared/utils/utils';
import * as actions from './actions'
import { FinancialMonitoringLinksRequestPayload, FinancialMonitoringResponse } from './types';
import { getStockExchange } from './utils';

function* loadFinancialMonitoringLinks() {
    try {
        const birCode = getBirCode();
        const country = getSelectedCountry();

        if (birCode && country) {
            const { countrycode: countryCode } = country;
            const payload: FinancialMonitoringLinksRequestPayload = {
                birCode,
                countryCode
            }

            const financialMonitoringLinks: FinancialMonitoringResponse = yield apiCallWrapper(getStockExchange, payload)
            yield put(actions.actionCreators.loadFinancialMonitoringSuccess(financialMonitoringLinks))

        }
    } catch (error) {
        yield put(actions.actionCreators.loadFinancialMonitoringError(error))
    }
}

export default function* () {
    yield takeLatest(actions.Type.LOAD_FINANCIAL_MONITORING_LINKS, loadFinancialMonitoringLinks)
}