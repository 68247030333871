import {
  DislikeOutlined,
  FolderOutlined,
  HomeOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  RetweetOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { MenuProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatabaseType, RolePrivilege, myBIRCodeDataSelector, myRolePrivilegeSelector } from 'screens/context/stores';
import { loginActions } from 'screens/login/stores/actions';
import { StyledMenu } from '../Styles';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  key: React.Key | null,
  label?: React.ReactNode,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    label,
    children,
  } as MenuItem;
}

export const HeaderMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logout = () => dispatch(loginActions.authLogout());
  const rolePrivilege = useSelector(myRolePrivilegeSelector);
  const myBirCodeData = useSelector(myBIRCodeDataSelector);

  const oracleItems: MenuItem[] = [
    getItem("headerMenu", <MenuUnfoldOutlined />, undefined, [
      getItem("/home", <Link to="/home"> {t("Generic.Menu.Home")}</Link>, <HomeOutlined />),
      getItem("/accounts/tracking", <Link to="/accounts/tracking"> {t("Generic.Menu.Account")}</Link>, <UsergroupAddOutlined />),
      getItem("/orders/tracking", <Link to="/orders/tracking"> {t("Generic.Menu.Orders")}</Link>, <ShoppingCartOutlined />),
      getItem("/error-logs/tracking", <Link to="/error-logs/tracking"> {t("Generic.Menu.ErrorLogs")}</Link>, <DislikeOutlined />),
      getItem("/stock-exchange/tracking", <Link to="/stock-exchange/tracking"> {t("Generic.Menu.StockExchange")}</Link>, <RetweetOutlined />),
      getItem("/financial-monitoring/tracking", <Link to="/financial-monitoring/tracking"> {t("Generic.Menu.FinancialMonitoring")}</Link>, <LineChartOutlined />),
      myBirCodeData && myBirCodeData.countryCode && myBirCodeData.countryCode === 'FR' ?
        getItem("/exadis/tracking", <Link to="/exadis/tracking">Commande stock Exadis</Link>, <FolderOutlined />) : null,
      getItem("/logout", <span onClick={logout} >{t("Generic.Menu.LogOut")}</span>, <LogoutOutlined />),
    ])
  ]

  const postgreItems: MenuItem[] = [
    getItem("headerMenu", <MenuUnfoldOutlined />, undefined, [
      getItem("/home", <Link to="/home"> {t("Generic.Menu.Home")}</Link>, <HomeOutlined />),
      getItem("/accounts-RDS/tracking", <Link to="/accounts-RDS/tracking">{t("Generic.Menu.Account")}</Link>, <UsergroupAddOutlined />),
      getItem("/orders-RDS/tracking", <Link to="/orders-RDS/tracking">{t("Generic.Menu.Orders")}</Link>, <ShoppingCartOutlined />),
      getItem("/error-logs-RDS/tracking", <Link to="/error-logs-RDS/tracking">{t("Generic.Menu.ErrorLogs")}</Link>, <DislikeOutlined />),
      getItem("/stock-exchange/tracking", <Link to="/stock-exchange/tracking"> {t("Generic.Menu.StockExchange")}</Link>, <RetweetOutlined />),
      getItem("/financial-monitoring/tracking", <Link to="/financial-monitoring/tracking"> {t("Generic.Menu.FinancialMonitoring")}</Link>, <LineChartOutlined />),
      myBirCodeData && myBirCodeData.countryCode && myBirCodeData.countryCode === 'FR' ?
        getItem("/exadis/tracking", <Link to="/exadis/tracking">Commande stock Exadis</Link>, <FolderOutlined />) : null,
      getItem("/logout", <span onClick={logout} >{t("Generic.Menu.LogOut")}</span>, <LogoutOutlined />),
    ])
  ]

  const countrySubscriptionOracleItems: MenuItem[] = [
    getItem("headerMenu", <MenuUnfoldOutlined />, undefined, [
      getItem("/home", <Link to="/home"> {t("Generic.Menu.Home")}</Link>, <HomeOutlined />),
      getItem("/accounts/tracking", <Link to="/accounts/tracking">{t("Generic.Menu.Account")}</Link>, <UsergroupAddOutlined />),
      getItem("/logout", <span onClick={logout} >{t("Generic.Menu.LogOut")}</span>, <LogoutOutlined />),
    ])
  ]

  const countrySubscriptionPostgreItems: MenuItem[] = [
    getItem("headerMenu", <MenuUnfoldOutlined />, undefined, [
      getItem("/home", <Link to="/home"> {t("Generic.Menu.Home")}</Link>, <HomeOutlined />),
      getItem("/accounts-RDS/tracking", <Link to="/accounts-RDS/tracking">RDS - {t("Generic.Menu.Account")}</Link>, <UsergroupAddOutlined />),
      getItem("/logout", <span onClick={logout} >{t("Generic.Menu.LogOut")}</span>, <LogoutOutlined />),
    ])
  ]

  let items = oracleItems;

  switch (rolePrivilege) {
    case RolePrivilege.COUNTRY_SUBSCRIPTION:
      items = myBirCodeData && myBirCodeData.databaseType && myBirCodeData.databaseType === DatabaseType.POSTGRE ? countrySubscriptionPostgreItems : countrySubscriptionOracleItems
      break;
    default:
      items = myBirCodeData && myBirCodeData.databaseType && myBirCodeData.databaseType === DatabaseType.POSTGRE ? postgreItems : oracleItems;
      break;
  }

  return <StyledMenu key="menu" mode="horizontal" selectedKeys={['']} items={items} />;
};
