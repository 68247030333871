
export function useErrorLogsInterfaceId(): string[] {
    return [
        'AAD',
        'ALD',
        'ALV',
        'AND',
        'ARC',
        'ARD',
        'CAT',
        'CDE',
        'CMD',
        'DCD',
        'DFC',
        'GRP',
        'LST',
        'RCR',
        'RDG',
        'STK',
        'TAR'
    ]
}