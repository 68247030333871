import { Action, handleActions } from "redux-actions";
import { FetchReduceFn, Payload, UpdateStateSuccessFn } from "shared/utils/types";
import { fetchReduceFn, updateStateSuccessFn } from "shared/utils/utils";
import { MyColumnsType } from "../hooks/useBuyersColumns";
import { Type } from "./actions";
import { AccountsState, Buyer, FetchApiAccounts, UpdateBuyerAdhesionEnum } from "./types";

export const defaultBuyerPayload: Payload = {
    page: 0,
    pageSize: 20
}

export const INITIAL_STATE: AccountsState = {
    getBuyer: { data: undefined, loading: false, payload: undefined },
    getBuyers: { data: undefined, loading: false, payload: undefined },
    getCountries: { data: undefined, loading: false, payload: undefined },
    getSeller: { data: undefined, loading: false, payload: undefined },
    updateBuyers: { data: undefined, loading: false, payload: undefined },
    updateBuyersAdhesion: { data: undefined, loading: false, payload: undefined },
    updateSellers: { data: undefined, loading: false, payload: undefined },

    buyersLastVisitedTab: undefined,
    buyersSelectedRows: [],
    buyersMassActionType: UpdateBuyerAdhesionEnum.SUBSCRIBE,
    buyersColumns: undefined,
}

const updateStateSuccess: UpdateStateSuccessFn<FetchApiAccounts> = updateStateSuccessFn
const fetchReduce: FetchReduceFn<FetchApiAccounts> = fetchReduceFn

export default handleActions<AccountsState, any>({
    ...fetchReduce({
        param: "getBuyer",
        error: Type.GET_BUYER_ERROR,
        start: Type.GET_BUYER,
        success: Type.GET_BUYER_SUCCESS
    }),

    ...fetchReduce({
        param: "getBuyers",
        error: Type.GET_BUYERS_ERROR,
        start: Type.GET_BUYERS,
        success: Type.GET_BUYERS_SUCCESS
    }),

    ...fetchReduce({
        param: "updateBuyersAdhesion",
        error: Type.UPDATE_BUYERS_ADHESION_ERROR,
        start: Type.UPDATE_BUYERS_ADHESION,
        success: Type.UPDATE_BUYERS_ADHESION_SUCCESS,
    }),

    ...fetchReduce({
        param: "getCountries",
        error: Type.GET_COUNTRIES_ERROR,
        start: Type.GET_COUNTRIES,
        success: Type.GET_COUNTRIES_SUCCESS
    }),

    ...fetchReduce({
        param: "getSeller",
        error: Type.GET_SELLER_ERROR,
        start: Type.GET_SELLER,
        success: Type.GET_SELLER_SUCCESS
    }),

    ...fetchReduce({
        param: "updateBuyersAdhesion",
        start: Type.UPDATE_BUYERS_ADHESION,
        success: Type.UPDATE_BUYERS_ADHESION_SUCCESS,
        error: Type.UPDATE_BUYERS_ADHESION_ERROR,
        onSuccess: updateStateSuccess(["getBuyer", "getBuyers"], "accountCommercialLinks.id")
    }),

    ...fetchReduce({
        param: "updateBuyers",
        start: Type.UPDATE_BUYERS,
        success: Type.UPDATE_BUYERS_SUCCESS,
        error: Type.UPDATE_BUYERS_ERROR,
        onSuccess: updateStateSuccess(["getBuyer", "getBuyers"], "accountCommercialLinks.id")
    }),

    ...fetchReduce({
        param: "updateSellers",
        start: Type.UPDATE_SELLERS,
        success: Type.UPDATE_SELLERS_SUCCESS,
        error: Type.UPDATE_SELLERS_ERROR,
        onSuccess: updateStateSuccess(["getSeller"], "account.id")
    }),

    [Type.SET_BUYER_COLUMNS]: (state: AccountsState, action: Action<MyColumnsType<Buyer>>) => ({
        ...state,
        buyersColumns: action.payload
    }),
    [Type.SET_BUYER_LAST_VISITED_TAB]: (state: AccountsState, action: Action<string>) => ({
        ...state,
        buyersLastVisitedTab: action.payload
    }),
    [Type.SET_BUYER_SELECTED_ROWS]: (state: AccountsState, action: Action<Buyer[]>) => ({
        ...state,
        buyersSelectedRows: action.payload
    }),
    [Type.SET_BUYER_ACTION_TYPE]: (state: AccountsState, action: Action<UpdateBuyerAdhesionEnum>): AccountsState => ({
        ...state,
        buyersMassActionType: action.payload
    })
}, INITIAL_STATE)