import { Button, Card, Col, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditableCell, EditableRow } from "shared/components/Columns/EditableTable";
import { twoDigitRounded } from 'shared/components/Render/TwoDigitsRounder';
import { useItemsColumns } from '../hooks/useItemsColumns';
import { OrderItems, ordersActions, ordersSelectors } from "../stores";

export const ItemsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: orderItems, loading: loadingItems } = useSelector(ordersSelectors.getOrderItems);
  const { data: orderByRef, loading: loadingOrder } = useSelector(ordersSelectors.getOrder);
  let itemsColumns = useItemsColumns()

  const [orderItemsTable, setOrderItemsTable] = useState<OrderItems[]>([])

  useEffect(() => {
    orderItems && setOrderItemsTable(orderItems.map((item: OrderItems) => {
      return {
        ...item,
        key: item.id,
        unitPrice: twoDigitRounded(item.price),
        amount: twoDigitRounded((item.price * (1 - item.discountRate / 100)) * item.deliveredQuantity),
      }
    }))
  }, [orderItems])

  itemsColumns = itemsColumns.map((column: any) => {
    if (!(orderByRef?.content[0].status === 0 && (column.dataIndex === 'wantedQuantity'))) {
      return column
    }

    return {
      ...column,
      onCell: (record: OrderItems) => ({
        record,
        editable: true,
        dataIndex: column.dataIndex,
        title: column.title,
        handleSave: (record: OrderItems) =>
          setOrderItemsTable(orderItemsTable.map(obj => obj.id === record.id ? record : obj)),
      }),
    }
  });

  const pagination = {
    showSizeChanger: true,
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = () => {
    dispatch(ordersActions.updateOrderListWantedQuantity({
      orderId: 10,
      records: { records: orderItemsTable }
    }));
  }

  return (
    <Col span={24}>
      <Card>
        <Table
          components={components}
          size="small"
          loading={loadingItems || loadingOrder}
          dataSource={orderItemsTable}
          columns={itemsColumns}
          pagination={pagination}
          rowClassName={() => 'editable-row'}
        />
        {orderByRef?.content[0].status === 0 && <Button type="primary" onClick={handleSave}>{t("Generic.Actions.Save")}</Button>}
      </Card>
    </Col>
  );
};
