export type ID = string | number

export type FetchApiTypes<T> = {
  [K in keyof T]: (...args: any[]) => Promise<T[K]>;
}

export interface ApiData<T extends (...args: any) => any> {
  data?: Awaited<ReturnType<T>>
  loading: boolean
  payload?: Parameters<T>[0]
}

export type ActionSuccess<T extends (...args: any) => any> = Awaited<ReturnType<T>>
export type ApiActionLaunch<T extends (...args: any) => any> = Parameters<T>[0]

export interface IPage<T> {
  content: T[]
  pageable: any
  number: number
  size: number
  totalElements: number
  totalPages: number
  sort: any
  numberOfElements: number
  first: boolean
  last: boolean
  empty: boolean
}

export type DataWithRender = {
  label: string,
  value?: string,
  render?: JSX.Element
  void?: boolean
}
export interface Page {
  number: number
  size: number
  totalElements: number
  totalPages: number
}

export interface IRestData<T> {
  page?: Page
  _embedded: T[] | any
  _links?: any
}


export interface Payload extends Pageable {
  // Filter
  [x: string]: any
}

export interface Pageable {
  // Pagination
  page?: number
  pageSize?: number
  size?: number

  // Sort
  sort?: string
  name?: string
}

export enum SortDirection {
  ascend = "asc",
  descend = "desc"
}

export type UpdateRecordRequest<T> = {
  records: T[]
}

export type UpdateRecordResponse<T> = {
  updatedRecords: {
    record: T
    updated: boolean
    message: string
  }[]
  updatedRecordsNumber: number
}

export type UpdateStateSuccessFn<A> = <T extends keyof A>(
  aditionnalPropertyPathsToUpdate: T[],
  matchingKey: string
) => {}

export interface FetchReduce<T> {
  start: string
  success: string
  error: string
  param: keyof T
  additionnalParams?: Array<keyof T>
  onSuccess?: any
}

export type FetchReduceFn<T> = (
  fetchs: FetchReduce<T>
) => {}

type AsyncFunction<T, U> = (arg: T) => Promise<U>;

export type FetchFunctions<T extends Record<string, AsyncFunction<any, any>>> = {
  [key in keyof T]: T[key] extends AsyncFunction<infer A, infer R> ? (args: A) => Promise<R> : never;
};