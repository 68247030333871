import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

function renderWithLink<T>(renderFunc: any, linkByRecordFunc: any) {
    return (value: any, record: T) => {
        const destination: string = linkByRecordFunc(record)
        return <Link
            style={{ color: "unset", width: "100%", display: "block" }}
            to={destination}>{renderFunc ? renderFunc(value, record) : value}
        </Link>
    }
}

export function useRowAsLink<T>(columns: ColumnsType<T>, linkByRecordFunc: any) {
    return columns.map((column: any) => ({
        ...column,
        render: renderWithLink(column.render, linkByRecordFunc)
    }))
}