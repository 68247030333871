import { Col, FormInstance, Spin } from "antd";
import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { ActionButton, DetailsCard, Field, InfosRow, Value } from "shared/components/Styles";
import { Buyer, UpdateBuyerAdhesionEnum, accountPostgreActions, accountsSelectors } from "../stores";

interface BuyerInfoProps {
    buyer: Buyer
    form: FormInstance<any>
}

export const BuyerInfo: FC<BuyerInfoProps> = ({ buyer, form }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading, data } = useSelector(accountsSelectors.getBuyer)

    const handleSubscribe = () => {
        data && form.validateFields()
            .then(() => dispatch(accountPostgreActions.updateBuyersAdhesion({
                commercialLinkIds: [data.content[0].accountCommercialLinks.id],
                type: UpdateBuyerAdhesionEnum.SUBSCRIBE
            })))
    }

    const handleUnsubscribe = () => {
        data && form.validateFields()
            .then(() => dispatch(accountPostgreActions.updateBuyersAdhesion({
                commercialLinkIds: [data.content[0].accountCommercialLinks.id],
                type: UpdateBuyerAdhesionEnum.UNSUBSCRIBE
            })))
    }

    const addrPrincipal = buyer.accountAddrs.filter(x => x.principal)[0]

    return (
        <InfosRow gutter={[8, 8]}>
            <Col>
                <DetailsCard>
                    <div className="fields-wrapper">
                        <Field>
                            <span>{t("Account.Details.CommercialName")} :</span>
                            <Value>{buyer.account.commercialName}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.TresorCode")} :</span>
                            <Value>{buyer.account.tresorCode}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.Bircode")} :</span>
                            <Value>{buyer.account.sellerCode}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.BuildingNumber")} :</span>
                            <Value>{buyer.account.establishmentNumber}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.GarageType")} :</span>
                            <Value>{buyer.accountCommercialLinks.type}</Value>
                        </Field>
                        <Field>
                            <span>{t("Account.Details.GarageLanguage")} :</span>
                            <Value>{buyer.countryCode}</Value>
                        </Field>
                    </div>
                </DetailsCard>
            </Col>
            <Col>
                <DetailsCard>
                    <Field>
                        <span>{t("Account.Details.Address")} :</span>
                        <Value>{addrPrincipal.address1}</Value>
                    </Field>
                    {addrPrincipal.address2 && <Field>
                        <span>-</span>
                        <Value>{addrPrincipal.address2}</Value>
                    </Field>}
                    {addrPrincipal.address3 && <Field>
                        <span>-</span>
                        <Value>{addrPrincipal.address3}</Value>
                    </Field>}
                    {addrPrincipal.address4 && <Field>
                        <span>-</span>
                        <Value>{addrPrincipal.address4}</Value>
                    </Field>}
                    <Field>
                        <span>{t("Account.Details.PostalCode")} :</span>
                        <Value>{addrPrincipal.postalCode}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.City")} :</span>
                        <Value>{addrPrincipal.city}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.Phone")} :</span>
                        <Value>{addrPrincipal.phone}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.Fax")} :</span>
                        <Value>{addrPrincipal.fax}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.ProfessionnalEmail")} :</span>
                        <Value>{addrPrincipal.mail}</Value>
                    </Field>
                </DetailsCard>
            </Col>

            <Col>
                <DetailsCard>
                    <Spin spinning={loading} >
                        <Field>
                            <span>{t("Account.Details.ValidityStartDate")} :</span>
                            {buyer.accountCommercialLinks.validityStartDate && <Value>{dayjs(buyer.accountCommercialLinks.validityStartDate).format('L LT')}</Value>}
                        </Field>
                        <Field>
                            <span>{t("Account.Details.ValidityEndDate")} :</span>
                            {buyer.accountCommercialLinks.validityEndDate && <Value>{dayjs(buyer.accountCommercialLinks.validityEndDate).format('L LT')}</Value>}
                        </Field>
                        <Field style={{ paddingTop: "0px", visibility: "hidden", contentVisibility: "hidden" }}>
                            <ActionButton disabled={!buyer.accountCommercialLinks.subscribeAllowed} type="primary" onClick={handleSubscribe} > {t("Generic.Actions.Subscribe")} </ActionButton>
                            <ActionButton disabled={!buyer.accountCommercialLinks.unsubscribeAllowed} type="default" onClick={handleUnsubscribe} > {t("Generic.Actions.Unsubscribe")} </ActionButton>
                        </Field>
                    </Spin>
                </DetailsCard>

            </Col>
        </InfosRow>
    );
}