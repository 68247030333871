import { Select, Space } from 'antd';
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from 'react-i18next';
import { Presentation } from "shared/components/Modal/Styles";
import { Order, OrderStatus, UpdateOrderActionEnum } from '../stores';
import { OrderStatusTag } from './OrderStatusTag';

const { Option } = Select;

interface OrderTableMassActionModalProps {
  selectedRows: Order[]
  actionType: UpdateOrderActionEnum
  setActionType: Dispatch<SetStateAction<UpdateOrderActionEnum>>
}

export const OrderTableMassActionModal: FC<OrderTableMassActionModalProps> = ({ selectedRows, actionType, setActionType }) => {

  const { t } = useTranslation();

  const onSelect = (value: UpdateOrderActionEnum) => {
    setActionType(value)
  };

  return <Presentation>
    <Space direction="vertical">
      <Space direction="horizontal">
        <Select style={{ width: "175px" }} defaultValue={actionType} onChange={onSelect} placeholder={t("Orders.Actions.ActionsToRealise")}>
          <Option value={UpdateOrderActionEnum.VALIDATE} title={t("Orders.Actions.ValidateAllOrders")}>
            {t("Generic.Actions.Validate")}
          </Option>
          <Option value={UpdateOrderActionEnum.REJECT} title={t("Orders.Actions.RejectAllOrders")}>
            {t("Generic.Actions.Reject")}
          </Option>
          <Option value={UpdateOrderActionEnum.MARK_AS_READ} title={t("Orders.Actions.MarkAllAsRead")}>
            {t("Orders.Actions.MarkAsRead")}
          </Option>
          <Option value={UpdateOrderActionEnum.REISSUE_TO_DMS} title={t("Orders.Others.ReissueDMS")}>
            {t("Orders.Others.ReissueDMS")}
          </Option>
        </Select>
      </Space>

      {
        ((actionType === UpdateOrderActionEnum.REJECT) || (actionType === UpdateOrderActionEnum.VALIDATE)) &&
        <p>
          {t("Orders.Actions.OnlyStatusToTreatWillBeModified", {
            toTreat:
              `${t(`Orders.StatusName.${OrderStatus.ValidationRequired}`)}", "${t("Orders.Navigation.OrdersNeedReissueDMS")}`
          })}
        </p>
      }

      {
        selectedRows.map(order => <>{order.id} <OrderStatusTag status={order.status} /></>)
      }
    </Space>
  </Presentation>
};
