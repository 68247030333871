import { Button, Card, Radio, Switch } from "antd";
import styled from "styled-components";

export const DetailsCardForm = styled(Card)`
  width: 100%;
  .ant-card-body {
    padding: .5em;
  }
  .ant-picker-input {
    width: 150px;
  }
  .ant-form-item-label>label {
    // width: 200px;
  }
  .ant-form-item {
    display: flex;
    justify-content: space-between;
    width: 467px;
  }
  .ant-form-item-control {
    display: contents;
  }
`;

export const ButtonRadio = styled(Radio.Group)`
  position: relative;
  left: 37px;
`;


export const CustomButton = styled(Button)`
  margin-top: 5px;
  align-self: flex-end;
`;

export const CustomGreenRedSwitch = styled(Switch)`
  &.ant-switch {
    background-color: red;

    &-checked {
      background-color: green;
    }
  }
  &.ant-switch-disabled {
    opacity: 0.5;
  }
`;
