import { createSelector } from 'reselect'
import { RootState } from 'shared/stores'

export const rolesStateSelector = (state: RootState) => state.rp4.context

export const BIRCodeDataListSelector = createSelector(rolesStateSelector, state => state.allBIRCodeDataList)

export const myBIRCodeDataSelector = createSelector(rolesStateSelector, state => state.myBirCodeData)
export const myRoleListSelector = createSelector(rolesStateSelector, state => state.roleData.myRoleList)
export const myRolePrivilegeSelector = createSelector(rolesStateSelector, state => state.roleData.myRolePrivilege)

export const garagePopoverVisibleSelector = createSelector(rolesStateSelector, state => state.garagePopover.isVisible)
export const garagePopoverValueSelector = createSelector(rolesStateSelector, state => state.garagePopover.value)

export const orderPopoverVisibleSelector = createSelector(rolesStateSelector, state => state.orderPopover.isVisible)
export const orderPopoverValueSelector = createSelector(rolesStateSelector, state => state.orderPopover.value)
export const orderPopoverMsgSelector = createSelector(rolesStateSelector, state => state.orderPopover.msg)

export const rpartsStoreSelector = createSelector(rolesStateSelector, state => state.rpartsStore)