import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MyColumnsType } from 'screens/accounts_postgre/hooks/useBuyersColumns';
import { useRowAsLink } from 'shared/hooks/useRowAsLink';
import { ErrorLogs } from "../stores/types";

export function useErrorLogsColumns(): ColumnsType<ErrorLogs> {
    const { t } = useTranslation();

    const COLUMNS: MyColumnsType<ErrorLogs> = [
        {
            title: t("ErrorLogs.Columns.interfaceId"),
            dataIndex: 'errorType',
            key: 'errorType',
        },
        {
            title: t("ErrorLogs.Columns.fileLotNumber"),
            dataIndex: 'datasetNumber',
            key: 'datasetNumber',
        },
        {
            title: t("ErrorLogs.Columns.fileCreatedDate"),
            dataIndex: 'creationDateTime',
            key: 'date',
            defaultSortOrder: "descend",
            sorter: true,
            render: value => value ? dayjs(value).format("L LTS") : null,
        },
        {
            title: 'tarAllLines',
            dataIndex: 'tarAllLines',
            key: 'tarAllLines',
        },
        {
            title: "tarIntegratedLines",
            dataIndex: 'tarIntegratedLines',
            key: 'tarIntegratedLines',
        },
        {
            title: "tarNonIntegratedLines",
            dataIndex: 'tarNonIntegratedLines',
            key: 'tarNonIntegratedLines',
        },
        {
            title: "stkNonIntegratedLines",
            dataIndex: 'stkNonIntegratedLines',
            key: 'stkNonIntegratedLines',
        },
        {
            title: "rdgNonIntegratedLines",
            dataIndex: 'rdgNonIntegratedLines',
            key: 'rdgNonIntegratedLines',
        },
        {
            title: "lstNonIntegratedLines",
            dataIndex: 'lstNonIntegratedLines',
            key: 'lstNonIntegratedLines',
        },
        {
            title: "grpNonIntegratedLines",
            dataIndex: 'grpNonIntegratedLines',
            key: 'grpNonIntegratedLines',
        },
        {
            title: "dfcNonIntegratedLines",
            dataIndex: 'dfcNonIntegratedLines',
            key: 'dfcNonIntegratedLines',
        },
    ]



    return useRowAsLink<ErrorLogs>(COLUMNS, (record: ErrorLogs) => {
        const { datasetNumber, errorType } = record;
        return `details/${datasetNumber.toString()}-${errorType.toString()}`
    })
}