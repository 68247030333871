import { UserOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import logoRenaultGroup from 'assets/img/logo-renault.svg';
import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { ContextHeader } from 'screens/context/pages/ContextHeader';
import {
    userSelector
} from 'screens/login/stores/selectors';
import {
    Groupe, HomeLink, Renault, RenaultGroupeWrapper,
    RenaultGroupText,
    RenaultLogo, UserInfoWrapper
} from '../Styles';
import { TranslateMenu } from '../TranslateMenu/TranslateMenu';
import { HeaderMenu } from './HeaderMenu';

export const HeaderLogin: FC = () => {
    const user = useSelector(userSelector);
    const { t } = useTranslation();

    const userName = `${user?.profile?.uid} - ${user?.profile?.firstname} ${user?.profile?.lastname?.toUpperCase()}`;
    const company = user?.profile.company || user?.profile.entity_name;

    return <>
        <RenaultGroupeWrapper>
            <HomeLink to="/home">
                <RenaultLogo src={logoRenaultGroup} alt="Renault logo" />
                <RenaultGroupText>
                    <Groupe>{t("Generic.group")}</Groupe>
                    <Renault>RENAULT</Renault>
                </RenaultGroupText>
            </HomeLink>
        </RenaultGroupeWrapper>
        <UserInfoWrapper>
            <ContextHeader />
            <TranslateMenu />
            <Space size="small">
                <span>{userName} | {company} </span>
                <UserOutlined />
            </Space>
            <HeaderMenu />
        </UserInfoWrapper>
    </>
}