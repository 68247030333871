import { FC } from 'react';
import { useSelector } from 'react-redux';
import { userIsAuthenticated } from '../login/stores/selectors';
import { useTranslation } from 'react-i18next';
import {
  BottomPart,
  Container,
  MainContainer,
  MainWrapper,
} from 'shared/GlobalStyles';
import {
  CustomCarousel,
  HomeBGWrapper,
  HomeBG1,
  HomeBG2,
  HomeBG3,
  HomeBGText,
  HomeBGContent,
  HomePageContainer,
  CustomLink
} from './Styles';
import { myRoleListSelector } from 'screens/context/stores';

/**
 * Home Page
 */
export const Home: FC = () => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const role = useSelector(myRoleListSelector)
  const { t } = useTranslation();

  return (
    <MainContainer>
      {/* <SubHeader /> */}

      <MainWrapper>
        {(!isAuthenticated || role === undefined) || (
          <Container>
            <HomePageContainer id="HomePage">
              <CustomCarousel autoplay>
                <HomeBGWrapper>
                  <HomeBGContent>
                    <HomeBGText>{t("homePage.accounts")}</HomeBGText>
                    <CustomLink to="/accounts/tracking">{t("Generic.Menu.Account")}</CustomLink>
                  </HomeBGContent>
                  <HomeBG1 />
                </HomeBGWrapper>
                <HomeBGWrapper>
                  <HomeBGContent>
                    <HomeBGText>{t("homePage.orders")}</HomeBGText>
                    <CustomLink to="/orders/tracking">{t("Generic.Menu.Orders")}</CustomLink>
                  </HomeBGContent>
                  <HomeBG2 />
                </HomeBGWrapper>
                <HomeBGWrapper>
                  <HomeBGContent>
                    <HomeBGText>{t("homePage.errorsLogs")}</HomeBGText>
                    <CustomLink to="/error-logs/tracking">{t("Generic.Menu.ErrorLogs")}</CustomLink>
                  </HomeBGContent>
                  <HomeBG3 />
                </HomeBGWrapper>
              </CustomCarousel>
            </HomePageContainer>
          </Container>
        )}

        <BottomPart></BottomPart>
      </MainWrapper>
    </MainContainer>
  );
};
