import { NavigateFunction } from "react-router-dom"
import { MyColumnsType } from "screens/accounts_postgre/hooks/useBuyersColumns"
import { ApiData, ID, IPage, Pageable, UpdateRecordRequest, UpdateRecordResponse } from "shared/utils/types"

export type FetchApiOrders = {
  getOrders: (payload: GetOrdersPayload) => Promise<IPage<Order>>,
  getOrder: (payload: GetOrdersPayload) => Promise<IPage<Order>>,
  getOrderByRef: (payload: SearchByRef) => Promise<IPage<Order>>,
  getOrderEvents: (orderId: ID) => Promise<OrderEvents[]>,
  getOrderItems: (orderId: ID) => Promise<OrderItems[]>,
  updateOrderList: (payload: UpdateOrderListPayload) => Promise<UpdateRecordResponse<Order>>,
  updateOrderItemsListWantedQuantities: (payload: UpdateOrderItemsPayload) => Promise<UpdateRecordResponse<OrderItems>>
}

export type UpdateOrderItemsPayload = {
  records: UpdateRecordRequest<OrderItems>
  orderId: number
}

export type OrdersState = {
  getOrders: ApiData<FetchApiOrders["getOrders"]>
  getOrder: ApiData<FetchApiOrders["getOrder"]>
  getOrderByRef: ApiData<FetchApiOrders["getOrderByRef"]>
  getOrderEvents: ApiData<FetchApiOrders["getOrderEvents"]>
  getOrderItems: ApiData<FetchApiOrders["getOrderItems"]>
  updateOrderListStatus: ApiData<FetchApiOrders["updateOrderList"]>
  updateOrderItemsListWantedQuantity: ApiData<FetchApiOrders["updateOrderItemsListWantedQuantities"]>

  ordersSelectedRows: Order[]
  ordersLastVisitedTab?: string
  ordersColumns?: MyColumnsType<Order>
  ordersFromSuperOrder?: Order[]
}

export enum OrderStatus {
  TechnicalError = -1,
  ValidationRequired = 0,
  WaitToBeSent = 2,
  DMSTreatment = 4,
  FullyServed = 5,
  Solded = 6,
  R1Refuse = 7,
  DMSRefuse = 8,
  ExaInVerification = 10,
  ExaTechnicalIssue = 11,
  ExaPartiallyServed = 16,
  NoStatus = 77
}

export type Order = {
  id: number
  superOrderId: number
  sellerCode: string
  sellerName: string
  deliveryMethod: string
  orderComment: string
  sellerTreatedByFirstname: string
  sellerTreatedByLastname: string
  orderRef: string
  superOrderRef: string
  stockist: string
  origin: string
  countryCode: string
  buyerName: string
  messageToSeller: string
  buyerDmsClientCode: string
  messageToSellerReadFlag: boolean
  status: number
  creationDateTime: Date
  lastUpdateDateTime: Date
  totalTaxFree: number
  totalTax: number
  totalTaxIncluded: number
  dmsReemission: boolean
  takenIntoAccount: boolean
  distributedBy: string
  // orderEventIsLate: boolean // TODO ask CHR to know what to do
  urgentFlag: boolean
}

export interface UpdateOrderItems {
  orderId: ID
  orderItemId: ID
  fields: UpdateOrderItemsFields
}

export interface UpdateOrderItemsFields {
  wantedQuantity: number
}

export type OrderEvents = {
  id: number
  eventType: string
  eventComment: string
  orderAccepted: string
  eventDateTime: Date
  isNew: boolean
}

export type OrderItems = {
  id: number
  creationDateTime: Date
  lastUpdateDateTime: Date
  reference: string
  substituteItem: string
  itemComment: string
  discountCode: string
  isUrgent: boolean
  wantedQuantity: number
  deliveredQuantity: number
  dmsAvailableQuantity: number
  price: number
  discountRate: number
  vat: number
  stockAvailable?: boolean
  warehouseStocks?: Warehouse[]
  designation: string
}

export enum UpdateOrderActionEnum {
  VALIDATE = "VALIDATE",
  REJECT = "REJECT",
  MARK_AS_READ = "MARK_AS_READ",
  REISSUE_TO_DMS = "REISSUE_TO_DMS"
}

export type UpdateOrderListPayload = {
  orderIds: number[]
  action: UpdateOrderActionEnum
}

export const OrdersStatusColors = {
  [OrderStatus.TechnicalError]: 'red',
  [OrderStatus.ValidationRequired]: 'purple',
  [OrderStatus.WaitToBeSent]: 'gold',
  [OrderStatus.DMSTreatment]: 'yellow',
  [OrderStatus.FullyServed]: 'green',
  [OrderStatus.Solded]: 'cyan',
  [OrderStatus.R1Refuse]: 'red',
  [OrderStatus.DMSRefuse]: 'orange',
  [OrderStatus.ExaInVerification]: 'geekblue',
  [OrderStatus.ExaTechnicalIssue]: 'red',
  [OrderStatus.ExaPartiallyServed]: 'lime',
  [OrderStatus.NoStatus]: 'black',
}

export interface GetOrdersPayload extends Pageable {
  sellerCode?: string
  status?: number[]
  buyerName?: string
  orderId?: number
  orderRef?: string
}

export enum WarehouseCode {
  /** ALL AVAILABLE IN DMS */
  DMS = 1,
  CDP = 2,

  /** Available in Country Warehouse */
  SIGMPR = 3,
  CDE = 4,
  SAPPR = 5,

  /** Available in France */
  MPR88 = 6,

  /** Not available in France */
  DIFF = 7,
}

export type WarehouseCodeLabel = keyof typeof WarehouseCode

export type WarehouseData = {
  code: WarehouseCode
  ordinal: number
  color: string
}

export type Warehouse = {
  order: number
  confirmedQuantity: number
  availableQuantity: number
  code: WarehouseCodeLabel
  type: string
}

export type WarehouseCodeData = {
  code: WarehouseCode,
  color: string
}

export const WarehouseColors = {
  [WarehouseCode.DMS]: "green",
  [WarehouseCode.CDP]: "green",
  [WarehouseCode.SIGMPR]: "yellow",
  [WarehouseCode.CDE]: "yellow",
  [WarehouseCode.SAPPR]: "yellow",
  [WarehouseCode.MPR88]: "orange",
  [WarehouseCode.DIFF]: "red",
}

export interface SearchByRef {
  navigate: NavigateFunction
  payload: GetOrdersPayload
}