import { OrderedListOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { NavLink, useResolvedPath } from 'react-router-dom';
import { SiderMenu } from 'shared/components/SiderMenu/SiderMenu';

/**
 * Navigation of module ErrorLogs
 */
export const ExadisNavigation: FC = () => {
    const url = useResolvedPath("").pathname;
    const [showMenu, setShowMenu] = useState(false);
    const toggleCollapsed = () => setShowMenu(!showMenu);


    return <SiderMenu
        items={[{
            key: '/tracking',
            label: <NavLink to={`${url}/tracking`}>Commande stock</NavLink>,
            icon: <OrderedListOutlined />
        }]}
        selectedKeys={[]}
        showMenu={showMenu}
        toggleCollapsed={toggleCollapsed}
    />

};
