import { Input } from "antd"
import { SearchProps } from "antd/lib/input"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { ordersActions, ordersSelectors } from "screens/orders_postgre/stores"
import { SelectLabel, SelectWrapper } from "../Styles"
import { contextActions, orderPopoverMsgSelector, orderPopoverValueSelector, orderPopoverVisibleSelector } from "../stores"

export const OrderSelect: FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const orderByRefLoading = useSelector(ordersSelectors.getOrderByRef).loading
    const visible = useSelector(orderPopoverVisibleSelector)
    const value = useSelector(orderPopoverValueSelector)
    const setValue = (value: string) => dispatch(contextActions.setOrderPopoverValue(value))
    const msg = useSelector(orderPopoverMsgSelector);
    const setMsg = (msg: string) => dispatch(contextActions.setOrderPopoverMsg(msg))
    const navigate = useNavigate()

    useEffect(() => {
        if (!visible) {
            setValue("")
            setMsg("")
        }
    }, [visible])

    const inputSearchProps: SearchProps = {
        autoFocus: true,
        placeholder: t("Countries.OrderNumber"),
        value,
        style: { width: '400px' },
        loading: orderByRefLoading,
        onSearch: value => {
            const filters: { [key: string]: boolean } = {
                [t("Countries.YouMustEnterAValue")]: value?.length > 0,
            };

            Object.keys(filters).forEach(key => !filters[key] && setMsg(key));
            if (!Object.values(filters).includes(false)) {
                dispatch(ordersActions.getOrderByRef({
                    payload: {
                        orderRef: value
                    }, navigate
                }))
            }

        },
        onChange: (event) => {
            setMsg("");
            setValue(event.target.value.replace(/\D/g, ''))
        }
    }

    return <SelectWrapper>
        <SelectLabel>{t(`Countries.SearchForAnOrderNumber`)}</SelectLabel>
        <Input.Search ref={(input) => input && input.focus()} {...inputSearchProps} />
        {msg && <span style={{ color: "red" }}>{msg}</span>}
    </SelectWrapper>
}