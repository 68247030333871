import { InputRef, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ButtonSearch, ButtonReset } from "./Buttons"
import InputStringFilterDropdown from "./InputStringFilterDropdown";
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ReactNode } from 'react';

function stringFilterDropdown(title: string, dataIndex?: string) {

  const searchInput: { current: InputRef | null } = { current: null };
  dataIndex = dataIndex ? dataIndex : title.split('.')[title.split('.').length - 1]

  return {
    filterDropdown:
      ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }: FilterDropdownProps) => (

        <div style={{ padding: 8 }}>
          <InputStringFilterDropdown
            title={title}
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <ButtonSearch onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} />
            <ButtonReset onClick={() => handleReset(clearFilters)} />
          </Space>
        </div>
      ),

    filterIcon: (filtered: any): ReactNode => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

    onFilter: () => true,

    onFilterDropdownOpenChange: (visible: any): void => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current?.select(), 1000);
      }
    }
  }

  function handleSearch(selectedKeys: any, confirm: any, dataIndex: any) {
    confirm();
  };

  function handleReset(clearFilters: any) {
    clearFilters();
  };

}

export default stringFilterDropdown;