import { Col, Spin, Table } from 'antd';
import { FC } from "react";
import { useSelector } from 'react-redux';
import { DetailsCard, Field, InfosRow, Value } from 'shared/components/Styles';
import { useItemsTotalColumns } from "../hooks/useItemsTotalColumns";
import { useOrderDetailsInfo } from '../hooks/useOrderDetailsInfo';
import {
    ordersSelectors
} from '../stores';
import { OrderStatusAction } from './OrderStatusAction';

export const OrderDetailsMain: FC = () => {
    let { data: order, loading } = useSelector(ordersSelectors.getOrder);

    const data = order && order.content[0] ? [order.content[0]] : [];
    const columns = useItemsTotalColumns();

    const {
        orderDetailsMainInfos,
        orderDetailsDeliveryInfos
    } = useOrderDetailsInfo()

    return <InfosRow gutter={[8, 8]}>
        <Col>
            <DetailsCard>
                {loading && <Spin />}
                {
                    order && !loading && orderDetailsMainInfos.map(x =>
                        <Field key={x.label}>
                            <span>{x.label}</span>
                            <Value>{x.render ? x.render : x.value}</Value>
                        </Field>
                    )
                }
            </DetailsCard>
        </Col>

        <Col>
            <DetailsCard>
                {loading && <Spin />}
                {
                    order && !loading && orderDetailsDeliveryInfos.map(x =>
                        <Field key={x.label}>
                            <span>{x.label}</span>
                            <Value>{x.render ? x.render : x.value}</Value>
                        </Field>
                    )
                }
            </DetailsCard>
        </Col>

        <Col>
            <DetailsCard>
                <Table
                    size="small"
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    rowKey={record => record.id}
                    pagination={false}
                />
            </DetailsCard>
        </Col>

        {
            order && <OrderStatusAction />
        }

    </InfosRow>
}