import { Col } from 'antd';
import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { CenteredSpin, DetailsCard, Field, InfosRow, Value } from "shared/components/Styles";
import { accountsSelectors } from "../stores";

export const SellerInfo: FC = () => {
    const { t } = useTranslation();

    const { data, loading } = useSelector(accountsSelectors.getSeller)

    if (loading || !data) return <CenteredSpin size="large" />

    const { tresorCode, sellerCode: birCode, establishmentNumber: establishmentNum, commercialName } = data.account;
    const { address1, address2, address3, address4, city, postalCode: zipcode } = data.accountAddrs.filter(x => x.principal)[0]

    let addressConcat: string = address1;
    if (address2) addressConcat += ", " + address2
    if (address3) addressConcat += ", " + address3
    if (address4) addressConcat += ", " + address4

    return (
        <InfosRow gutter={[8, 8]}>
            <Col>
                <DetailsCard>
                    <Field>
                        <span>{t("Account.Details.CommercialName")} :</span>
                        <Value>{commercialName}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.TresorCode")} :</span>
                        <Value>{tresorCode}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.Bircode")} :</span>
                        <Value>{birCode}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.BuildingNumber")} :</span>
                        <Value>{establishmentNum}</Value>
                    </Field>
                </DetailsCard>
            </Col>
            <Col>
                <DetailsCard>
                    <Field>
                        <span>{t("Account.Details.Address")} :</span>
                        <Value>{addressConcat}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.city")} :</span>
                        <Value>{city}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.zipcode")} :</span>
                        <Value>{zipcode}</Value>
                    </Field>
                </DetailsCard>
            </Col>
        </InfosRow>
    );
}