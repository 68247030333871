import { Button } from 'antd';
import styled from 'styled-components';
import backgroundConnexion from '../../assets/img/RParts_Landings_Background.jpg';

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundConnexion});
  background-size: cover;
  color: #fff;
  padding: 87px 142.5px 121px 140px;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 10px;
  padding-left: 94px;
  padding-top: 59px;
  padding-bottom: 36px;
  border-left: 1px solid rgba(255,255,255,.3);
`;

export const IconAdvisorWrapper = styled.div`
  width: 103px;
  height: 103px;
  margin: auto;
  border-radius: 50px;
  z-index: 1;
  border: solid 1px rgba(255,255,255,.5);
  background-color: transparent;
`;

export const IconAdvisor = styled.img`
  background-color: #fff;
  border-radius: 50px;
  margin: 7px;
  padding: 26px;
`;

export const Contact = styled.div`
  color: #fff;
  font-size: 16px;
  font-family: Read-Medium;
  line-height: 1.25;
  font-weight: 500;
  margin: 45px 0 16px;
`;

export const ByPhoneNumber = styled.div`
  color: #fff;
  font-size: 16px;
  font-family: Read-Medium;
  line-height: 1.25;
  font-weight: 500;
  text-align: center;
  margin: auto;
  padding-top: 3px;
  letter-spacing: normal;
  font-style: normal;
  font-stretch: normal;
`;

export const PhoneNumber = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .5px;
  color: #fc3;
  font-family: Read;
  font-style: normal;
  font-stretch: normal;
`;

export const TimeSlots = styled.div`
  color: #fff;
  height: 80px;
  font-family: Read-Medium;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
`;

export const LoginButton = styled(Button)`
  background-color: #fc3;
  color: #000;
  border: none;
  font-family: Read-Medium;
  font-weight: 500;
  min-height: 40px;
  width: 210px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  :hover {
    background-color: rgba(255,204,51,.7);
    transition: .3s background-color;
  }
`;

export const LoginLinkButton = styled(Button)`
background-color: lightgrey;
color: #000;
border: none;
font-family: Read-Medium;
font-weight: 500;
min-height: 35px;
width: 180px;
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: space-around;
:hover {
  background-color: rgba(255,204,51,.7);
  transition: .3s background-color;
}
`;

export const WelcomeWrapper = styled.div`
  width: 50%;
  padding-bottom: 10px;
`;

export const WelcomeTitle = styled.div`
  font-family: Read-Light;
  font-size: 42px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`;

export const WelcomeMessage = styled.div`
  height: auto;
  padding-bottom: 12px;
  font-family: Read;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-shadow: 0 1px 0 rgb(0 0 0 / 60%);
  white-space: pre-line;
`;

export const IconArrow = styled.img`
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50px;
  margin: 7px;
  padding: 26px;
`;
