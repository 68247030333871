import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { sellerDataSelector, sellerLoadingSelector } from "../stores";
import { CustomButton } from '../Styles';
import { useSellerForm } from "../hooks/useSellerForm";
import { CenteredSpin } from "shared/components/Styles";
import { SellerFormOpeningTime } from "./SellerFormOpeningTime";
import { SellerFormMailAlert } from "./SellerFormMailAlert";
import { SellerFormDmsInfo } from "./SellerFormDmsInfo";
import { FormWrapper } from "shared/components/Form/FormWrapper";
import { DetailsRow } from "shared/components/Styles";
import { Form } from "antd";

export const SellerForm: FC = () => {
    const { t } = useTranslation()
    const sellerForm = useSellerForm()
    const [areFieldsEnabled, setAreFieldsEnabled] = useState(true)
    const seller = useSelector(sellerDataSelector);
    const loading = useSelector(sellerLoadingSelector)
    const form = Form.useForm();

    useEffect(() => {
        if (seller && seller.mailAlert.sendEmails) setAreFieldsEnabled(true);
        else setAreFieldsEnabled(false);
    }, [])

    sellerForm.onValuesChange = (changedValues, values) => {
        if (values.mailAlert.sendEmails) setAreFieldsEnabled(true);
        else setAreFieldsEnabled(false);
    }

    if (loading || !seller || !sellerForm.onFinish) return <CenteredSpin size="large" />

    return (
        <DetailsRow>
            <FormWrapper form={form[0]} {...sellerForm}>
                <SellerFormOpeningTime />
                <SellerFormMailAlert areFieldsEnabled={areFieldsEnabled} />
                <SellerFormDmsInfo />
                <CustomButton type="primary" htmlType="submit">{t("Generic.Actions.Validate")}</CustomButton>
            </FormWrapper>
        </DetailsRow>
    );
}