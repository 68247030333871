import { ColumnType } from "antd/lib/table";
import { OrderItem } from "../stores";
import { useItemsColumns } from "./useItemsColumns";
import { useTranslation } from "react-i18next";

export function useItemsColumnsPDF() {
    const itemsColumns = useItemsColumns();
    const { t } = useTranslation();

    return itemsColumns
        .map((x: ColumnType<OrderItem>) => {
            if (x.dataIndex === "currentItemStatus")
                x.render = (value: any, record: OrderItem, index: number) => t(`Orders.StatusName.${value}`);
            return x;
        }
        )
        .filter((x: ColumnType<OrderItem>) => x.dataIndex !== "dmsAvailableQuantity")
}