import { Form } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { FormWrapper } from "shared/components/Form/FormWrapper";
import { CenteredSpin, DetailsRow } from "shared/components/Styles";
import { CustomButton } from '../Styles';
import { useSellerForm } from "../hooks/useSellerForm";
import { accountsSelectors } from "../stores";
import { SellerFormDmsInfo } from "./SellerFormDmsInfo";
import { SellerFormOpeningTime } from "./SellerFormOpeningTime";

export const SellerForm: FC = () => {
    const { t } = useTranslation()
    const sellerForm = useSellerForm()
    const [areFieldsEnabled, setAreFieldsEnabled] = useState(true)
    const { data, loading } = useSelector(accountsSelectors.getSeller);
    const form = Form.useForm();

    useEffect(() => {
        if (data && data.dmsR1 && (data.dmsR1.sendEmailOnEvent === true)) setAreFieldsEnabled(true);
        else setAreFieldsEnabled(false);
    }, [])

    // sellerForm.onValuesChange = (_, values) => {
    //     if (values.mailAlert.sendEmails) setAreFieldsEnabled(true);
    //     else setAreFieldsEnabled(false);
    // }

    if (loading || !data || !sellerForm.onFinish) return <CenteredSpin size="large" />

    return (
        <DetailsRow>
            <FormWrapper form={form[0]} {...sellerForm}>
                <SellerFormOpeningTime />
                {/* TODO <SellerFormMailAlert areFieldsEnabled={areFieldsEnabled} /> */}
                <SellerFormDmsInfo />
                <CustomButton type="primary" htmlType="submit">{t("Generic.Actions.Validate")}</CustomButton>
            </FormWrapper>
        </DetailsRow>
    );
}