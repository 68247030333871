import { combineReducers } from '@reduxjs/toolkit'

import loginReducer from 'screens/login/stores/reducer'
import rpAlertReducer from 'shared/components/RPAlert/stores/reducer'
import apiReducers from './api/reducers'

import accountsReducer from 'screens/accounts_oracle/stores/reducer'
import errorLogsReducer from 'screens/errorLogs_oracle/stores/reducer'
import ordersReducer from 'screens/orders_oracle/stores/reducer'

import accountsPostgreReducer from 'screens/accounts_postgre/stores/reducer'
import errorLogsPostgreReducer from 'screens/errorLogs_postgre/stores/reducer'
import ordersPostgreReducer from 'screens/orders_postgre/stores/reducer'

import contextReducer from 'screens/context/stores/reducer'
import exadisReducer from "screens/exadis/stores/reducer"
import financialMonitoringReducer from "screens/financialMonitoring/stores/reducer"
import stockExchangeReducer from "screens/stockExchange/stores/reducer"

/**
 * Root Reducers (All RP4 Reducers)
 */

const oracleReducer = combineReducers({
  orders: ordersReducer,
  errorLogs: errorLogsReducer,
  accounts: accountsReducer,
})

const postgreReducer = combineReducers({
  orders: ordersPostgreReducer,
  errorLogs: errorLogsPostgreReducer,
  accounts: accountsPostgreReducer,
})

export const rootReducer = combineReducers({
  api: apiReducers,
  alert: rpAlertReducer,
  login: loginReducer,
  oracle: oracleReducer,
  postgre: postgreReducer,
  context: contextReducer,
  stockExchange: stockExchangeReducer,
  financialMonitoring: financialMonitoringReducer,
  exadis: exadisReducer
})

export default rootReducer