import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonBack } from 'shared/components/Buttons/ButtonBack';
import { SellerForm } from '../components/SellerForm';
import { SellerInfo } from '../components/SellerInfo';
import { accountPostgreActions } from '../stores';
import { PageContainer } from 'shared/GlobalStyles';
import { getBirCode } from 'shared/utils/utils';

/**
*  Details Seller
*/
export const SellerDetails: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const birCode = getBirCode();

        if (birCode) {
            dispatch(accountPostgreActions.getSeller(birCode));
        }

    }, []);

    return (
        <PageContainer>
            <ButtonBack linkTo="/accounts-RDS/tracking" />
            <h4>{t("Account.Details.SellerInfo")}</h4>
            <SellerInfo />
            <SellerForm />
        </PageContainer>
    );
}