import { FC } from "react";
import { actionCreators, Order, orderMassActionStatusSelector, ordersSelectedRowsSelector, OrderStatus } from "../stores";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderTableMassActionModal } from "./OrderTableMassActionModal";
import { MassActionModal } from "shared/components/Modal/MassActionModal";

export const OrderTableMassAction: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const orderMassActionStatus = useSelector(orderMassActionStatusSelector);
    const ordersSelectedRows = useSelector(ordersSelectedRowsSelector);

    const handleOk = () => {
        // Filter orders to edit
        const filteredRows = ordersSelectedRows.filter((elem: Order) => {
            if (orderMassActionStatus === OrderStatus.Solded) {
                return (elem.status === OrderStatus.ValidationRequired)
            } else if (orderMassActionStatus === OrderStatus.R1Refuse) {
                return (elem.status === OrderStatus.ValidationRequired) || (elem.status === OrderStatus.DMSTreatment)
            } else if (orderMassActionStatus === OrderStatus.DMSTreatment) {
                return (elem.needReemissionDMS)
            } return false
        });

        // Format orders for update
        const updatingRows: Order[] = filteredRows.map((elem: Order) => ({ ...elem, status: orderMassActionStatus }));

        // Update orders
        if (orderMassActionStatus === OrderStatus.DMSTreatment) {
            dispatch(actionCreators.retransmissionToDMSMass(updatingRows))
        } else {
            dispatch(actionCreators.updateOrders({ orders: updatingRows }));
        }
        dispatch(actionCreators.setSelectedOrders([]));
    };

    return <>
        {
            ordersSelectedRows && ordersSelectedRows.length > 0 && <>
                <MassActionModal
                    onOk={handleOk}
                    title={t("Generic.Actions.MassEdit")}
                >
                    <OrderTableMassActionModal />
                </MassActionModal>
            </>
        }
    </>
} 