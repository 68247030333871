import { Col } from 'antd';
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton, DetailsCard, Field } from "shared/components/Styles";
import { OrderStatus, UpdateOrderActionEnum, ordersActions, ordersSelectors } from '../stores';

export const OrderStatusAction: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: order } = useSelector(ordersSelectors.getOrder)

    const handleAction = (action: UpdateOrderActionEnum) => {
        if (order) {
            dispatch(ordersActions.updateOrderList({
                orderIds: [order.content[0].id],
                action
            }))
        }
    };

    const handleValidate = () => handleAction(UpdateOrderActionEnum.VALIDATE);
    const handleReject = () => handleAction(UpdateOrderActionEnum.REJECT);
    const handleDmsReissue = () => handleAction(UpdateOrderActionEnum.REISSUE_TO_DMS);

    const status = typeof order?.content[0].status === "string" ? parseInt(order.content[0].status) : order?.content[0].status
    const needReemissionDMS = order?.content[0].dmsReemission;

    return (<>
        {
            status === OrderStatus.ValidationRequired && (
                <Col>
                    <DetailsCard>
                        <Field>
                            <ActionButton
                                type="primary"
                                onClick={handleValidate}
                            >
                                {t("Generic.Actions.Validate")}
                            </ActionButton>

                            <ActionButton
                                type="default"
                                onClick={handleReject}
                            >
                                {t("Generic.Actions.Reject")}
                            </ActionButton>
                        </Field>
                    </DetailsCard>
                </Col>
            )
        }

        {
            needReemissionDMS && (
                <Col>
                    <DetailsCard>
                        <Field>
                            <ActionButton
                                type="primary"
                                onClick={handleDmsReissue}
                            >
                                {t("Orders.Others.ReissueDMS")}
                            </ActionButton>

                            <ActionButton
                                type="default"
                                onClick={handleReject}
                            >
                                {t("Generic.Actions.Reject")}
                            </ActionButton>
                        </Field>
                    </DetailsCard>
                </Col>
            )
        }
    </>

    )
}