import { FC } from 'react';
import {
    orderEventsSelector,
    orderEventsLoadingSelector,
    mapOrderEvents,
    orderByRefLoadingSelector,
} from '../stores';
import { Card, Col, Table } from 'antd';
import { useSelector } from 'react-redux';
import { useEventsColumns } from '../hooks/useEventsColumns';

export const EventsTable: FC = () => {
    const loadingOrder = useSelector(orderByRefLoadingSelector);
    const loadingEvents = useSelector(orderEventsLoadingSelector);
    const orderEvents = useSelector(orderEventsSelector);
    const eventsColumns = useEventsColumns();

    const eventsData = orderEvents ? mapOrderEvents(orderEvents) : undefined

    const pagination = {
        showSizeChanger: true,
    };

    return (
        <Col span={24}>
            <Card>
                <Table
                    size="small"
                    loading={loadingEvents || loadingOrder}
                    dataSource={eventsData}
                    columns={eventsColumns}
                    pagination={pagination}
                    rowKey={() => Math.random()}
                />
            </Card>
        </Col>
    );
};
