import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { PriceRender } from "shared/components/Render/PriceRender";
import { Order } from "../stores";

export function useItemsTotalColumns(): ColumnsType<Order> {
    const { t } = useTranslation()

    const DEFAULT_COLUMNS: ColumnsType<Order> = [{
        title: t("Orders.Columns.totalTaxFree"),
        dataIndex: 'totalTaxFree',
        key: 'totalTaxFree',
        render: (value) => <PriceRender value={value}></PriceRender>,
    },
    {
        title: t("Orders.Columns.totalMax"),
        dataIndex: 'totalTax',
        key: 'totalTax',
        render: (value) => <PriceRender value={value}></PriceRender>,
    },
    {
        title: t("Orders.Columns.totalTaxIncluded"),
        dataIndex: 'totalTaxIncluded',
        key: 'totalTaxIncluded',
        render: (value) => <PriceRender value={value}></PriceRender>,
    },]

    return DEFAULT_COLUMNS;
}