import { createSelector } from 'reselect';
import { RootState } from 'shared/stores'

export const ordersStateSelector = (state: RootState) => state.rp4.oracle.orders

export const orderPageSelector = createSelector(ordersStateSelector, state => state.orders)
export const orderLoadingSelector = createSelector(ordersStateSelector, state => state.loading)
export const OrdersPayloadSelector = createSelector(ordersStateSelector, state => state.requestPayload)
export const ordersColumnsSelector = createSelector(ordersStateSelector, state => state?.columns)
export const orderPageRequestSelector = createSelector(orderPageSelector, state => state?.pageable)
export const ordersSelectedRowsSelector = createSelector(ordersStateSelector, state => state.selectedRows)
export const toggleNavigation = createSelector(ordersStateSelector, state => state.showNavigation)
export const navSelectedKey = createSelector(ordersStateSelector, state => state.navigationSelectedKey)
export const orderMassActionStatusSelector = createSelector(ordersStateSelector, state => state.massActionStatus)
export const updatedOrderSelector = createSelector(ordersStateSelector, state => state.lastSingleOrderUpdated)
export const isOrderUpdated = createSelector(ordersStateSelector, state => state.lastSingleOrderUpdated && state.lastSingleOrderUpdated.id)
export const bulkUpdateStateSelector = createSelector(ordersStateSelector, state => state.bulkUpdateOrders)
export const isBulkUpdateLoading = createSelector(bulkUpdateStateSelector, state => state?.loading)
export const lastOrdersUpdated = createSelector(bulkUpdateStateSelector, state => state?.lastOrdersUpdated)
export const orderThingsSelector = createSelector(ordersStateSelector, state => state.orderThings)
export const orderItemsLoadingSelector = createSelector(orderThingsSelector, state => state?.loadingItems)
export const orderEventsLoadingSelector = createSelector(orderThingsSelector, state => state?.loadingEvents)
export const orderItemsSelector = createSelector(orderThingsSelector, state => state?.orderItems)
export const orderEventsSelector = createSelector(orderThingsSelector, state => state?.orderEvents)
export const orderByRefSelector = createSelector(ordersStateSelector, state => state.orderByRef)
export const orderByRefLoadingSelector = createSelector(orderByRefSelector, state => state?.loading)
export const orderByRefDataSelector = createSelector(orderByRefSelector, state => state?.data)
export const searchByRefSelector = createSelector(ordersStateSelector, state => state.searchByRef)
export const searchByRefLoadingSelector = createSelector(searchByRefSelector, state => state.loading)
export const searchByRefDataSelector = createSelector(searchByRefSelector, state => state.data)
export const lastVisitedTabOrdersSelector = createSelector(ordersStateSelector, state => state.lastVisitedTabOrders);