import { createSelector } from 'reselect'
import { RootState } from 'shared/stores'

export const accountsStateSelector = (state: RootState) => state.rp4.oracle.accounts

export const sellerDataSelector = createSelector(accountsStateSelector, state => state.seller.data)
export const sellerLoadingSelector = createSelector(accountsStateSelector, state => state.seller.loading)

export const buyersSelector = createSelector(accountsStateSelector, state => state.buyers)
export const buyersSelectedRowsSelector = createSelector(accountsStateSelector, state => state.buyers.selectedRows)
export const buyersMassActionTypeSelector = createSelector(accountsStateSelector, state => state.buyers.buyersMassActionType)

export const buyerByIdDataSelector = createSelector(accountsStateSelector, state => state.buyers.dataById)
export const buyerByIdLoadingSelector = createSelector(accountsStateSelector, state => state.buyers.loadingById)


export const myCountriesLoadingSelector = createSelector(accountsStateSelector, state => state.countries.loading)
export const myCountriesDataSelector = createSelector(accountsStateSelector, state => state.countries.data)

export const toggleNavigation = createSelector(accountsStateSelector, state => state.showNavigation)