import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AccountClientCode } from '../stores/types';

export function useBuyersClientCodesColumns(): ColumnsType<AccountClientCode> {
    const { t } = useTranslation()

    const DEFAULT_COLUMNS: ColumnsType<AccountClientCode> = [
        {
            title: t("Account.Columns.BuyersClientCodes.clientcode"),
            render: record => record.clientcode,
            key: 'clientcode',
        },
        {
            title: t("Account.Columns.BuyersClientCodes.validitystartdate"),
            dataIndex: 'validitystartdate',
            key: 'validitystartdate',
            render: (date: Date) => date ? dayjs(date).format('L LT') : null,
        },
        {
            title: t("Account.Columns.BuyersClientCodes.validityenddate"),
            dataIndex: 'validityenddate',
            key: 'validityenddate',
            render: (date: Date) => date ? dayjs(date).format('L LT') : null,
        },
        {
            title: t("Account.Columns.BuyersClientCodes.creationdate"),
            dataIndex: 'creationdate',
            key: 'creationdate',
            render: (date: Date) => date ? dayjs(date).format('L LT') : null,
        },
        {
            title: t("Account.Columns.BuyersClientCodes.lastupdatedate"),
            dataIndex: 'lastupdatedate',
            key: 'lastupdatedate',
            render: (date: Date) => date ? dayjs(date).format('L LT') : null,
        },
    ]
    return DEFAULT_COLUMNS;
}