import i18n from 'assets/i18n';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { alertActions } from 'shared/components/RPAlert/stores/actions';
import store from "shared/stores/store";
import { apiCallWrapper } from 'shared/utils/api';
import { ID, IPage, IRestData, Payload } from 'shared/utils/types';
import { createSuccessMsg } from 'shared/utils/utils';
import * as actions from './actions';
import { Order, OrderEvents, OrderItem, OrderList, UpdateOrderItems } from './types';
import { getByRef, getOrderEventsByOrderId, getOrderItemsByOrderId, getOrderItemsWithStocksByOrderId, getOrders, orderItemsWantedQuantity, putOrder, putOrders, retransmissionDMS } from './utils';

function* loadOrders(action: Action<Payload>) {
  try {
    const orderPage: IPage<Order> = yield apiCallWrapper<IPage<Order>>(getOrders, action.payload)
    yield put(actions.actionCreators.loadOrdersSuccess(orderPage))
  } catch (error) {
    yield put(actions.actionCreators.loadOrdersError(error))
  }
}

function* updateOrder(action: Action<Order>) {
  try {
    const updated: Order = yield apiCallWrapper(putOrder, action.payload)
    yield put(actions.actionCreators.updateOrderSuccess(updated))
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Orders.Alert.StatusUpdated"))))
    yield put(actions.actionCreators.getByRef(updated.orderRef))
  } catch (error) {
    yield put(actions.actionCreators.loadOrdersError(error))
  }
}

function* refreshOrders() {
  let PageablePayload = store.getState().rp4.oracle.orders.requestPayload;
  yield put(actions.actionCreators.loadOrders(PageablePayload))
}

function* updateOrders(action: Action<OrderList>) {
  try {

    const updated: OrderList = yield apiCallWrapper(putOrders, action.payload)
    yield put(actions.actionCreators.updateOrdersSuccess(action.payload))
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Generic.Alert.RowsUpdated", { numberRows: updated.orders.length.toString() }))))
    yield refreshOrders();
  } catch (error) {
    yield put(actions.actionCreators.updateOrdersError(error))
  }
}

function* getOrderItems(action: Action<ID>) {
  try {
    const orderItems: IRestData<OrderItem> = yield apiCallWrapper(getOrderItemsByOrderId, action.payload)
    yield put(actions.actionCreators.getOrderItemsSuccess(orderItems))
  } catch (error) {
    yield put(actions.actionCreators.getOrderItemsError(error))
  }
}

function* getOrderItemsWithStocks(action: Action<ID>) {
  try {
    const orderItemsWithStocks: IRestData<OrderItem> = yield apiCallWrapper(getOrderItemsWithStocksByOrderId, action.payload)
    yield put(actions.actionCreators.getOrderItemsWithStocksSuccess(orderItemsWithStocks))
  } catch (error) {
    yield put(actions.actionCreators.getOrderItemsWithStocksError(error))
  }
}

function* getOrderEvents(action: Action<ID>) {
  try {
    const orderEvents: Array<OrderEvents> = yield apiCallWrapper(getOrderEventsByOrderId, action.payload)
    yield put(actions.actionCreators.getOrderEventsSuccess(orderEvents))
  } catch (error) {
    yield put(actions.actionCreators.getOrderEventsError(error))
  }
}

function* getOrderByRef(action: Action<string>) {
  try {
    const ref = action.payload
    const orderByRef: Order = yield apiCallWrapper(getByRef, ref)
    yield put(actions.actionCreators.getByRefSuccess(orderByRef))
    yield call(getOrderThingsByOrder, orderByRef)
  } catch (error) {
    yield put(actions.actionCreators.getByRefError(error))
  }
}

function* getOrderThingsByOrder(order: Order) {
  // Order items
  if (order.status === 0) yield put(actions.actionCreators.getOrderItemsWithStocks(order.id))
  else yield put(actions.actionCreators.getOrderItems(order.id))

  // Order events
  yield put(actions.actionCreators.getOrderEvents(order.id))
}

function* updateOrderRetransmissionDMS(action: Action<number>) {
  try {
    const order: Order = yield apiCallWrapper<Order>(retransmissionDMS, action.payload)
    yield put(actions.actionCreators.retransmissionToDMSSuccess(order));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Orders.Alert.RetransmittedToDMS"))));
  } catch (error) {
    yield put(actions.actionCreators.retransmissionToDMSError(error))
  }
}

function* updateOrderRetransmissionDMSMass(action: Action<Order[]>) {
  try {
    yield all(action.payload.map(x => apiCallWrapper<Order>(retransmissionDMS, x.id)))
    yield put(actions.actionCreators.updateOrdersSuccess({ orders: action.payload }))
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Generic.Alert.RowsUpdated", { numberRows: action.payload.length.toString() }))))
    yield refreshOrders();
  } catch (error) {
    yield put(actions.actionCreators.retransmissionToDMSError(error))
  }
}

function* updateOrderItemsWantedQuantity(action: Action<UpdateOrderItems>) {
  try {
    const orderItems: OrderItem = yield apiCallWrapper<OrderItem>(orderItemsWantedQuantity, action.payload)
    yield put(actions.actionCreators.updateOrderItemsWantedQuantitySuccess(orderItems))
  } catch (error) {
    yield put(actions.actionCreators.updateOrderItemsWantedQuantityError(error))
  }
}

function* updateOrderItemsWantedQuantityList(action: Action<UpdateOrderItems[]>) {
  try {
    for (const item of action.payload) {
      yield apiCallWrapper<OrderItem>(orderItemsWantedQuantity, item)
    }
    yield put(actions.actionCreators.updateOrderItemsWantedQuantityListSuccess());
    yield put(actions.actionCreators.getOrderItemsWithStocks(action.payload[0].orderId));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Orders.Alert.WantedQuantityUpdated"))));
  } catch (error) {
    yield put(actions.actionCreators.updateOrderItemsWantedQuantityError(error))
  }
}

export default function* () {
  yield takeLatest(actions.Type.LOAD_ORDERS, loadOrders)
  yield takeLatest(actions.Type.PUT_ORDER, updateOrder)
  yield takeLatest(actions.Type.PUT_ORDERS, updateOrders)
  yield takeLatest(actions.Type.GET_ORDER_ITEMS, getOrderItems)
  yield takeLatest(actions.Type.GET_ORDER_ITEMS_WITH_STOCKS, getOrderItemsWithStocks)
  yield takeLatest(actions.Type.GET_ORDER_EVENTS, getOrderEvents)
  yield takeLatest(actions.Type.GET_BY_REF, getOrderByRef)
  yield takeLatest(actions.Type.RETRANSMISSION_TO_DMS, updateOrderRetransmissionDMS)
  yield takeLatest(actions.Type.RETRANSMISSION_TO_DMS_MASS, updateOrderRetransmissionDMSMass)
  yield takeLatest(actions.Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY, updateOrderItemsWantedQuantity)
  yield takeLatest(actions.Type.UPDATE_ORDER_ITEMS_WANTED_QUANTITY_LIST, updateOrderItemsWantedQuantityList)
}