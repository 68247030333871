
import api, { unwrapAxiosResult } from 'shared/utils/api'
import { API_URLs } from 'shared/utils/constants'
import { ID, IPage } from 'shared/utils/types'
import { getArgs } from 'shared/utils/utils'
import { StockExchange, StockExchangeLnk, StockExchangeLnkRequestPayload, StockExchangeRequestPayload } from './types'

const STOCK_EXCHANGE_URI = `${API_URLs.POSTGRE.ORDERS}/products-exchanges`

export const getStockExchange = (payload: StockExchangeRequestPayload) =>
    unwrapAxiosResult<IPage<StockExchange>>(api.get<IPage<StockExchange>>(
        `${STOCK_EXCHANGE_URI}?${getArgs(payload)}`
    ));

export const getStockExchangeLnk = (productReference: ID, payload: StockExchangeLnkRequestPayload) =>
    unwrapAxiosResult<IPage<StockExchangeLnk>>(api.get<IPage<StockExchangeLnk>>(
        `${STOCK_EXCHANGE_URI}/${productReference}/dealers?${getArgs(payload)}`
    ));
