import { createAction } from 'redux-actions';
import { IPage } from 'shared/utils/types';
import { ErrorLogs, ErrorLogsRequestPayload } from './types';
import { ColumnsType } from "antd/lib/table";

export enum Type {
  LOAD_ERROR_LOGS = 'LOAD_ERROR_LOGS',
  LOAD_ERROR_LOGS_SUCCESS = 'LOAD_ERROR_LOGS_SUCCESS',
  LOAD_ERROR_LOGS_ERROR = 'LOAD_ERROR_LOGS_ERROR',
  TOGGLE_NAVIGATION_MENU = 'TOGGLE_NAVIGATION_MENU',
  NAVIGATION_SELECTED_KEY = 'NAVIGATION_SELECTED_KEY',
  SET_ERROR_LOGS_COLUMNS = 'SET_ERROR_LOGS_COLUMNS',
}

export const actionCreators = {
  loadLogs: createAction<ErrorLogsRequestPayload, ErrorLogsRequestPayload>(Type.LOAD_ERROR_LOGS, (payload: ErrorLogsRequestPayload) => payload),
  loadLogsSuccess: createAction<IPage<ErrorLogs>, IPage<ErrorLogs>>(Type.LOAD_ERROR_LOGS_SUCCESS, (errorLogsPage: IPage<ErrorLogs>) => errorLogsPage),
  loadLogsError: createAction(Type.LOAD_ERROR_LOGS_ERROR),
  setErrorLogsColumns: createAction<ColumnsType<ErrorLogs>, ColumnsType<ErrorLogs>>(Type.SET_ERROR_LOGS_COLUMNS, (columns: ColumnsType<ErrorLogs>) => columns),
  toggleNavigationOpen: createAction(Type.TOGGLE_NAVIGATION_MENU),
  navigationSelectedKey: createAction<Array<string>, Array<string>>(Type.NAVIGATION_SELECTED_KEY, (payload: Array<string>) => payload)
}