import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useRowAsLink } from 'shared/hooks/useRowAsLink';
import { Seller } from '../stores';

export function useSellersColumns(): ColumnsType<Seller> {
  const { t } = useTranslation()

  const DEFAULT_COLUMNS: ColumnsType<Seller> = [
    {
      title: t("Account.Columns.commercialName"),
      dataIndex: ["account", "commercialName"],
      key: 'commercialName',
    },
    {
      title: t("Account.Columns.tresorCode"),
      dataIndex: ["account", "tresorCode"],
      key: 'tresorCode'
    },
    {
      title: t("Account.Columns.bircode"),
      dataIndex: ["account", "sellerCode"],
      key: 'birCode'
    },
    {
      title: t("Account.Columns.establishmentNumber"),
      dataIndex: ["account", "establishmentNumber"],
      key: 'establishmentNumber',
    },
  ]

  return useRowAsLink<Seller>(DEFAULT_COLUMNS, (record: Seller) => `seller`)
}