import i18n from 'assets/i18n';
import { Action } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { ACCOUNT_POSTGRE_FETCHS, GetCountriesResponse } from 'screens/accounts_postgre/stores';
import { BircodeData, DatabaseType, RolePrivilege, contextActions } from "screens/context/stores";
import { loginActions } from "screens/login/stores/actions";
import { alertActions } from 'shared/components/RPAlert/stores/actions';
import store from 'shared/stores/store';
import { apiCallWrapper } from 'shared/utils/api';
import { ID, IPage, Payload } from "shared/utils/types";
import { createSuccessMsg } from 'shared/utils/utils';
import { getBuyerById, getCountries, patchDmsR1 } from ".";
import { Type, accountOracleActions } from "./actions";
import { Buyer, BuyerSubscribeRequest, BuyerSubscribeResponse, Country as CountryOracle, DmsAccountInfo, Seller } from "./types";
import { getBuyersByBirCode, getSellerByBirCode, postSubscribeMass, postUnsubscribeMass, putDmsInfo } from "./utils";

interface CountryMap {
  [countrycode: string]: CountryMapValue
}

interface CountryMapValue extends CountryOracle {
  bircodeDataMap: BircodeMap;
}
interface BircodeMap {
  [bircode: string]: BircodeData
}

function* loadSellerByBirCode() {
  try {
    const account: Seller = yield apiCallWrapper<Seller>(getSellerByBirCode)
    yield put(accountOracleActions.loadSellerByBirCodeSuccess(account))
  } catch (error) {
    yield put(accountOracleActions.loadSellerByBirCodeError(error))
  }
}

function* loadBuyersByBirCode(action: Action<Payload>) {
  try {
    const buyersPage: IPage<Buyer> = yield apiCallWrapper<IPage<Buyer>>(getBuyersByBirCode, action.payload)
    yield put(accountOracleActions.loadBuyersByBirCodeSuccess(buyersPage))
  } catch (error) {
    yield put(accountOracleActions.loadBuyersByBirCodeError(error))
  }
}

function* loadBuyerById(action: Action<ID>) {
  try {
    const buyer: Buyer = yield apiCallWrapper<Buyer>(getBuyerById, action.payload)
    yield put(accountOracleActions.loadBuyerByIdSuccess(buyer))
  } catch (error) {
    yield put(accountOracleActions.loadBuyerByIdError(error))
  }
}

function* updateBuyer(action: Action<DmsAccountInfo>) {
  try {
    const updatedDMSInfo: DmsAccountInfo = yield apiCallWrapper(putDmsInfo, action.payload)
    yield put(accountOracleActions.updateDmsInfoSuccess(updatedDMSInfo));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.BuyerUpdated"))))
  } catch (error) {
    yield put(accountOracleActions.updateDmsInfoError(error))
  }
}

function* updateSeller(action: Action<Seller>) {
  try {
    const updatedSellerInfo: Seller = yield apiCallWrapper(patchDmsR1, action.payload)
    yield put(accountOracleActions.updateSellerDmsR1Success(updatedSellerInfo));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.SellerUpdated"))))
  } catch (error) {
    yield put(accountOracleActions.updateSellerDmsR1Error(error))
  }
}

function* subscribeBuyerMass(action: Action<BuyerSubscribeRequest>) {
  try {
    const buyers: BuyerSubscribeResponse[] = yield apiCallWrapper(postSubscribeMass, action.payload);
    yield put(accountOracleActions.subscribeBuyerMassSuccess(buyers));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.SubscribeSuccess"))))
  } catch (err) {
    yield put(accountOracleActions.subscribeBuyerMassError())
  }
}

function* unsubscribeBuyerMass(action: Action<BuyerSubscribeRequest>) {
  try {
    const buyers: BuyerSubscribeResponse[] = yield apiCallWrapper(postUnsubscribeMass, action.payload);
    yield put(accountOracleActions.unsubscribeBuyerMassSuccess(buyers));
    yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.UnsubscribeSuccess"))))
  } catch (err) {
    yield put(accountOracleActions.unsubscribeBuyerMassError())
  }
}

function* loadCountries() {
  try {
    const myRolePrivilege = store.getState().rp4.context.roleData.myRolePrivilege
    const myBircode = store.getState().rp4.context.myBirCodeData?.bircode
        || store.getState().rp4.login?.user?.bircode
        || undefined;
    let countriesOracle: CountryOracle[] = yield apiCallWrapper(getCountries);
    if (!countriesOracle) countriesOracle = [];
    const countriesPostgre: GetCountriesResponse = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.getCountries);

    // build postgres map for set operations
    const countriesPostgreMap: CountryMap = {};
    for (const countryPostgres of countriesPostgre.countries) {
      const country: CountryMapValue = {
        countrycode: countryPostgres.countryCode,
        countryid: countryPostgres.id,
        countrylogistic: countryPostgres.countryLogistic,
        active: countryPostgres.active,
        currencycode: countryPostgres.currencyCode,
        currencysymbol: countryPostgres.currencyCode,
        timezone: countryPostgres.timezone,
        urgentflagdefault: countryPostgres.urgentFlagDefault,
        urgentflagfeature: countryPostgres.urgentFlagFeature,
        bircodeDataList: [],
        bircodeDataMap: {}
      };
      const bircodeDataMap: BircodeMap = {};
      for (const account of countryPostgres.accountList) {
        bircodeDataMap[account.sellerCode] = {
          bircode: account.sellerCode,
          businessName: account.organizationName,
          city: account.city,
          databaseType: DatabaseType.POSTGRE,
          countryCode: country.countrycode
        };
      }
      country.bircodeDataMap = bircodeDataMap;
      countriesPostgreMap[country.countrycode] = country;
    }

    // build oracle map for set operations
    const countriesOracleMap: CountryMap = {};
    for (const country of countriesOracle) {
      const countryMapValue: CountryMapValue = { ...country, bircodeDataMap: {} };
      const bircodeDataMap: BircodeMap = {};
      for (const bircodeData of country.bircodeDataList) {
        bircodeData.databaseType = DatabaseType.ORACLE;
        bircodeData.countryCode = country.countrycode;
        if (!!bircodeData.bircode) {
          bircodeDataMap[bircodeData.bircode] = bircodeData
        }
      }
      countryMapValue.bircodeDataMap = bircodeDataMap;
      countriesOracleMap[country.countrycode] = countryMapValue;
    }

    // merge oracle and postgres with oracle predominance

    for (const countryCode of Object.keys(countriesPostgreMap)) {

      // country only defined in postgres
      if (!countriesOracleMap[countryCode]) {
        countriesOracleMap[countryCode] = countriesPostgreMap[countryCode];
        continue;
      }

      // country existing in both env, iterating over R1s, giving precedence to oracle
      // nb: future RP4 provider call may overwrite this
      for (const bircode of Object.keys(countriesPostgreMap[countryCode].bircodeDataMap)) {

        // R1 only defined in postgres
        if (!countriesOracleMap[countryCode].bircodeDataMap[bircode]) {
          countriesOracleMap[countryCode].bircodeDataMap[bircode] = countriesPostgreMap[countryCode].bircodeDataMap[bircode];
          continue;
        }
      }

    }

    // map to model
    let res: CountryOracle[] = [];
    for (const countryCode of Object.keys(countriesOracleMap)) {
      const bircodeDataList: BircodeData[] = [...Object.values(countriesOracleMap[countryCode].bircodeDataMap)];
      const country: CountryOracle = {
        countryid: countriesOracleMap[countryCode].countryid,
        countrycode: countriesOracleMap[countryCode].countrycode,
        currencycode: countriesOracleMap[countryCode].currencycode,
        currencysymbol: countriesOracleMap[countryCode].currencysymbol,
        urgentflagfeature: countriesOracleMap[countryCode].urgentflagfeature,
        urgentflagdefault: countriesOracleMap[countryCode].urgentflagdefault,
        countrylogistic: countriesOracleMap[countryCode].countrylogistic,
        timezone: countriesOracleMap[countryCode].timezone,
        active: countriesOracleMap[countryCode].active,
        bircodeDataList: bircodeDataList
      };
      res.push(country);
    }

    yield put(accountOracleActions.loadCountriesSuccess(res));
    yield put(contextActions.setAllBIRCodeDataList(res));

    let disconnect = true;
    let tempBirCodeData: BircodeData | undefined;

    if (!!myBircode) {
      for (const country of res) {
        for (const birCodeData of country.bircodeDataList) {
          if (birCodeData.bircode === myBircode) {
            disconnect = false;
            tempBirCodeData = birCodeData;
          }
        }
      }
    }

    if ((myRolePrivilege === RolePrivilege.R1 && disconnect) || myRolePrivilege === RolePrivilege.NONE) {
      yield put(loginActions.authLogout())
    } else if (tempBirCodeData) {
      yield put(contextActions.setMyBirCodeData(tempBirCodeData));
    }

  } catch (error) {
    yield put(accountOracleActions.loadCountriesError(error));
  }
}

export default function* () {
  yield takeLatest(Type.LOAD_SELLER_BY_BIRCODE, loadSellerByBirCode)
  yield takeLatest(Type.LOAD_BUYERS_BY_BIRCODE, loadBuyersByBirCode)
  yield takeLatest(Type.LOAD_BUYER_BY_ID, loadBuyerById)
  yield takeLatest(Type.UPDATE_BUYER, updateBuyer)
  yield takeLatest(Type.LOAD_COUNTRIES, loadCountries)
  yield takeLatest(Type.UPDATE_SELLER_DMS_R1, updateSeller)
  yield takeLatest(Type.SUBSCRIBE_BUYER_MASS, subscribeBuyerMass)
  yield takeLatest(Type.UNSUBSCRIBE_BUYER_MASS, unsubscribeBuyerMass)
}