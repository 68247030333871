import { put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import * as actions from './actions'
import { StockExchange, StockExchangeLnk, StockExchangeLnkRequestPayload, StockExchangeRequestPayload } from './types';
import { getStockExchange, getStockExchangeLnk } from './utils';
import { IPage } from 'shared/utils/types'
import { apiCallWrapper } from 'shared/utils/api';

function* loadStockExchange(action: Action<StockExchangeRequestPayload>) {
    try {
        const stockExchange: IPage<StockExchange> = yield apiCallWrapper<IPage<StockExchange>>(getStockExchange, action.payload)
        yield put(actions.actionCreators.loadStockExchangeSuccess(stockExchange))
    } catch (error) {
        yield put(actions.actionCreators.loadStockExchangeError(error))
    }
}

function* loadStockExchangeLnk(action: Action<StockExchangeLnkRequestPayload>) {
    try {
        const ref = action.payload.ref
        delete action.payload.ref
        const stockExchangeLnk: IPage<StockExchangeLnk> = yield apiCallWrapper<IPage<StockExchangeLnk>>(getStockExchangeLnk, ref, action.payload)
        yield put(actions.actionCreators.loadStockExchangeLnkSuccess(stockExchangeLnk))
    } catch (error) {
        yield put(actions.actionCreators.loadStockExchangeLnkError(error))
    }
}

export default function* () {
    yield takeLatest(actions.Type.LOAD_STOCK_EXCHANGE, loadStockExchange)
    yield takeLatest(actions.Type.LOAD_STOCK_EXCHANGE_LNK, loadStockExchangeLnk)
}