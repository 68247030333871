import { Action, handleActions } from 'redux-actions'
import { Type } from './actions'
import { AlertState, IAlertType } from './types'

const INITIAL_STATE: AlertState = {
  alertToDisplay: undefined,
  previousAlerts: []
}

export default handleActions<AlertState, any>(
  {
    [Type.ALERT_MSG]: (state: AlertState, action: Action<IAlertType>) => ({
      ...state,
      alertToDisplay: action.payload
    }),
    [Type.ALERT_MSG_DISPLAY]: (state: AlertState, action: Action<boolean>) => ({
      ...state,
      alertToDisplay: {
        ...state.alertToDisplay,
        displayed: action.payload
      }
    }),
    [Type.ALERT_MSG_PENDING]: (state: AlertState, action: Action<IAlertType>) => ({
      ...state,
      previousAlerts: state.previousAlerts
        ? state.previousAlerts.splice(0, 0, action.payload)
        : [action.payload]
    })
  },
  INITIAL_STATE
)