import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from 'shared/stores/store';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from 'assets/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import dayjs from "dayjs"

// dayjs config
dayjs.extend(require('dayjs/plugin/localizedFormat'));

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Suspense fallback={<div></div>}>
      <CookiesProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Provider>
      </CookiesProvider>
    </Suspense>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
