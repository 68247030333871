import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { apiCallWrapper } from 'shared/utils/api';
import * as actions from './actions';
import { UploadFilePayload, UploadFileResponse } from './types';
import { uploadFileExadis } from './utils';

function* uploadFile(action: Action<UploadFilePayload>) {
    try {
        console.log(action.payload)
        const response: UploadFileResponse = yield apiCallWrapper(uploadFileExadis, action.payload)
        yield put(actions.actionCreators.uploadFileSuccess(response))
    } catch (error) {
        yield put(actions.actionCreators.uploadFileError(error))
    }
}

export default function* () {
    yield takeLatest(actions.Type.UPLOAD_FILE, uploadFile)
}