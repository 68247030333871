import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { ItemsStocks } from '../components/ItemsStocks';
import { orderByRefDataSelector, OrderItem } from '../stores'
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { priceRender } from 'shared/components/Render/PriceRender';
import { percentRender } from 'shared/components/Render/PercentRender';
import { twoDigitRounded } from 'shared/components/Render/TwoDigitsRounder';
import { OrderStatusTag } from '../components/OrderStatusTag';

export function useItemsColumns(): ColumnsType<OrderItem> {
    const { t } = useTranslation();

    const DEFAULT_COLUMNS: ColumnsType<OrderItem> = [
        {
            title: t("Orders.Columns.reference"),
            dataIndex: 'reference',
            key: 'reference',
            sorter: (a, b) => Number(a.reference) - Number(b.reference),
        },
        {
            title: t("Orders.Columns.designation"),
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: t("Orders.Columns.deliveredQuantity"),
            dataIndex: 'deliveredQuantity',
            key: 'deliveredQuantity',
        },
        {
            title: t("Orders.Columns.wantedQuantity"),
            dataIndex: 'wantedQuantity',
            key: 'wantedQuantity'
        },
        {
            title: t("Orders.Columns.unitPrice"),
            dataIndex: 'price',
            key: 'price',
            render: (value) => priceRender(twoDigitRounded(value)),
        },
        {
            title: t("Orders.Columns.discountRate"),
            dataIndex: 'discountRate',
            key: 'discountRate',
            render: percentRender,
        },
        {
            title: t("Orders.Columns.vat"),
            dataIndex: 'vat',
            key: 'vat',
            render: percentRender,
        },
        {
            title: t("Orders.Columns.amount"),
            dataIndex: 'amount',
            key: 'amount',
            render: (_value, record) => priceRender(twoDigitRounded((record.price * (1 - record.discountRate / 100)) * record.deliveredQuantity)),
        },
        {
            title: t("Orders.Columns.dmsAvailableQuantity"),
            dataIndex: 'dmsAvailableQuantity',
            key: 'dmsAvailableQuantity',
            render: (_: number, record: OrderItem) => <ItemsStocks orderItem={record} />
        }, {
            title: t("Orders.Columns.status"),
            key: 'currentItemStatus',
            dataIndex: 'currentItemStatus',
            render: (status: number) => <OrderStatusTag status={status} />,
        },
    ]

    const [columns, setColumns] = useState(DEFAULT_COLUMNS)
    const orderByRef = useSelector(orderByRefDataSelector);

    useEffect(() => {
        if (!orderByRef) return;

        setColumns(DEFAULT_COLUMNS.filter((column: ColumnType<OrderItem>) =>
            (!((column.dataIndex === "dmsAvailableQuantity") && (orderByRef?.status !== 0)))
        ))

    }, [orderByRef])

    return columns;
}