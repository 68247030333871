import { Button, Card, DatePicker, Form, InputNumber, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import { Dispatch, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorLogsInterfaceId } from "../hooks/useErrorLogsInterfaceId";
import { GetErrorLogsPayload } from "../stores";

interface ErrorLogsFormProps {
    onValidation: Function
    payload?: GetErrorLogsPayload
    setPayload: Dispatch<GetErrorLogsPayload>
}

export const ErrorLogsForm: FC<ErrorLogsFormProps> = (
    { onValidation, payload, setPayload }
) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const interfaceIdList = useErrorLogsInterfaceId();
    const [createdDateTime, setCreatedDateTime] = useState<any>();

    const handleOnFinish = (values: any) => {
        Object.keys(values).forEach(key => {
            const value = values[key];
            if (!value && payload) {
                delete values[key]
                delete payload[key]
            }
            if (key === 'creationDateTime' && value) {
                let fileCreatedDate = dayjs(value).format("MM/DD/YYYY")
                fileCreatedDate =
                    `${fileCreatedDate} ${values["creationDateTimeHours"] ?
                        dayjs(values["creationDateTimeHours"]).format("LTS")
                        :
                        "00:00:00"
                    }`;
                values[key] = fileCreatedDate;
                delete values["creationDateTimeHours"];
            }
        })
        setPayload({
            ...payload,
            ...values
        })
        onValidation()
    }

    return <Card style={{ margin: "15px 0px" }}>
        <Form
            form={form}
            onFinish={handleOnFinish}
            layout="inline"
        >
            <Form.Item
                // rules={[{ required: true }]}
                label={t("ErrorLogs.Columns.interfaceId")} name="errorType">
                <Select style={{ minWidth: "150px" }} allowClear={true}>
                    {interfaceIdList.map(x => <Select.Option value={x}>{x}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label={t("ErrorLogs.Columns.fileLotNumber")} name="datasetNumber">
                <InputNumber />
            </Form.Item>
            <Form.Item
                label={t("Generic.Others.Date")} name="creationDateTime">
                <DatePicker style={{ minWidth: "150px" }} onChange={(value) => { setCreatedDateTime(value) }} />
            </Form.Item>
            <Form.Item label={t("Generic.Others.Hour")} name="creationDateTimeHours">
                <TimePicker disabled={!createdDateTime} style={{ minWidth: "150px" }} />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">{t("Generic.Actions.Validate")}</Button>
            </Form.Item>
        </Form>
    </Card>
}