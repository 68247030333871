import Table, { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import { FC, Dispatch } from "react"
import { useSelector } from "react-redux"
import { handleTableChange } from "shared/utils/utils"
import { errorLogsDataSelector, errorLogsLoadingSelector } from "../stores"
import { ErrorLogs, ErrorLogsRequestPayload } from "../stores/types"

interface ErrorLogsTableProps {
    columns: ColumnsType<ErrorLogs>
    setColumns: Dispatch<ColumnsType<ErrorLogs>>
    payload: ErrorLogsRequestPayload
    setPayload: Dispatch<ErrorLogsRequestPayload>
}

export const ErrorLogsTable: FC<ErrorLogsTableProps> = ({ columns, setColumns, payload, setPayload }) => {

    const errorLogsData = useSelector(errorLogsDataSelector)
    const errorLogsLoading = useSelector(errorLogsLoadingSelector)

    const pagination: TablePaginationConfig = {
        showSizeChanger: true,
        pageSize: errorLogsData?.size,
        total: errorLogsData?.totalElements,
        current: errorLogsData?.number ? errorLogsData.number + 1 : undefined
    }

    return <Table
        size="middle"
        loading={errorLogsLoading}
        pagination={pagination}
        dataSource={errorLogsData?.content}
        columns={columns}
        onChange={handleTableChange(payload, setPayload, columns, setColumns)}
    >
    </Table>

}