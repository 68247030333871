import { Button, Form, FormInstance, InputNumber, Radio, Select } from 'antd';
import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Value } from "shared/components/Styles";
import { ButtonRadio, DetailsCardForm } from '../Styles';
import { Buyer, accountPostgreActions } from "../stores";
import { capitalizeFirstLetter } from 'shared/utils/utils';
import lodash from 'lodash';
import { CustomGreenRedSwitch } from '../Styles';

interface BuyerFormProps {
    form: FormInstance<any>
    buyer: Buyer
}

export const BuyerForm: FC<BuyerFormProps> = ({ form, buyer }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dmsAccountInfo = !!buyer.dmsAccountInfo;

    const [localBuyer, setLocalBuyer] = useState(buyer);

    const onFinish = () => dispatch(accountPostgreActions.updateBuyers({
        records: [ localBuyer ]
    }));

    const onValuesChange = (changedValues: any, _: any) => { 
        setLocalBuyer(lodash.merge({}, localBuyer, changedValues));
    };

    const validateMessages = {
        required: t("Generic.Alert.RequiredField")
    };

    return (
        <DetailsCardForm>
            <div className="fields-wrapper">
                <Form
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    onValuesChange={onValuesChange}
                    initialValues={localBuyer}
                    form={form}
                >
                    <Form.Item
                        label={capitalizeFirstLetter(t("Account.Details.activeComLink"))}
                        name={["accountCommercialLinks", "active"]}
                        valuePropName="checked"
                    >
                        <CustomGreenRedSwitch />
                    </Form.Item>

                    {dmsAccountInfo && <Form.Item
                        label={t("Account.Details.UpdateDeliveryMode")}
                        name={['dmsAccountInfo', 'deliveryMethod']}
                        rules={[{ required: true }]}
                    >
                        <Select style={{ minWidth: "200px" }}>
                            <Select.Option value="G">{t("Generic.DeliveryMethod.G")}</Select.Option>
                            {
                                ["ES", "IT"].includes(buyer.countryCode) &&
                                <Select.Option value="D">{t("Generic.DeliveryMethod.D")}</Select.Option>
                            }
                            <Select.Option value="M">{t("Generic.DeliveryMethod.M")}</Select.Option>
                        </Select>
                    </Form.Item>}

                    {dmsAccountInfo && <Form.Item
                        label={t("Account.Details.ClientAutomaticValidation")}
                        name={['dmsAccountInfo', 'automaticOrderTransfer']}
                        rules={[{ required: true }]}
                    >
                        <ButtonRadio >
                            <Radio value={true} >{t("Generic.Actions.Yes")}</Radio>
                            <Radio value={false} >{t("Generic.Actions.No")}</Radio>
                        </ButtonRadio>
                    </Form.Item>}

                    {dmsAccountInfo && <Form.Item
                        label={t("Account.Details.manageCreditLimit")}
                        name={['dmsAccountInfo', 'manageCreditLimit']}
                        rules={[{ required: true }]}
                    >
                        <ButtonRadio >
                            <Radio value={true} >{t("Generic.Actions.Yes")}</Radio>
                            <Radio value={false} >{t("Generic.Actions.No")}</Radio>
                        </ButtonRadio>
                    </Form.Item>}

                    {dmsAccountInfo && <Form.Item
                        label={t("Account.Details.IfYesOrderAmmount")}
                        name={['dmsAccountInfo', 'maxOrderAmount']}
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            min={0}
                            type="text"
                        />
                    </Form.Item>}

                    {dmsAccountInfo && <Form.Item
                        label={t("Account.Details.InDMSFacturation")}
                    >
                        <Value>{buyer.dmsAccountInfo.actualInvoiceAmount}</Value>
                    </Form.Item>}

                    {dmsAccountInfo && <Form.Item
                        label={t("Account.Details.IfYesMaximumOutstanding")}
                        name={['dmsAccountInfo', 'maxInvoiceAmount']}
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            min={0}
                            type="text"
                        />
                    </Form.Item>}

                    <Button type="primary" htmlType="submit">{t("Generic.Actions.Validate")}</Button>

                </Form >
            </div>
        </DetailsCardForm>
    )

}