import Table, { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Dispatch, FC } from "react";
import { useSelector } from "react-redux";
import { handleTableChange } from "shared/utils/utils";
import { StockExchangeLnk, StockExchangeLnkRequestPayload, stockExchangeLnkDataSelector, stockExchangeLnkLoadingSelector } from "../stores";

interface StockExchangeLnkTableProps {
    columns: ColumnsType<StockExchangeLnk>
    setColumns: Dispatch<ColumnsType<StockExchangeLnk>>
    payload: StockExchangeLnkRequestPayload
    setPayload: Dispatch<StockExchangeLnkRequestPayload>
}

export const StockExchangeLnkTable: FC<StockExchangeLnkTableProps> = ({ columns, setColumns, payload, setPayload }) => {

    const stockExchangeLnkData = useSelector(stockExchangeLnkDataSelector)
    const stockExchangeLnkLoading = useSelector(stockExchangeLnkLoadingSelector)

    const pagination: TablePaginationConfig = {
        showSizeChanger: true,
        pageSize: stockExchangeLnkData?.size,
        total: stockExchangeLnkData?.totalElements,
        current: stockExchangeLnkData?.number ? stockExchangeLnkData.number + 1 : undefined
    }

    return <Table
        rowKey={(record) => record.r1 + " - " + record.countryCode}
        size="middle"
        loading={stockExchangeLnkLoading}
        pagination={pagination}
        dataSource={stockExchangeLnkData?.content}
        columns={columns}
        onChange={handleTableChange(payload, setPayload, columns, setColumns)}
    >
    </Table>

}