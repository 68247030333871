import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        returnNull: false,
        //  language to use if translations in user language are not available
        fallbackLng: "en",
        debug: true,
        //  string or array of namespaces to load
        ns: "translation",
        //  default namespace used if not passed to 'translation function'
        defaultNS: "translation",

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;