import { Space } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer } from 'shared/GlobalStyles';
import { ButtonBack } from "shared/components/Buttons/ButtonBack";
import { DetailsRow } from 'shared/components/Styles';
import { EventsTable } from "../components/EventsTable";
import { ItemsTable } from '../components/ItemsTable';
import { OrderDetailsMain } from "../components/OrderDetailsMain";
import { ordersActions, ordersSelectors } from '../stores';

import { ID } from 'shared/utils/types';
import { getBirCode } from 'shared/utils/utils';
import { OrderDetailsPDF } from '../components/OrderDetailsPDF';

export const OrdersDetails: FC = () => {
    const { id, ref } = useParams<{ id: string, ref: string }>();
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate();

    const orderByRef = useSelector(ordersSelectors.getOrderByRef);

    const handleLoadOrderStuff = (id: ID) => {
        dispatch(ordersActions.getOrderItems(id))
        dispatch(ordersActions.getOrderEvents(id))
    }

    useEffect(() => {
        if (ref && !orderByRef.data) {
            dispatch(ordersActions.getOrderByRef({
                payload: {
                    sellerCode: getBirCode()!,
                    orderRef: ref
                }, navigate
            }))
        }

        if (ref && orderByRef && orderByRef.data && orderByRef.data.content[0]) {
            handleLoadOrderStuff(orderByRef.data.content[0].id);
        }

        if (id && !ref) {
            dispatch(ordersActions.getOrder({
                sellerCode: getBirCode()!,
                orderId: parseInt(id)
            }));
            handleLoadOrderStuff(id);
        }

    }, [id, ref, orderByRef.data]);

    return (
        <PageContainer>
            <Space>
                <ButtonBack backTo={() => navigate(-1)} />
                <OrderDetailsPDF />
            </Space>

            <h4>{t("Orders.Details.OrderInfo")}</h4>
            <OrderDetailsMain />

            <h4>{t("Orders.Details.OrderDetails")}</h4>
            <DetailsRow>
                <ItemsTable />
            </DetailsRow>

            <h4>{t("Orders.Details.OrderEvents")}</h4>
            <DetailsRow>
                <EventsTable />
            </DetailsRow>

        </PageContainer>
    );
};