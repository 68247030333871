import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client'
import { RpUser } from 'screens/login/stores/types'
import store from "shared/stores/store"
import { AUTH_FLOWS } from 'shared/utils/constants';

const settingsIDPLegacy: UserManagerSettings = {
  authority: process.env.REACT_APP_IDP_URI,
  client_id: process.env.REACT_APP_CLIENT_ID,
  acr_values: "secure/name/x509-FormLogin-Arca-Rnet-R1-R2-R3/uri",
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  redirect_uri: `${window.location.origin}/1ss-Oidc-Callback`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  silent_redirect_uri: `${window.location.origin}/1ss-Oidc-Callback-Silent`,
  userStore: new WebStorageStateStore({ store: localStorage })
};

const settingsIDPTwin: UserManagerSettings = {
  authority: process.env.REACT_APP_IDP_URI,
  client_id: process.env.REACT_APP_CLIENT_ID,
  acr_values: "secure/name/x509-FormLogin-Rnet-R1-R2-R3/uri",
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  redirect_uri: `${window.location.origin}/1ss-Oidc-Callback`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  silent_redirect_uri: `${window.location.origin}/1ss-Oidc-Callback-Silent`,
  userStore: new WebStorageStateStore({ store: localStorage })
};

const userManagerIDPLegacy = new UserManager(settingsIDPLegacy);
const userManagerIDPTwin = new UserManager(settingsIDPTwin);

export const pkceRenewToken = () => {

  const flow = localStorage.getItem('auth_flow') ||
    store.getState().rp4.login.flow ||
    'N/A';

  switch (flow) {
    case AUTH_FLOWS.IDP_LEGACY:
      return userManagerIDPLegacy.signinSilent() as Promise<RpUser>;
    case AUTH_FLOWS.IDP_TWIN:
      return userManagerIDPTwin.signinSilent() as Promise<RpUser>;
    default:
      break;
  }
  throw new Error(`Unknown renew flow ${flow}`);
}

export const pkceLogin = () => {

  const flow = localStorage.getItem('auth_flow') ||
    store.getState().rp4.login.flow ||
    'N/A';

  switch (flow) {
    case AUTH_FLOWS.IDP_LEGACY:
      return userManagerIDPLegacy.signinPopup() as Promise<RpUser>;
    case AUTH_FLOWS.IDP_TWIN:
      return userManagerIDPTwin.signinPopup() as Promise<RpUser>;
    default:
      break;
  }
  throw new Error(`Unknown signin flow ${flow}`);
}

export const pkceLogOut = () => {

  const flow = localStorage.getItem('auth_flow') ||
    store.getState().rp4.login.flow ||
    'N/A';

  switch (flow) {
    case AUTH_FLOWS.IDP_LEGACY:
      return userManagerIDPLegacy.signoutRedirect();
    case AUTH_FLOWS.IDP_TWIN:
      return userManagerIDPTwin.signoutRedirect();
    default:
      break;
  }
  throw new Error(`Unknown signout flow ${flow}`);
}
