import api, { unwrapAxiosResult } from 'shared/utils/api'
import { API_URLs } from 'shared/utils/constants'
import { FinancialMonitoringLinksRequestPayload, FinancialMonitoringResponse } from './types'

const FINANCIAL_MONITORING_URI = (birCode: string, countryCode: string) => `${API_URLs.POSTGRE.ACCOUNT}/sellers/${birCode}-${countryCode}/annual-sales`

export const getStockExchange = (payload: FinancialMonitoringLinksRequestPayload) =>
    unwrapAxiosResult<FinancialMonitoringResponse>(api.get<FinancialMonitoringResponse>(
        `${FINANCIAL_MONITORING_URI(payload.birCode, payload.countryCode)}`
    ))

