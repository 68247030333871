import { createSelector } from 'reselect';
import { RootState } from 'shared/stores';

export const accountsStateSelector = (state: RootState) => state.rp4.postgre.accounts;

export const accountsSelectors = {
    getBuyer: createSelector(accountsStateSelector, state => state.getBuyer),
    getBuyers: createSelector(accountsStateSelector, state => state.getBuyers),
    getCountries: createSelector(accountsStateSelector, state => state.getCountries),
    getSeller: createSelector(accountsStateSelector, state => state.getSeller),

    buyersColumns: createSelector(accountsStateSelector, state => state.buyersColumns),
    buyersLastVisitedTab: createSelector(accountsStateSelector, state => state.buyersLastVisitedTab),
    buyersSelectedRows: createSelector(accountsStateSelector, state => state.buyersSelectedRows),
    buyersMassActionType: createSelector(accountsStateSelector, state => state.buyersMassActionType)
}