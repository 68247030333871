import { Tabs, TabsProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageContainer } from 'shared/GlobalStyles';
import { useConfigTranslate } from "shared/hooks/useConfigTranslate";
import { getBirCode } from "shared/utils/utils";
import { ErrorLogsForm } from "../components/ErrorLogsForm";
import { ErrorLogsTable } from "../components/ErrorLogsTable";
import { useErrorLogsColumns } from "../hooks/useErrorLogsColumns";
import { ErrorLogs, GetErrorLogsPayload, defaultPayload, errorLogsPostgreActions, errorLogsSelectors } from "../stores";

export const ErrorLogsTracking: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const defaultColumns = useErrorLogsColumns();
    const errorLogs = useSelector(errorLogsSelectors.getErrorLogs)
    const errorLogsColumns = useSelector(errorLogsSelectors.errorLogsColumns)
    const [showRes, setShowRes] = useState(false);

    const columns = errorLogsColumns ? errorLogsColumns : defaultColumns;

    const setPayload = (payload: GetErrorLogsPayload) => { dispatch(errorLogsPostgreActions.getErrorLogs(payload)) }
    const setColumns = (columns: ColumnsType<ErrorLogs>) => { dispatch(errorLogsPostgreActions.setErrorLogsColumns(columns)) }

    const [key, setKey] = useState(0);

    useEffect(() => {
        setPayload(errorLogs.payload!)
    }, [])

    const locale = useConfigTranslate();
    useEffect(() => {
        handleReinitFilters()
    }, [locale])

    const handleReinitFilters = () => {
        reinitTab()
        setKey(key + 1)
    }

    const reinitTab = () => {
        setColumns(defaultColumns)
        setPayload({
            ...defaultPayload,
            sellerCode: getBirCode()!
        })
    }

    const items: TabsProps['items'] = [{
        key: key.toString(),
        label: t("ErrorLogs.Navigation.Tabs"),
        children: <>

            <ErrorLogsForm
                onValidation={() => setShowRes(true)}
                payload={errorLogs.payload}
                setPayload={setPayload}
            />

            {showRes && <ErrorLogsTable
                columns={columns}
                payload={errorLogs.payload}
                setColumns={setColumns}
                setPayload={setPayload}
            />}
        </>
    }]

    return <PageContainer>
        <Tabs
            items={items}
        />
    </PageContainer>
}