import { RpUser } from './types'
import { Action, handleActions } from 'redux-actions'
import { Type } from './actions'
import { LoginPayload, LoginState } from './types'
import { getUserFromLocalStorage } from 'shared/utils/utils'

const INITIAL_STATE: LoginState = {
  loading: false,
  user: getUserFromLocalStorage(),
  isSidebarVisible: false,
  flow: 'N/A'
}

const loginReducer = handleActions<LoginState, any>(
  {
    [Type.AUTH_LOGIN]: (state: LoginState, action: Action<LoginPayload>) => ({
      ...state,
      loading: true,
      user: undefined,
      flow: action.payload.flow
    }),
    [Type.AUTH_LOGIN_SUCCESS]: (state: LoginState, action: Action<RpUser>) => ({
      ...state,
      loading: false,
      user: action.payload
    }),
    [Type.AUTH_LOGIN_FAILED]: (state: LoginState) => ({
      ...state,
      loading: false,
      user: undefined
    }),
    [Type.AUTH_LOGOUT]: (state: LoginState, actions: Action<LoginPayload>) => ({
      ...state,
      loading: false,
      user: undefined,
      isSidebarVisible: false
    }),
    [Type.TOGGLE_SIDEBAR]: (state: LoginState) => ({
      ...state,
      isSidebarVisible: state.user && !state.user.expired ? !state.isSidebarVisible : false
    }),
    [Type.AUTH_LOGIN_REFRESH_TOKEN]: (state: LoginState, action: Action<LoginPayload>) => ({
      ...state
    })
  },
  INITIAL_STATE
)

export default loginReducer