import i18n from "assets/i18n";
import { Action } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { alertActions } from "shared/components/RPAlert/stores/actions";
import { apiCallWrapper } from "shared/utils/api";
import { ApiActionLaunch, IPage, UpdateRecordResponse } from "shared/utils/types";
import { createSuccessMsg } from "shared/utils/utils";
import { Type, accountPostgreActions } from "./actions";
import { Buyer, FetchApiAccounts, GetCountriesResponse, Seller } from "./types";
import { ACCOUNT_POSTGRE_FETCHS } from "./utils";

function* getBuyer(action: Action<ApiActionLaunch<FetchApiAccounts["getBuyer"]>>) {
    try {
        const buyer: IPage<Buyer> = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.getBuyer, action.payload)
        yield put(accountPostgreActions.getBuyerSuccess(buyer))
    } catch (error) {
        yield put(accountPostgreActions.getBuyerError(error))
    }
}

function* getBuyers(action: Action<ApiActionLaunch<FetchApiAccounts["getBuyers"]>>) {
    try {
        const buyers: IPage<Buyer> = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.getBuyers, action.payload)
        yield put(accountPostgreActions.getBuyersSuccess(buyers))
    } catch (error) {
        yield put(accountPostgreActions.getBuyersError(error))
    }
}

function* updateBuyersAdhesion(action: Action<ApiActionLaunch<FetchApiAccounts["updateBuyersAdhesion"]>>) {
    try {
        const res: UpdateRecordResponse<Buyer> = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.updateBuyersAdhesion, action.payload)
        yield put(accountPostgreActions.updateBuyersAdhesionSuccess(res))
        yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.BuyerUpdated"))))
    } catch (error) {
        yield put(accountPostgreActions.updateBuyersAdhesionError(error))
    }
}

function* updateBuyers(action: Action<ApiActionLaunch<FetchApiAccounts["updateBuyers"]>>) {
    try {
        const res: UpdateRecordResponse<Buyer> = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.updateBuyers, action.payload)
        yield put(accountPostgreActions.updateBuyersSuccess(res))
        yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.BuyerUpdated"))))
    } catch (error) {
        yield put(accountPostgreActions.updateBuyersError(error))
    }
}

function* getCountries() {
    try {
        const res: GetCountriesResponse = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.getCountries)
        yield put(accountPostgreActions.getCountriesSuccess(res))
    } catch (error) {
        yield put(accountPostgreActions.getCountriesError(error))
    }
}

function* getSeller(action: Action<ApiActionLaunch<FetchApiAccounts["getSeller"]>>) {
    try {
        const res: Seller = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.getSeller, action.payload)
        yield put(accountPostgreActions.getSellerSuccess(res))
    } catch (error) {
        yield put(accountPostgreActions.getSellerError(error))
    }
}

function* updateSellers(action: Action<ApiActionLaunch<FetchApiAccounts["updateSellers"]>>) {
    try {
        const res: UpdateRecordResponse<Seller> = yield apiCallWrapper(ACCOUNT_POSTGRE_FETCHS.updateSellers, action.payload)
        yield put(accountPostgreActions.updateSellersSuccess(res))
        yield put(alertActions.alertMsg(createSuccessMsg(i18n.t("Account.Alert.SellerUpdated"))))
    } catch (error) {
        yield put(accountPostgreActions.updateSellersError(error))
    }
}

export default function* () {
    yield takeLatest(Type.GET_BUYER, getBuyer)
    yield takeLatest(Type.GET_BUYERS, getBuyers)
    yield takeLatest(Type.UPDATE_BUYERS_ADHESION, updateBuyersAdhesion)
    yield takeLatest(Type.UPDATE_BUYERS, updateBuyers)
    yield takeLatest(Type.GET_COUNTRIES, getCountries)
    yield takeLatest(Type.GET_SELLER, getSeller)
    yield takeLatest(Type.UPDATE_SELLERS, updateSellers)
}