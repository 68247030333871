import { Action, handleActions } from 'redux-actions'
import { MyColumnsType } from 'screens/accounts_postgre/hooks/useBuyersColumns'
import { FetchReduceFn, UpdateStateSuccessFn } from 'shared/utils/types'
import { fetchReduceFn, updateStateSuccessFn } from 'shared/utils/utils'
import { Type } from './actions'
import { FetchApiOrders, Order, OrdersState, UpdateOrderActionEnum } from './types'

export const defaultPayload = {
  page: 0,
  pageSize: 20,
  sort: "creationDate,desc"
}

const INITIAL_STATE: OrdersState = {
  getOrders: { data: undefined, loading: false, payload: undefined },
  getOrder: { data: undefined, loading: false, payload: undefined },
  getOrderByRef: { data: undefined, loading: false, payload: undefined },
  getOrderEvents: { data: undefined, loading: false, payload: undefined },
  getOrderItems: { data: undefined, loading: false, payload: undefined },
  updateOrderListStatus: { data: undefined, loading: false, payload: undefined },
  updateOrderItemsListWantedQuantity: { data: undefined, loading: false, payload: undefined },

  ordersLastVisitedTab: undefined,
  ordersSelectedRows: [],
  ordersColumns: undefined,
  ordersFromSuperOrder: []
}

const updateStateSuccess: UpdateStateSuccessFn<FetchApiOrders> = updateStateSuccessFn
const fetchReduce: FetchReduceFn<FetchApiOrders> = fetchReduceFn

export default handleActions<OrdersState, any>(
  {
    ...fetchReduce({
      param: "getOrder",
      start: Type.GET_ORDER,
      success: Type.GET_ORDER_SUCCESS,
      error: Type.GET_ORDER_ERROR,
    }),

    ...fetchReduce({
      param: "getOrders",
      start: Type.GET_ORDERS,
      success: Type.GET_ORDERS_SUCCESS,
      error: Type.GET_ORDERS_ERROR
    }),

    ...fetchReduce({
      param: "getOrderByRef",
      start: Type.GET_ORDER_BY_REF,
      success: Type.GET_ORDER_BY_REF_SUCCESS,
      error: Type.GET_ORDER_BY_REF_ERROR,
      additionnalParams: ["getOrder"]
    }),

    ...fetchReduce({
      param: "getOrderEvents",
      start: Type.GET_ORDER_EVENTS,
      success: Type.GET_ORDER_EVENTS_SUCCESS,
      error: Type.GET_ORDER_EVENTS_ERROR,
    }),

    ...fetchReduce({
      param: "getOrderItems",
      start: Type.GET_ORDER_ITEMS,
      success: Type.GET_ORDER_ITEMS_SUCCESS,
      error: Type.GET_ORDER_ITEMS_ERROR,
    }),

    ...fetchReduce({
      param: "updateOrderList",
      start: Type.UPDATE_ORDER_LIST,
      success: Type.UPDATE_ORDER_LIST_SUCCESS,
      error: Type.UPDATE_ORDER_LIST_ERROR,
      onSuccess: updateStateSuccess(["getOrders", "getOrder"], "id")
    }),

    ...fetchReduce({
      param: "updateOrderItemsListWantedQuantities",
      start: Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY,
      success: Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_SUCCESS,
      error: Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_ERROR,
      onSuccess: updateStateSuccess(["getOrders", "getOrder"], "id")
    }),

    [Type.SET_ORDERS_COLUMNS]: (state: OrdersState, action: Action<MyColumnsType<Order>>) => ({
      ...state,
      ordersColumns: action.payload
    }),
    [Type.SET_ORDERS_LAST_VISITED_TAB]: (state: OrdersState, action: Action<string>) => ({
      ...state,
      ordersLastVisitedTab: action.payload
    }),
    [Type.SET_ORDERS_SELECTED_ROWS]: (state: OrdersState, action: Action<Order[]>) => ({
      ...state,
      ordersSelectedRows: action.payload
    }),
    [Type.SET_ORDERS_MASS_ACTION_STATUS]: (state: OrdersState, action: Action<UpdateOrderActionEnum>) => ({
      ...state,
      ordersMassActionStatus: action.payload
    }),
    [Type.SET_ORDERS_FROM_SUPERORDER]: (state: OrdersState, action: Action<Order[]>) => {
      return {
        ...state,
        ordersFromSuperOrder: action.payload
      };
    }
  },
  INITIAL_STATE
)
