import { Drawer, Menu, Button, Spin, Row, Card } from 'antd';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../utils/constants';

export const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  background: #F0F2F5;
  padding: .7em;
`;

export const Value = styled.span`
  font-weight: bold;
`;


export const Rem = styled(Value)`
  color: #8bdf4b;
`;

export const WrongPrice = styled(Value)`
  font-size: small;
  font-weight: lighter;
  color: red;
  text-decoration: line-through;
`;

export const FinalAmount = styled(Value)`
  font-weight: bolder;
`;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
`;


export const DetailsCard = styled(Card)`
  width: 390px;
  .ant-card-body {
    padding: .5em;
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;
  margin: 0 1%;
`

export const InfosRow = styled(Row)`
  display: flex;  
  width: 100%;
  justify-content: space-between;
  background: #F0F2F5;
  padding: .7em;
  margin: 0 !important;
`;

interface ToggleProps {
  $collapsed: boolean
}

export const TableWrapper = styled.div`
    min-height:340px;
`;

const slideRightAnimation = keyframes`
from {
  left: 0;
}
to {
  left: 152px;
}
`;

export const ToggleButton = styled(Button)`
  width: ${(props: ToggleProps) => props.$collapsed ? '100%' : null};
  animation: ${(props: ToggleProps) => !props.$collapsed ? slideRightAnimation : null} .3s forwards 1;
`;

export const PrimaryHeader = styled.div`
  top: 0;
  background-color: #333;
  display: flex;
  min-height: 35px;
  width: 100%;
  align-items: center;
  padding: 0 1em;
  position: fixed;
  z-index: 999;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 35px;
  align-items: center;
  padding: 0 45px;
`;

export const SecondaryHeaderWrapper = styled.div`
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Read';
  background: ${COLORS.WHITE};
  padding: 0 59px;
  position: relative;
  left: 0;
  right: 0;
  margin-top: 35px;
  box-shadow: 0 1px 0 0 rgba(204,204,204,.5);
`;

// Dark theme
interface SideBarProps {
  theme?: 'black'
}

export const DrawerSideBar = styled(Drawer)`
  .ant-drawer-content {
    background: ${(props: SideBarProps) => props.theme === 'black' ? '#333' : '#FFF'};
    color: ${(props: SideBarProps) => props.theme === 'black' ? '#FFF' : 'rgba(0, 0, 0, 0.85)'};
  }
  .ant-drawer-wrapper-body {
    height: calc(100% - 35px);
    margin: 35px 0 0 0;
  }
  .ant-drawer-header {
    background: ${(props: SideBarProps) => props.theme === 'black' ? '#333' : '#FFF'};
    border-bottom: ${(props: SideBarProps) => props.theme === 'black' ? '1px solid #000' : '1px solid #f0f0f0'};
  }
  .ant-drawer-title {
    color: ${(props: SideBarProps) => props.theme === 'black' ? '#FFF' : 'rgba(0, 0, 0, 0.85)'};
  }
  .ant-drawer-close {
    color: ${(props: SideBarProps) => props.theme === 'black' ? '#FF3' : 'rgba(0, 0, 0, 0.45)'};
  }
`;

export const StyledMenu = styled(Menu)`
  display: flex;
  align-items: center;
  height: 100%;
  background: #333;
  color: #FFF;
  &.ant-menu-horizontal {
    line-height: 36px !important;
    border-bottom: 1px solid #333 !important;
    &>.ant-menu-submenu {
      vertical-align: bottom;
    }
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-width: 240px;
  align-items: center;
  justify-content: space-between;
  > button {
    color: #FFF;
  }
  color:#fff;
`;

export const RenaultGroupeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RenaultLogo = styled.img`
  width: 30px;
  height: 30px;
  padding: 5px;
`;

export const RenaultGroup = styled.img`
  width: 208px;
  height: 50px;
`;

export const RenaultGroupText = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
`;

export const ConnectHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #ccc;
`;

export const LoginButton = styled(Button)`
  background-color: transparent;
  color: #e1e1e1;
  border: none;
  :hover {
    color: #fff;
    text-decoration: none!important;
    background-color: transparent;
  }
`;

export const IconPhoneNumber = styled.img`
  width: 11px;
  height: 14px;
`;

export const PhoneNumberWrapper = styled.div`
  width: 165px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CallRate = styled.div`
  font-size: 12px;
  color: #999;
  font-family: Read;
`;

export const IconUserWrapper = styled.div`
  display: flex;
  width: 29px;
  height: 29px;
  background-color: #f7f7f7;
  position: relative;
  border-radius: 50%;
`;

export const IconUser = styled.img`
  width: 15px;
  height: 17px;
  object-fit: contain;
  position: absolute;
  background-size: cover;
  top: 50%;
  left: 49%;
  transform: translate(-50%,-50%);
`;

export const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  font-family: Read;
  font-style: normal;
  color: #ccc;
  margin-left: 8px;
  line-height: 1.1;
  letter-spacing: .5px;
`;

export const RP4Logo = styled.img`
  width: 149px;
  height: 50px;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 37px;
  background-color: rgb(51, 51, 51);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 15px;
`;

export const FooterEndContainer = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 550px;
  display: flex;
  font-family: Read;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

export const GDPR = styled.div`
  color: #777;
  :hover {
    color: #fff;
  }
`;

export const TermsOfService = styled.div`
  color: #777;
  :hover {
    color: #fff;
  }
`;

export const Cookies = styled.div`
  color: #777;
  :hover {
    color: #fff;
  }
`;

export const Copyright = styled.div`
  color: #777;
`;

export const StyledButton = styled(Button)`
  max-width: 200px;
`;

export const HomeLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Groupe = styled.span`
  font-weight: bold;
  color: #FFCC33;
`;

export const Renault = styled.span`
  font-weight: bold;
  color: #FFFFFF;
`;

export const CenteredSpin = styled(Spin)`
  margin: auto;
  width: 100%;
`