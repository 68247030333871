import { ColumnsType } from 'antd/lib/table';
import { Payload } from 'shared/utils/types';
import { ID, IPage } from 'shared/utils/types';

/**
 * Order Types
 */
export interface Order {
  id: ID
  dmsflux: string
  dmsnbr: string
  headererrorid: string
  headererrorlabel: string
  arccreateddate: Date | string
  serveddate: Date | string
  needReemissionDMS: boolean
  nbOccurence?: Number
  creationDate: Date | string
  lastUpdateDate: Date | string
  orderRef: string
  externalOrderRef: string
  countryCode: string
  status: number
  currencyCode: string
  origin: string
  orderEventIsLate: boolean
  orderEvents?: any
  stockList: string
  sellerName: string
  sellerBirCode: string
  sellerTresorCode: string
  sellerTreatedByFirstname: string
  sellerTreatedByLastname: string
  r1treatedbyfirstname: string
  r1treatedbylastname: string
  r1GarageId: string
  buyerName: string
  messageToSeller: string

  r1MessageReadStatus: string

  orderComment: string
  deliveryMethod: string
  totalTaxFree: number
  totalMax: number
  totalTaxIncluded: number
  _links: any
  urgentFlag: boolean

  // Front required
  key?: ID
  chosen?: boolean
}

export enum OrderStatus {
  ValidationRequired = 0,
  WaitToBeSent = 2,
  DMSTreatment = 4,
  FullyServed = 5,
  Solded = 6,
  R1Refuse = 7,
  DMSRefuse = 8,
  ExaInVerification = 10,
  ExaTechnicalIssue = 11,
  ExaPartiallyServed = 16,
  NoStatus = 77
}

export const OrderStatusColors = {
  [OrderStatus.ValidationRequired]: 'purple',
  [OrderStatus.WaitToBeSent]: 'gold',
  [OrderStatus.DMSTreatment]: 'yellow',
  [OrderStatus.FullyServed]: 'green',
  [OrderStatus.Solded]: 'cyan',
  [OrderStatus.R1Refuse]: 'red',
  [OrderStatus.DMSRefuse]: 'orange',
  [OrderStatus.ExaInVerification]: 'geekblue',
  [OrderStatus.ExaTechnicalIssue]: 'red',
  [OrderStatus.ExaPartiallyServed]: 'lime',
  [OrderStatus.NoStatus]: 'black',
}

export type OrdersStatusLabel = keyof typeof OrderStatus;

export interface OrderItem {
  id: ID
  currentItemStatus: number
  creationDate: Date | string
  lastUpdateDate: Date | string
  reference: string
  substituteItem: string
  isUrgent: boolean
  designation: string
  wantedQuantity: number
  deliveredQuantity: number
  price: number
  discountRate: number
  discountCode: string
  vat: number
  dmsAvailableQuantity?: number
  isStockAvailable?: boolean
  warehouses?: Warehouse[]
}

export enum WarehouseCode {
  /** ALL AVAILABLE IN DMS */
  DMS = 1,
  CDP = 2,

  /** Available in Country Warehouse */
  SIGMPR = 3,
  CDE = 3,

  /** Available in France */
  MPR88 = 4,

  /** Not available in France */
  DIFF = 5,
}

export type WarehouseCodeLabel = keyof typeof WarehouseCode

export type WarehouseData = {
  code: WarehouseCode
  ordinal: number
  color: string
}

export type Warehouse = {
  availableQuantity: number
  code: WarehouseCodeLabel
  confirmedQuantity: number
  order: number
  type: string
}

export type WarehouseCodeData = {
  code: WarehouseCode,
  color: string
}

export const WarehouseColors = {
  [WarehouseCode.DMS]: "green",
  [WarehouseCode.CDP]: "green",
  [WarehouseCode.SIGMPR]: "yellow",
  [WarehouseCode.CDE]: "yellow",
  [WarehouseCode.MPR88]: "orange",
  [WarehouseCode.DIFF]: "red",
}

export interface OrderEvents {
  [x: string]: any
  id: ID
  eventDate: Array<string>
  eventType: Array<string>
}

export interface IBulkState {
  loading: boolean
  lastOrdersUpdated?: Array<Order>
}

export interface OrderThings {
  loadingItems?: boolean
  loadingEvents?: boolean
  orderItems?: Array<OrderItem>
  orderEvents?: Array<OrderEvents>
}

export interface OrderDetails {
  loading: boolean
  data?: Order
}

export interface OrdersState {
  loading: boolean
  requestPayload: Payload
  orders?: IPage<Order>
  searchByRef: OrderDetails
  orderByRef: OrderDetails
  orderThings?: OrderThings
  lastSingleOrderUpdated?: Order
  bulkUpdateOrders?: IBulkState
  selectedRows: Array<Order>
  lastVisitedTabOrders?: string
  navigationSelectedKey: Array<string>
  showNavigation: boolean
  massActionStatus: OrderStatus
  columns?: ColumnsType<Order>
}

export interface OrderList {
  orders: Array<Order>
}

export interface UpdateOrderItems {
  orderId: ID
  orderItemId: ID
  fields: UpdateOrderItemsFields
}

export interface UpdateOrderItemsFields {
  wantedQuantity: number
}