import { FC } from 'react';
import { ordersSelectors } from '../stores';
import { Card, Col, Table } from 'antd';
import { useSelector } from 'react-redux';
import { useEventsColumns } from '../hooks/useEventsColumns';

export const EventsTable: FC = () => {
    const { loading: loadingOrder } = useSelector(ordersSelectors.getOrder);
    const { loading: loadingEvents, data: orderEvents } = useSelector(ordersSelectors.getOrderEvents)
    const eventsColumns = useEventsColumns();

    const pagination = {
        showSizeChanger: true,
    };

    return (
        <Col span={24}>
            <Card>
                <Table
                    size="small"
                    loading={loadingEvents || loadingOrder}
                    dataSource={orderEvents}
                    columns={eventsColumns}
                    pagination={pagination}
                    rowKey={() => Math.random()}
                />
            </Card>
        </Col>
    );
};
