import iconPhoneNumber from 'assets/img/icon-phone.svg';
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    CallRate, ConnectHeaderWrapper, IconPhoneNumber, PhoneNumber, PhoneNumberWrapper
} from '../Styles';

export const HeaderApp: FC = () => {
    const { t } = useTranslation();

    return <ConnectHeaderWrapper>
        <PhoneNumberWrapper>
            <IconPhoneNumber src={iconPhoneNumber} />
            <PhoneNumber>{t("loginPage.phoneNumber")}</PhoneNumber>
            <CallRate>{t("loginPage.callRate")}</CallRate>
        </PhoneNumberWrapper>
    </ConnectHeaderWrapper>
}