import Oidc, { Log } from 'oidc-client';
import { useSelector } from 'react-redux';
import { SimplePageContainer } from '../../Styles';
import { userSelector } from '../login/stores/selectors';

export const OidcCallbackSilent = () => {
  Log.logger = console;
  Log.level = Log.DEBUG;

  const context = useSelector(userSelector);

  var config = {
    userStore: new Oidc.WebStorageStateStore({ store: context }),
    response_mode: 'query'
  }

  new Oidc.UserManager(config).signinSilentCallback()
    .then(user => console.log('[OIDC Callback Silent Scene] - User Manager => ', user))
    .catch(error => console.error('[OIDC Callback Silent Scene] - Error => ', error))

  return (
    <SimplePageContainer>
      <h1>Hello React</h1>
      <h2>I'm callback silent scene</h2>
    </SimplePageContainer>
  );
}