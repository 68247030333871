import { Popover, PopoverProps, Tooltip } from "antd";
import { FC } from "react";
import { contextActions, orderPopoverVisibleSelector } from "../stores";
import { useDispatch, useSelector } from "react-redux"
import { FileSearchOutlined } from "@ant-design/icons";

import { HeaderToggleButton, } from '../Styles'
import { OrderSelect } from "./OrderSelect";
import { useTranslation } from "react-i18next";

export const OrderPopover: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const visible = useSelector(orderPopoverVisibleSelector);
  const setVisible = (visible: boolean) => dispatch(contextActions.setOrderPopoverVisible(visible))
  const popoverProps: PopoverProps = {
    destroyTooltipOnHide: true,
    trigger: "click",
    placement: "top",
    open: visible,
    onOpenChange: () => { setVisible(!visible) },
    content: <OrderSelect />
  }
  return <Tooltip title={t("Countries.SearchAnOrder")}>
    <Popover {...popoverProps}>
      <HeaderToggleButton>
        <FileSearchOutlined />
      </HeaderToggleButton>
    </Popover>
  </Tooltip>
}