import { Table, TablePaginationConfig, Tabs } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Payload } from 'shared/utils/types';
import { handleTableChangeV2 } from 'shared/utils/utils';
import {
  Buyer,
  accountPostgreActions,
  accountsSelectors
} from '../stores';
import { OrderTableMassAction } from './BuyersTableMassAction';

const { TabPane } = Tabs;

interface BuyersTableProps {
  columns: ColumnsType<Buyer>
  setColumns: Dispatch<ColumnsType<Buyer>>
  payload: Payload
  setPayload: Dispatch<Payload>
  tabBarExtraContent: JSX.Element
}

/**
 * Table to displays Account Sellers Commercial Links
 */
export const BuyersTable: FC<BuyersTableProps> = ({ columns, setColumns, payload, setPayload, tabBarExtraContent }) => {
  const { t } = useTranslation()
  const { data, loading } = useSelector(accountsSelectors.getBuyers)
  const buyersSelectedRows = useSelector(accountsSelectors.buyersSelectedRows);
  const dispatch = useDispatch()

  const rowSelection: TableRowSelection<Buyer> = {
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      dispatch(accountPostgreActions.setBuyersSelectedRows(selectedRows));
    },
    selectedRowKeys: buyersSelectedRows.map(o => o.accountCommercialLinks.id)
  };

  const pageSizeOptions = () => {
    if (data && data.totalElements < 10) return [data.totalElements.toString()]
    if (data && data.totalElements < 20) return ['10', data.totalElements.toString()]
    if (data && data.totalElements < 50) return ['10', '20', data.totalElements.toString()]
    if (data && data.totalElements < 100) return ['10', '20', '50', data.totalElements.toString()]
    if (data && data.totalElements) return ['10', '20', '50', '100', data.totalElements.toString()]
    return ['10', '20', '50', '100'];
  }

  const pagination: TablePaginationConfig = {
    showTotal: (total) => `Total : ${total}`,
    showSizeChanger: true,
    pageSize: data?.size,
    total: data?.totalElements,
    current: data?.number ? data.number + 1 : undefined,
    pageSizeOptions: pageSizeOptions()
  }

  return (
    <Tabs defaultActiveKey="1" tabBarExtraContent={tabBarExtraContent}>
      <TabPane tab={t("Account.Navigation.Buyers")} key="1">
        <Table
          rowKey={record => record.accountCommercialLinks.id}
          rowSelection={rowSelection}
          size="middle"
          loading={loading}
          columns={columns}
          dataSource={data && data.content ? data.content : []}
          rowClassName={"clickable"}
          pagination={pagination}
          onChange={handleTableChangeV2(payload, setPayload, columns, setColumns)}
        />
        <OrderTableMassAction />
      </TabPane>
    </Tabs>
  );
};

