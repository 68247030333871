import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolePrivilege } from 'screens/context/stores';
import { myRolePrivilegeSelector } from 'screens/context/stores/selectors';
import stringFilterDropdown from 'shared/components/Filters/StringFilterDropdown';
import { useRowAsLink } from 'shared/hooks/useRowAsLink';
import { StockExchange } from "../stores/types";

export function useStockExchangeColumns(): ColumnsType<StockExchange> {
    const { t } = useTranslation();
    const myRolePrivilege = useSelector(myRolePrivilegeSelector);

    let COLUMNS: ColumnsType<StockExchange> = [
        {
            title: t("StockExchange.Columns.productReference"),
            dataIndex: 'productReference',
            key: 'productReference',
        },
        {
            title: t("StockExchange.Columns.productModelCode"),
            dataIndex: 'productModelCode',
            key: 'productModelCode'
        },
        {
            title: t("StockExchange.Columns.countrycode"),
            dataIndex: 'countryCode',
            key: 'countrycode',
        },
        {
            title: t("StockExchange.Columns.shortName"),
            dataIndex: 'shortName',
            key: 'shortName',
        },
        {
            title: t("StockExchange.Columns.brandName"),
            dataIndex: 'brandName',
            key: 'brandName'
        },
        {
            title: t("StockExchange.Columns.updateDate"),
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (date: Date) => date ? dayjs(date).format('L LT') : null,
        },
    ]

    if (myRolePrivilege === RolePrivilege.CENTRAL_ADMIN) {
        COLUMNS = COLUMNS.map(x => {
            if (x.key !== "countrycode") return x;
            else {
                return {
                    ...x,
                    ...stringFilterDropdown('StockExchange.Columns.countrycode')
                }
            }
        })
    }

    return useRowAsLink<StockExchange>(COLUMNS, (record: StockExchange) => `lnk/${record.countryCode.toString()}/${record.productReference.toString()}`)

}