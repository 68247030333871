import Oidc, { Log } from 'oidc-client';
import { SimplePageContainer } from '../../Styles';

export const OidcCallback = () => {
  Log.logger = console;
  Log.level = Log.DEBUG;

  var config = {
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
    response_mode: 'query'
  }

  new Oidc.UserManager(config).signinPopupCallback()
    .then(user => console.log('[OIDC Callback Scene] - User Manager => ', user))
    .catch(error => console.error('[OIDC Callback Scene] - Error => ', error))

  return (
    <SimplePageContainer>
      <h1>Hello React</h1>
      <h2>I'm callback scene</h2>
    </SimplePageContainer>
  );
}