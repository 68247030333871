const API_BASE_URL_ORACLE = process.env.REACT_APP_BASE_URL_ORACLE
const API_BASE_URL_POSTGRE = process.env.REACT_APP_BASE_URL_POSTGRE
const API_BASE_URL_RP4 = process.env.REACT_APP_BASE_URL_RP4

export const API_URLs = {
  POSTGRE: {
    ORDERS: API_BASE_URL_POSTGRE + "orders",
    ACCOUNT: API_BASE_URL_POSTGRE + "accounts",
    ERROR_LOGS: API_BASE_URL_POSTGRE + "error-logs"

    // ORDERS: API_BASE_URL_POSTGRE + "orders/v1", // for local
    // ERROR_LOGS: API_BASE_URL_POSTGRE + "error-logs/v1" // for local
  },
  ORACLE: {
    ORDERS: API_BASE_URL_ORACLE + "service-orders/orders",
    ACCOUNT: API_BASE_URL_ORACLE + "service-accounts",
    ERROR_LOGS: API_BASE_URL_ORACLE + "service-error-logs"
  },
  RP4_API: API_BASE_URL_RP4
}

export const API_TIMEOUT = 1000000
export const RPARTS_DOCUMENTATION_FR = '/static/media/RParts_New_R1_Admin_site_For_R1_Standard_Functionalities_French.pptx'
export const RPARTS_DOCUMENTATION_EN = '/static/media/RParts_New_R1_Admin_site_For_R1_Standard_Functionalities_English.pptx'
export const OLD_BACKOFFICE_URL = 'https://rnet.parts.renault.com/rps/aCreateHomeActionOut.do';
export const RPARTS_URL = 'https://www.rparts.dcs2.renault.com/'



export const API_ERROR_CODES = {
  UNKNOWN: 'UNKNOWN'
}

export const DEFAULT_DELAY = 100

// RP4 Main Colors
export const COLORS = {
  WHITE: '#FFF',
  RPARTS_YELLOW: '#FFCC33',
  BG_GREY_2: '#F5F6F6',
  BLUE: '#0072C6',
  BLUE_GREY: '#007297',
  TEXT_DEFAULT: '#4A4A4A',
  GREY: '#CCC',
  MENU_GREY: '#53565A',
  LIGHT_GREY: '#F7F7F7',
  DARK_GREY: '#848484',
  BLACK: '#000',
  RED: 'red'
}

export const AUTH_FLOWS = {
  IDP_LEGACY: 'IDP_LEGACY',
  IDP_TWIN: 'IDP_TWIN'
};