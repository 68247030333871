import { createAction } from 'redux-actions';
import { UploadFilePayload, UploadFileResponse } from './types';

export enum Type {
    UPLOAD_FILE = "UPLOAD_FILE",
    UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR",

    TOGGLE_NAVIGATION_MENU = "TOGGLE_NAVIGATION_MENU",
    NAVIGATION_SELECTED_KEY = "NAVIGATION_SELECTED_KEY",
}

export const actionCreators = {
    uploadFile: createAction<UploadFilePayload, UploadFilePayload>(Type.UPLOAD_FILE, payload => payload),
    uploadFileSuccess: createAction<UploadFileResponse, UploadFileResponse>(Type.UPLOAD_FILE_SUCCESS, payload => payload),
    uploadFileError: createAction(Type.UPLOAD_FILE_ERROR),

    toggleNavigationOpen: createAction(Type.TOGGLE_NAVIGATION_MENU),
    navigationSelectedKey: createAction<Array<string>, Array<string>>(Type.NAVIGATION_SELECTED_KEY, payload => payload)
}