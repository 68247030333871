import { AutoComplete, AutoCompleteProps } from "antd"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from 'react-router-dom'
import { SelectLabel, SelectWrapper } from "../Styles"
import { BIRCodeDataListSelector, RolePrivilege, birCodeDataListToOptionList, contextActions, garagePopoverValueSelector, garagePopoverVisibleSelector, isRolePrivilegeGranted } from "../stores"

export const GarageSelect: FC = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const visible = useSelector(garagePopoverVisibleSelector)
    const setVisible = (visible: boolean) => dispatch(contextActions.setGaragePopoverVisible(visible))

    const bircodeDataList = useSelector(BIRCodeDataListSelector)
    const value = useSelector(garagePopoverValueSelector)
    const setValue = (value: string) => dispatch(contextActions.setGaragePopoverValue(value))

    useEffect(() => {
        if (!visible) setValue("")
    }, [visible])

    const autoCompleteProps: AutoCompleteProps = {
        defaultActiveFirstOption: true,
        value,
        style: { "width": "450px" },
        placeholder: t(`Countries.garagePlaceholder`),
        filterOption: (inputValue, option) => {
            if (option && option.value)
                return option!.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            return false
        },
        options: bircodeDataList && birCodeDataListToOptionList(bircodeDataList),
        onSelect: (value: string) => {
            if (value) {
                // Set birCode data
                dispatch(contextActions.setMyBirCodeData(JSON.parse(value)));

                // Reinitialize popup value
                setValue("");

                // Hide popup
                setVisible(false);

                // Get back to home page
                location.pathname !== "/home" && navigate("/home")
            }
            return
        },
        onChange: (value) => setValue(value)
    }

    const showBirCodeDataSelect = isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION) && bircodeDataList;

    if (!showBirCodeDataSelect) return <></>

    return <SelectWrapper>
        <SelectLabel>{t(`Countries.chooseAGarage`)}</SelectLabel>
        <AutoComplete ref={(input) => input && input.focus()} {...autoCompleteProps} />
    </SelectWrapper>

}