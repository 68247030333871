import { Col, Divider, Form, Row, TimePicker } from "antd"
import { FC } from "react"
import { useTranslation } from "react-i18next"

export const SellerFormOpeningTime: FC = () => {
    const { t } = useTranslation()

    return <>
        <Divider orientation="left">{t("Account.Details.OpeningTime")}</Divider>
        <Row gutter={16}>
            <Col span={4}> <span>{t("Account.Details.HoursWeek")} :</span> </Col>
            <Col span={4}>
                <Form.Item name={['dmsR1OpeningHours', 0, 'startTime']}>
                    <TimePicker
                        style={{ width: "100%" }}
                        placeholder={t("Account.Details.Open")}
                        format='HH:mm:ss'
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item name={['dmsR1OpeningHours', 0, 'endTime']}>
                    <TimePicker
                        style={{ width: "100%" }}
                        placeholder={t("Account.Details.Close")}
                        format='HH:mm:ss' />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={4}><span>{t("Account.Details.HoursSaturday")} :</span></Col>
            <Col span={4}>
                <Form.Item name={['dmsR1OpeningHours', 1, 'startTime']}>
                    <TimePicker
                        style={{ width: "100%" }}
                        placeholder={t("Account.Details.Open")}
                        format='HH:mm:ss' />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item name={['dmsR1OpeningHours', 1, 'endTime']}>
                    <TimePicker
                        style={{ width: "100%" }}
                        placeholder={t("Account.Details.Close")}
                        format='HH:mm:ss' />
                </Form.Item>
            </Col>

        </Row>
        <Row gutter={16}>
            <Col span={4}><span>{t("Account.Details.HoursSunday")} :</span></Col>
            <Col span={4}>
                <Form.Item name={['dmsR1OpeningHours', 2, 'startTime']}>
                    <TimePicker
                        style={{ width: "100%" }}
                        placeholder={t("Account.Details.Open")}
                        format='HH:mm:ss' />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item name={['dmsR1OpeningHours', 2, 'endTime']}>
                    <TimePicker
                        style={{ width: "100%" }}
                        placeholder={t("Account.Details.Close")}
                        format='HH:mm:ss' />
                </Form.Item>
            </Col>
        </Row>
    </>
}