import React, { FC } from 'react';
import logoRP4 from '../../../assets/img/logo-FR-website.png';
import logoRenaultGroup from '../../../assets/img/Logo_GroupRenault.svg';
import { SecondaryHeaderWrapper, RP4Logo, RenaultGroup } from '../Styles';

/**
 * Secondary Header Component
 */
export const SecondaryHeader: FC = () => {

  return (
    <SecondaryHeaderWrapper>
        <RP4Logo src={logoRP4} />
        <RenaultGroup src={logoRenaultGroup} />
    </SecondaryHeaderWrapper>
  );
};
