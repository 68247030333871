import Form, { FormProps } from "antd/lib/form/Form"
import { FC } from "react"

export const FormWrapper: FC<FormProps<any> & {
    children?: React.ReactNode;
}> = (props) => {
    return <Form {...props}
        style={{
            backgroundColor: "#ffffff",
            width: "100%",
            padding: "10px",
            margin: "5px 10px",
        }}
    >
        {props.children}
    </Form>
}