import { FC, useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageContainer } from 'shared/GlobalStyles';
import { useStockExchangeColumns } from "../hooks/useStockExchangeColumns";
import { ColumnsType } from "antd/lib/table";
import { stockExchangePayloadSelector, stockExchangeColumnsSelector, stockExchangeDataSelector, stockExchangeLoadingSelector } from "../stores/selectors"
import { StockExchangeRequestPayload, StockExchange } from '../stores/types'
import { defaultStockExchangePayload } from "../stores/reducer"
import { StockExchangeTable } from "../components/StockExchangeTable";
import { actionCreators } from "../stores/actions"
import { useConfigTranslate } from "shared/hooks/useConfigTranslate";
import { StockExchangeForm } from "../components/StockExchangeForm";

const StockExchangeTracking: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const defaultColumns = useStockExchangeColumns()

    const payloadSelector = useSelector(stockExchangePayloadSelector)
    const columnsSelector = useSelector(stockExchangeColumnsSelector)
    const stockExchangeData = useSelector(stockExchangeDataSelector);
    const stockExchangeLoading = useSelector(stockExchangeLoadingSelector)

    const columns = columnsSelector ? columnsSelector : defaultColumns;

    const setPayload = (payload: StockExchangeRequestPayload) => dispatch(actionCreators.loadStockExchange(payload))
    const setColumns = (columns: ColumnsType<StockExchange>) => dispatch(actionCreators.setStockExchangeColumns(columns))
    const [key, setKey] = useState(0);

    const locale = useConfigTranslate();
    useEffect(() => {
        handleReinitFilters()
    }, [locale])


    const handleReinitFilters = () => {
        reinitTab()
        setKey(key + 1)
    }

    const reinitTab = () => {
        setColumns(defaultColumns)

        if (key > 2) setPayload(defaultStockExchangePayload)
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t("StockExchange.Navigation.Tabs"),
            children: <>
                <StockExchangeForm payload={payloadSelector} setPayload={setPayload} />
                {(stockExchangeData || stockExchangeLoading) && <StockExchangeTable
                    columns={columns}
                    payload={payloadSelector}
                    setColumns={setColumns}
                    setPayload={setPayload}
                />}
            </>
        }
    ]

    return <PageContainer>
        <Tabs items={items} />
    </PageContainer>
}

export default StockExchangeTracking