import { BirCode } from "screens/login/stores/types"

export interface Provider {
    repository: string
    accountId: string
    sia: string
}

export interface RoleState {
    allBIRCodeDataList?: BircodeData[]
    myBirCodeData?: BircodeData
    roleData: RoleData
    garagePopover: PopoverData
    orderPopover: OrderPopover
    rpartsStore: boolean
}

export interface PopoverData {
    isVisible: boolean
    value: string
}

export interface OrderPopover extends PopoverData {
    msg: string
}

export interface RoleData {
    myRolePrivilege: RolePrivilege
    myRoleList: RoleList
}

export interface BircodeData {
    bircode: BirCode
    businessName?: string
    city?: string
    databaseType?: DatabaseType
    countryCode?: string
}

export enum DatabaseType {
    ORACLE = "ORACLE",
    POSTGRE = "POSTGRE"
}

export type RoleList = Array<string>

/**
 * Define which privilege the user has.
 */
export enum RolePrivilege {
    /** Access denied. */
    NONE = 0,

    /** Access limited to his own seller : only 1 bircode. */
    R1 = 1,

    /** Access to all sellers from his country, but limited to account part : can choose any bircode in his country delimitation. */
    COUNTRY_SUBSCRIPTION = 2,

    /** Access to all sellers from his country : can choose any bircode in his country delimitation. */
    COUNTRY_ADMIN = 3,

    /** Access to all sellers : can choose any bircode. */
    CENTRAL_ADMIN = 4,
}

export interface RoleMapping {
    privilege: RolePrivilege
    roles: Array<string>
}

export const RoleRepositories: Array<string> = [
    "role-1po-irn72795",
    "role-rps-irn6943",
    "role-rnet-rps-irn6943",
    "role-rnet-1po-irn72795"
]

/**
 * R1 => all bircodes
 * COUNTRY_SUBSCRIPTION => bircode by country
 * COUNTRY_ADMIN => access only on account, on a single country
 * CENTRAL_ADMIN => one bircode
 * @returns countries
 */
export const roleMapping: Array<RoleMapping> = [
    {
        privilege: RolePrivilege.R1,
        roles: [
            "1po-r1",
            "IRN-72795:commandes",
            "IRN-6943:commandes",
            "IRN-6943:adhesion",
            "IRN-6943:catalogue"
        ]
    },
    {
        privilege: RolePrivilege.COUNTRY_SUBSCRIPTION,
        roles: [
            "1po-country-subscription"
        ]
    },
    {
        privilege: RolePrivilege.COUNTRY_ADMIN,
        roles: [
            "1po-country-admin"
        ]
    },
    {
        privilege: RolePrivilege.CENTRAL_ADMIN,
        roles: [
            "1po-central-admin",
            "IRN-6943:formateur-substitution",
        ]
    },
]