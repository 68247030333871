import { createSelector } from 'reselect';
import { RootState } from 'shared/stores'

export const ordersStateSelector = (state: RootState) => state.rp4.postgre.orders

export const ordersSelectors = {
    getOrders: createSelector(ordersStateSelector, state => state.getOrders),
    getOrder: createSelector(ordersStateSelector, state => state.getOrder),
    getOrderByRef: createSelector(ordersStateSelector, state => state.getOrderByRef),
    getOrderEvents: createSelector(ordersStateSelector, state => state.getOrderEvents),
    getOrderItems: createSelector(ordersStateSelector, state => state.getOrderItems),
    updateOrderListStatus: createSelector(ordersStateSelector, state => state.updateOrderListStatus),
    updateOrderItemsListWantedQuantity: createSelector(ordersStateSelector, state => state.updateOrderItemsListWantedQuantity),

    ordersColumns: createSelector(ordersStateSelector, state => state.ordersColumns),
    ordersLastVisitedTab: createSelector(ordersStateSelector, state => state.ordersLastVisitedTab),
    ordersSelectedRows: createSelector(ordersStateSelector, state => state.ordersSelectedRows),
    ordersFromSuperOrder: createSelector(ordersStateSelector, state => state.ordersFromSuperOrder)
}