import * as Effects from 'redux-saga/effects'

import exadisSaga from "screens/exadis/stores/sagas"
import financialMonitoringSaga from "screens/financialMonitoring/stores/sagas"
import loginSaga from 'screens/login/stores/sagas'
import stockExchangeSaga from "screens/stockExchange/stores/sagas"

import accountOracleSaga from 'screens/accounts_oracle/stores/sagas'
import errorLogsOracleSaga from 'screens/errorLogs_oracle/stores/sagas'
import orderOracleSaga from 'screens/orders_oracle/stores/sagas'

import accountPostgreSaga from 'screens/accounts_postgre/stores/sagas'
import errorLogsPostgreSaga from 'screens/errorLogs_postgre/stores/sagas'
import orderPostgreSaga from 'screens/orders_postgre/stores/sagas'

import contextSaga from "screens/context/stores/sagas"

const { all, call } = Effects

export default function* rootSaga() {
    yield all([
        call(loginSaga),
        call(stockExchangeSaga),
        call(financialMonitoringSaga),
        call(orderOracleSaga),
        call(accountOracleSaga),
        call(errorLogsOracleSaga),
        call(orderPostgreSaga),
        call(accountPostgreSaga),
        call(errorLogsPostgreSaga),
        call(exadisSaga),
        call(contextSaga)
    ])
}