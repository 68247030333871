import api, { unwrapAxiosResult } from 'shared/utils/api';
import { API_URLs } from 'shared/utils/constants';
import { getArgs } from 'shared/utils/utils';
import { FetchApiOrders } from './types';

export const ORDERS_POSTGRE_FETCHS: FetchApiOrders = {
    getOrders: payload => unwrapAxiosResult(
        api.get(`${API_URLs.POSTGRE.ORDERS}?${getArgs(payload)}`)
    ),
    getOrder: payload => unwrapAxiosResult(
        api.get(`${API_URLs.POSTGRE.ORDERS}?sellerCode=${payload.sellerCode}&orderId=${payload.orderId}`)
    ),
    getOrderByRef: payload => unwrapAxiosResult(
        api.get(`${API_URLs.POSTGRE.ORDERS}?${payload.payload.sellerCode ? 'sellerCode=' + payload.payload.sellerCode + '&' : ''}orderRef=${payload.payload.orderRef}`)
    ),
    getOrderEvents: orderId => unwrapAxiosResult(
        api.get(`${API_URLs.POSTGRE.ORDERS}/${orderId}/order-events`)
    ),
    getOrderItems: orderId => unwrapAxiosResult(
        api.get(`${API_URLs.POSTGRE.ORDERS}/${orderId}/order-items`)
    ),
    updateOrderList: payload => unwrapAxiosResult(
        api.post(`${API_URLs.POSTGRE.ORDERS}?${getArgs(payload)}`)
    ),
    updateOrderItemsListWantedQuantities: payload => unwrapAxiosResult(
        api.put(`${API_URLs.POSTGRE.ORDERS}/${payload.orderId}/order-items`, payload.records)
    )
}