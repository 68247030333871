import { UserAddOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useResolvedPath } from 'react-router-dom';
import { SiderMenu } from 'shared/components/SiderMenu/SiderMenu';

export const ErrorLogsNavigation: FC = () => {
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const toggleCollapsed = () => setShowMenu(!showMenu);

  return <SiderMenu
    items={[{
      key: '/tracking',
      label: <NavLink to={`${url}/tracking`}>{t("Generic.Menu.ErrorLogs")}</NavLink>,
      icon: <UserAddOutlined />
    }]}
    selectedKeys={[]}
    showMenu={showMenu}
    toggleCollapsed={toggleCollapsed}
  />
};
