import { FC } from 'react';
import { Navigate } from 'react-router';
import { SimplePageContainer } from '../../Styles';

export const NotFound: FC = () => {
  return (
    <SimplePageContainer>
      <Navigate to="/home"></Navigate>
      <h1>Hello React</h1>
      <h2>I'm NotFound scene</h2>
    </SimplePageContainer>
  );
}
