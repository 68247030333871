import { ColumnType } from "antd/lib/table";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { StyledButton } from "shared/components/Styles";
import { getSelectedCountry } from "shared/utils/utils";
import { useItemsColumnsPDF } from "../hooks/useItemsColumnsPDF";
import { useItemsTotalColumns } from "../hooks/useItemsTotalColumns";
import { useOrderDetailsInfo } from "../hooks/useOrderDetailsInfo";
import { ordersSelectors } from "../stores/selectors";
import { Order, OrderItems } from "../stores/types";

export const OrderDetailsPDF: FC = () => {
    const { t } = useTranslation()
    const { data: items } = useSelector(ordersSelectors.getOrderItems)
    const order: any = useSelector(ordersSelectors.getOrder).data?.content[0]

    const pricesColumns = useItemsTotalColumns();
    const itemsColumns = useItemsColumnsPDF();

    const { orderDetailsMainInfos, orderDetailsDeliveryInfos } = useOrderDetailsInfo()

    const handleExportToPDF = () => {
        let pricesHead: string[][] = [[]]
        const pricesBody: any[][] = [[]]

        let itemsHead: string[][] = [[]]
        const itemsBody: any[][] = []
        items?.forEach(x => itemsBody.push([]));

        pricesColumns.forEach((col: ColumnType<Order>) => {
            pricesHead[0] = [
                ...pricesHead[0],
                col.title as string
            ]

            if (order && col.key && col.dataIndex) {
                const value: any = order[col.dataIndex as string]
                pricesBody[0].push(value + " " + getSelectedCountry()?.currencycode)
            }
        });

        itemsColumns.forEach((col: ColumnType<OrderItems>) => {
            itemsHead[0] = [
                ...itemsHead[0],
                col.title as string
            ]
            items?.forEach((item: any, index) => {
                if (col.key && col.dataIndex) {
                    const value: any = item[col.dataIndex as string]
                    if (col.render) itemsBody[index].push(col.render(value, item, index))
                    else itemsBody[index].push(value)
                }
            })
        })

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        const centeredText = function (text: string) {
            var textWidth = doc.getStringUnitWidth(text) * doc.getFontSize() / doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
            doc.text(text, textOffset, y);
        }

        const title = function (text: string) {
            doc.setFontSize(15).setFont(doc.getFont().fontName, 'normal');
            centeredText(text);
        }

        const subTitle = function (text: string) {
            doc.setFontSize(12).setFont(doc.getFont().fontName, 'bold');
            doc.text(text, marginLeft, y += 15)
            doc.line(marginLeft, y += 2, marginLeft + doc.getTextWidth(text), y)
        }

        const text = function (text: string, x: number, y: number) {
            doc.setFontSize(9).setFont(doc.getFont().fontName, 'normal');
            doc.text(text, x, y)
        }

        // Params
        const _title = t("Orders.Others.Details", { orderId: order?.orderRef })
        const exportName = `${order?.orderRef}.pdf`;
        const extractionDate = dayjs().format("YYYY-MM-DD HH:mm:ss [UTC] Z")
        const marginLeft = 25;
        let y = 30;

        // Title
        title(_title)

        // Extraction informations
        y += 25;
        text(t("Orders.PDF.ExtractedOn") + " : " + extractionDate, marginLeft, y)

        // Infos
        y += 25;
        subTitle(t("Orders.PDF.MainInformations") + " :")

        y += 10
        orderDetailsMainInfos.forEach(x => {
            text(`${x.label} : ${x.value ? x.value : "-"}`, marginLeft + 15, y += 14)
        })

        // Delivery
        y += 25;
        subTitle(t("Orders.PDF.DeliveryInformations") + " :")

        y += 10
        orderDetailsDeliveryInfos.forEach(x => {
            text(`${x.label} : ${x.value ? x.value : "-"}`, marginLeft + 15, y += 14)
        })

        // Prices
        y += 25;
        subTitle(t("Orders.PDF.Prices") + " :");

        y += 10;
        autoTable(doc, {
            startY: y, 
            head: pricesHead, 
            body: pricesBody,
            margin: { left: marginLeft, right: marginLeft }
        });

        // Items
        y += 60;
        subTitle(t("Orders.Others.Products") + " :");

        y += 10;
        autoTable(doc, { 
            startY: y, 
            head: itemsHead, 
            body: itemsBody,
            columnStyles: {
                0: { cellWidth: 65, overflow: 'visible' },
            },
            styles: {
                overflow: 'linebreak',
                cellWidth: 'auto'
            },
            margin: { left: marginLeft, right: marginLeft }
        });
        doc.save(exportName);
    }

    return <StyledButton disabled={!(items && order) ? true : false} onClick={handleExportToPDF} type="primary"> {t("Generic.Actions.ExportToPDF")} </StyledButton>
}