import { Form } from "antd";
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PageContainer } from 'shared/GlobalStyles';
import { ButtonBack } from 'shared/components/Buttons/ButtonBack';
import { CenteredSpin, DetailsRow, InfosRow } from 'shared/components/Styles';
import { getBirCode } from 'shared/utils/utils';
import { BuyersClientCodesTable } from "../components/BuyerClientCodesTable";
import { BuyerForm } from "../components/BuyerForm";
import { BuyerInfo } from "../components/BuyerInfo";
import {
  accountPostgreActions,
  accountsSelectors
} from '../stores';

/**
*  Details DMS Info page
*/
export const BuyerDetails: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: buyerTresorCode } = useParams<{ id: string }>();
  const birCode = getBirCode()!;

  const { loading, data } = useSelector(accountsSelectors.getBuyer);

  const [form] = Form.useForm()

  useEffect(() => {
    if (birCode && buyerTresorCode)
      dispatch(accountPostgreActions.getBuyer({
        sellerCode: birCode,
        buyerTresorCode
      }))
  }, [birCode, buyerTresorCode])

  useEffect(() => {
    if (data)
      form.resetFields()
  }, [data])

  return (
    <PageContainer>
      <ButtonBack linkTo={"/accounts-RDS/tracking"} />
      <InfosRow gutter={30}>
        <h4>{t("Account.Details.BuyerInfo")}</h4>
        {data && data.content && data.content[0] && !loading ? <BuyerInfo buyer={data.content[0]} form={form} /> : <CenteredSpin size="large" />}

        <h4>{t("Account.Details.BuyerSettings")}</h4>
        {data && data.content && data.content[0] && !loading ? (<DetailsRow><BuyerForm buyer={data.content[0]} form={form} /></DetailsRow>) : <CenteredSpin size="large" />}

        <h4>{t("Account.Details.ClientCodesInValidity")}</h4>
        {data && data.content && data.content[0] && !loading ? <BuyersClientCodesTable accountClientCodes={data.content[0].accountClientCodes} /> : <CenteredSpin size="large" />}
      </InfosRow>
    </PageContainer >
  );
};