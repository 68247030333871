import { Alert } from 'antd';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  top: 3.5em;
  right: .5em;
  width: 40%;
  position: fixed;
  z-index: 10000;
  min-height: 91.75px;
  align-items: center;
  
  &.ant-alert-error {
    background-color: #ffd6d0de;
    border: 1px solid #faa39b;
  }

  &.ant-alert-warning {
    background-color: #fffbe6de;
  }

  &.ant-alert-info {
    background-color: #e6f7ffde;
  }

  &.ant-alert-success {
    background-color: #edffddde;
  }

  .ant-alert-close-icon {
    height: 100%;
    align-self: flex-start;
  }
`;