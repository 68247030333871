import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useBIRCode } from "screens/login/hooks/useBIRCode";
import { getSelectedCountry } from "shared/utils/utils";
import { Country, myCountriesDataSelector } from "../stores";

export function useCountry(): Country | undefined {
    const countries = useSelector(myCountriesDataSelector)
    const [country, setCountry] = useState<Country | undefined>()
    const birCode = useBIRCode();

    useEffect(() => {
        setCountry(getSelectedCountry());
    }, [countries, birCode])

    return country;
}