import { useTranslation } from "react-i18next";

export function useDmsTypeList() {
    const { t } = useTranslation();

    const dmsTypeList = [
        'EVA_BE',
        'eCars21-RO',
        'FORMEL1',
        'MOTION_DATA',
        'EUROPLUS',
        'Locosoft',
        'CDK',
        'ASWIN',
        'ASK',
        'PINEWOOD',
        'ANNATA',
        'SIVECO',
        'VECTOR',
        'AUTOLINE-NL',
        '1C',
        'STIEGER_CH',
        'ICAR',
        'ELITE',
        'Quiter-PT',
        'AUTAC',
        'Quiter',
        'GITS',
        'STIEGER_AT',
        'DMS400',
        'AUTOLINE',
        'DRAKKAR',
        'WerWieSo',
        'INCADEA-NL',
        'POWER',
        'EVA',
        'B3_PT',
        'VECTOR-PL',
        'COMPLETIS-PL',
        'CARE_CH',
        'ICAR-NL',
        'ICAR-PT',
        'CARE',
        'DracaR+',
        'KHEOPS',
        'INCADEA',
        'AUTOSYS',
        'ROGOWIEC',
        'SIX',
        'eCars21',
        'B3',
        'ICAR_BE',
        'AS400',
        'IMPETUS',
        'eCars21-PL',
        'AUTOLINE-PT',
        'RALLYE',
    ]

    let result: { value: string, label: string }[] = [];

    dmsTypeList.forEach(dmsType => {
        result = [
            ...result,
            {
                value: dmsType,
                label: t(`Account.DmsType.${dmsType}`)
            }
        ]
    })

    return result
}