import {
    combineReducers,
    configureStore
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import rootSaga from './rootSaga'
import rootReducer from './rootReducer'

// Create SAGA Middleware
const sagaMiddleware = createSagaMiddleware()

const createRootReducer = combineReducers({
    rp4: rootReducer
})

// Configure Store
const store = configureStore({
    reducer: createRootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true
        }),
        sagaMiddleware,
        // thunkMiddleware,
        createLogger({ collapsed: true })
    ],
    devTools: process.env.NODE_ENV !== 'production'
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof createRootReducer>
export type AppDispatch = typeof store.dispatch

export default store
