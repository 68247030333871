import { Space } from "antd"
import { OrderTableMessage } from "../components/OrderTableMessage"

export function useRenderMessage() {
    return (messageToSeller: string, record: any) => {
        return <Space size="middle">
            {messageToSeller && <OrderTableMessage message={messageToSeller} order={record} />}
        </Space>
    }
}
