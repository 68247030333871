import { FC } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../Styles";
import { NavLink } from "react-router-dom";

interface ButtonBackProps {
    linkTo?: string;
    backTo?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const ButtonBack: FC<ButtonBackProps> = ({ linkTo: fallback, backTo: callback }) => {
    const { t } = useTranslation();
    const props: any = {};
    if (!!fallback) props['to'] = { pathname: fallback };
    if (!!callback) props['onClick'] = callback;

    return (
        <NavLink {...props}>
            <StyledButton icon={<ArrowLeftOutlined />} type="default">
                {t("Generic.Actions.Return")}
            </StyledButton>
        </NavLink>
    )
}