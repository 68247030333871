import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import frFR from 'antd/lib/locale/fr_FR';
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import deDE from "antd/lib/locale/de_DE";
import ptPT from "antd/lib/locale/pt_PT";
import plPL from "antd/lib/locale/pl_PL";
import nlNL from "antd/lib/locale/nl_NL";
import itIT from "antd/lib/locale/it_IT";
import roRO from "antd/lib/locale/ro_RO";
import ruRU from "antd/lib/locale/ru_RU";
import trTR from "antd/lib/locale/tr_TR";
import ukUK from "antd/lib/locale/uk_UA";
import dayjs from 'dayjs'

import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/de";
import "dayjs/locale/pt";
import "dayjs/locale/nl";
import "dayjs/locale/it";
import "dayjs/locale/pl";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import "dayjs/locale/tr";
import "dayjs/locale/uk";
import "dayjs/locale/de-at";

/**
 * Config Provider
 */
export const useConfigTranslate = () => {
    const [locale, setLocale] = useState<any>();
    const { i18n } = useTranslation();
    const updateAntdLang = useCallback(() => {
        const language: any = {
            "fr": frFR,
            "en": enUS,
            "es": esES,
            "de": deDE,
            "pt": ptPT,
            "nl": nlNL,
            "it": itIT,
            "pl": plPL,
            "ro": roRO,
            "ru": ruRU,
            "tr": trTR,
            "uk": ukUK,
            "de-at": deDE
        };
        setLocale(language[i18n.language] || language['fr']);
    }, [i18n.language]);

    useEffect(() => {
        updateAntdLang();
        dayjs.locale(i18n.language);
    }, [i18n.language, updateAntdLang])

    return locale
}
