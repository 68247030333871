import Spin from 'antd/lib/spin'
import React, { FC } from 'react'
import { StyledComponent } from 'styled-components'
import { CenteredSpin } from '../Styles'

interface LoaderProps {
    children: React.ReactNode | React.ReactNode[]
    loading: boolean
    Spin?: StyledComponent<typeof Spin, any, {}, never>
}

export const Loader: FC<LoaderProps> = ({ children, loading, Spin }) => {
    return <>
        {!loading && children}
        {loading && Spin && <Spin />}
        {loading && !Spin && <CenteredSpin />}
    </>
}
