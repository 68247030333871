import { createAction } from 'redux-actions';
import { ActionSuccess, ApiActionLaunch } from 'shared/utils/types';
import { FetchApiOrders, Order, SearchByRef, UpdateOrderActionEnum } from './types';

export enum Type {
  GET_ORDERS = "PG_GET_ORDERS",
  GET_ORDERS_SUCCESS = "PG_GET_ORDERS_SUCCESS",
  GET_ORDERS_ERROR = "PG_GET_ORDERS_ERROR",

  GET_ORDER = "PG_GET_ORDER",
  GET_ORDER_SUCCESS = "PG_GET_ORDER_SUCCESS",
  GET_ORDER_ERROR = "PG_GET_ORDER_ERROR",

  GET_ORDER_BY_REF = "PG_GET_ORDER_BY_REF",
  GET_ORDER_BY_REF_SUCCESS = "PG_GET_ORDER_BY_REF_SUCCESS",
  GET_ORDER_BY_REF_ERROR = "PG_GET_ORDER_BY_REF_ERROR",

  GET_ORDER_EVENTS = "PG_GET_ORDER_EVENTS",
  GET_ORDER_EVENTS_SUCCESS = "PG_GET_ORDER_EVENTS_SUCCESS",
  GET_ORDER_EVENTS_ERROR = "PG_GET_ORDER_EVENTS_ERROR",

  GET_ORDER_ITEMS = "PG_GET_ORDER_ITEMS",
  GET_ORDER_ITEMS_SUCCESS = "PG_GET_ORDER_ITEMS_SUCCESS",
  GET_ORDER_ITEMS_ERROR = "PG_GET_ORDER_ITEMS_ERROR",

  UPDATE_ORDER_LIST = "PG_UPDATE_ORDER_LIST",
  UPDATE_ORDER_LIST_SUCCESS = "PG_UPDATE_ORDER_LIST_SUCCESS",
  UPDATE_ORDER_LIST_ERROR = "PG_UPDATE_ORDER_LIST_ERROR",

  UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY = "PG_UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY",
  UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_SUCCESS = "PG_UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_SUCCESS",
  UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_ERROR = "PG_UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_ERROR",

  SET_ORDERS_SELECTED_ROWS = "SET_ORDERS_SELECTED_ROWS",
  SET_ORDERS_LAST_VISITED_TAB = "SET_ORDERS_LAST_VISITED_TAB",
  SET_ORDERS_COLUMNS = "SET_ORDERS_COLUMNS",
  SET_ORDERS_MASS_ACTION_STATUS = "SET_ORDERS_MASS_ACTION_STATUS",
  SET_ORDERS_FROM_SUPERORDER = "SET_ORDERS_FROM_SUPERORDER"
}

export const ordersActions = {
  getOrders: createAction<ApiActionLaunch<FetchApiOrders["getOrders"]>, ApiActionLaunch<FetchApiOrders["getOrders"]>>(Type.GET_ORDERS, payload => payload),
  getOrdersSuccess: createAction<ActionSuccess<FetchApiOrders["getOrders"]>, ActionSuccess<FetchApiOrders["getOrders"]>>(Type.GET_ORDERS_SUCCESS, payload => payload),
  getOrdersError: createAction(Type.GET_ORDERS_ERROR),

  getOrder: createAction<ApiActionLaunch<FetchApiOrders["getOrder"]>, ApiActionLaunch<FetchApiOrders["getOrder"]>>(Type.GET_ORDER, payload => payload),
  getOrderSuccess: createAction<ActionSuccess<FetchApiOrders["getOrder"]>, ActionSuccess<FetchApiOrders["getOrder"]>>(Type.GET_ORDER_SUCCESS, payload => payload),
  getOrderError: createAction(Type.GET_ORDER_ERROR),

  getOrderByRef: createAction<SearchByRef, SearchByRef>(Type.GET_ORDER_BY_REF, payload => payload),
  getOrderByRefSuccess: createAction<ActionSuccess<FetchApiOrders["getOrderByRef"]>, ActionSuccess<FetchApiOrders["getOrderByRef"]>>(Type.GET_ORDER_BY_REF_SUCCESS, payload => payload),
  getOrderByRefError: createAction(Type.GET_ORDER_BY_REF_ERROR),

  getOrderEvents: createAction<ApiActionLaunch<FetchApiOrders["getOrderEvents"]>, ApiActionLaunch<FetchApiOrders["getOrderEvents"]>>(Type.GET_ORDER_EVENTS, payload => payload),
  getOrderEventsSuccess: createAction<ActionSuccess<FetchApiOrders["getOrderEvents"]>, ActionSuccess<FetchApiOrders["getOrderEvents"]>>(Type.GET_ORDER_EVENTS_SUCCESS, payload => payload),
  getOrderEventsError: createAction(Type.GET_ORDER_EVENTS_ERROR),

  getOrderItems: createAction<ApiActionLaunch<FetchApiOrders["getOrderItems"]>, ApiActionLaunch<FetchApiOrders["getOrderItems"]>>(Type.GET_ORDER_ITEMS, payload => payload),
  getOrderItemsSuccess: createAction<ActionSuccess<FetchApiOrders["getOrderItems"]>, ActionSuccess<FetchApiOrders["getOrderItems"]>>(Type.GET_ORDER_ITEMS_SUCCESS, payload => payload),
  getOrderItemsError: createAction(Type.GET_ORDER_ITEMS_ERROR),

  updateOrderList: createAction<ApiActionLaunch<FetchApiOrders["updateOrderList"]>, ApiActionLaunch<FetchApiOrders["updateOrderList"]>>(Type.UPDATE_ORDER_LIST, payload => payload),
  updateOrderListSuccess: createAction<ActionSuccess<FetchApiOrders["updateOrderList"]>, ActionSuccess<FetchApiOrders["updateOrderList"]>>(Type.UPDATE_ORDER_LIST_SUCCESS, payload => payload),
  updateOrderListError: createAction(Type.UPDATE_ORDER_LIST_ERROR),

  updateOrderListWantedQuantity: createAction<ApiActionLaunch<FetchApiOrders["updateOrderItemsListWantedQuantities"]>, ApiActionLaunch<FetchApiOrders["updateOrderItemsListWantedQuantities"]>>(Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY, payload => payload),
  updateOrderListWantedQuantitySuccess: createAction<ActionSuccess<FetchApiOrders["updateOrderItemsListWantedQuantities"]>, ActionSuccess<FetchApiOrders["updateOrderItemsListWantedQuantities"]>>(Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_SUCCESS, payload => payload),
  updateOrderListWantedQuantityError: createAction(Type.UPDATE_ORDER_ITEMS_LIST_WANTED_QUANTITY_ERROR),

  setLastVisitedTabOrders: createAction<string, string>(Type.SET_ORDERS_LAST_VISITED_TAB, (payload: string) => payload),
  setSelectedOrders: createAction<Order[], Order[]>(Type.SET_ORDERS_SELECTED_ROWS, (payload: Order[]) => payload),
  setColumns: createAction<any, any>(Type.SET_ORDERS_COLUMNS, (payload: any) => payload),
  setOrdersMassActionStatus: createAction<UpdateOrderActionEnum, UpdateOrderActionEnum>(Type.SET_ORDERS_MASS_ACTION_STATUS, (payload: UpdateOrderActionEnum) => payload),
  setOrdersFromSuperOrder: createAction<any, any>(Type.SET_ORDERS_FROM_SUPERORDER, (payload: any) => payload)
}