import { Button, Tabs } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from 'react-router';
import { useCountry } from 'screens/accounts_oracle/hooks/useCountry';
import { PageContainer } from 'shared/GlobalStyles';
import { CenteredSpin } from 'shared/components/Styles';
import { SwitchOrderRpartsStore } from 'shared/components/SwitchOrderRpartsStore/SwitchOrderRpartsStore';
import { useConfigTranslate } from 'shared/hooks/useConfigTranslate';
import { Payload } from 'shared/utils/types';
import { getBirCode } from 'shared/utils/utils';
import { OrdersTable } from '../components/OrdersTable';
import { useOrderTable } from '../hooks/useOrderTable';
import {
    ordersActions,
    ordersSelectors
} from '../stores';

/**
 * Orders Tracking page - Used to display orders tracking
 */
export const OrdersTracking: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigation = useNavigation();

    const lastVisitedTabOrders = useSelector(ordersSelectors.ordersLastVisitedTab)
    const ordersColumns = useSelector(ordersSelectors.ordersColumns)
    const { payload: PageablePayload } = useSelector(ordersSelectors.getOrders)

    const country = useCountry();

    const {
        ordersWaitingForActionTable,
        ordersInProgressTable,
        ordersNeedReissueDMS,
        ordersAll,
        ordersFinishedTable
    } = useOrderTable();

    const orderTrackingData: any = {
        "1": {
            key: "1",
            tab: t("Orders.Navigation.OrdersWaitingForAction"),
            naturalKey: "TO_BE_VALIDATED",
            ...ordersWaitingForActionTable
        },
        "2": {
            key: "2",
            tab: t("Orders.Navigation.OrdersInProgress"),
            naturalKey: "IN_PROGRESS",
            ...ordersInProgressTable
        },
        "3": {
            key: "3",
            tab: t("Orders.Navigation.OrdersNeedReissueDMS"),
            naturalKey: "NEED_REISSUE",
            ...ordersNeedReissueDMS
        },
        "4": {
            key: "4",
            tab: t("Orders.Navigation.OrdersAll"),
            naturalKey: "ALL",
            ...ordersAll
        },
        "5": {
            key: "5",
            tab: t("Orders.Navigation.OrdersFinished"),
            naturalKey: "FINISHED",
            ...ordersFinishedTable
        }
    }

    const [columns, setColumns] = useState(lastVisitedTabOrders && ordersColumns ? ordersColumns : orderTrackingData["1"].columns);
    const [payload, setPayload] = useState<Payload>(lastVisitedTabOrders ? PageablePayload : orderTrackingData["1"].payload);
    const [curActiveKey, setCurActiveKey] = useState<string>(lastVisitedTabOrders ? lastVisitedTabOrders : "1");
    const [tableKey, setTableKey] = useState<number>(0);

    const defaultActiveKey = lastVisitedTabOrders ? lastVisitedTabOrders : "1"

    const reinitTab = (activeKey: string) => {
        setColumns(orderTrackingData[activeKey].columns);
        setPayload(orderTrackingData[activeKey].payload);
        dispatch(ordersActions.setSelectedOrders([]));
    }

    const handleReinitFilters = (activeKey: string) => {
        reinitTab(activeKey);
        setTableKey(tableKey + 1)
    }

    const onChange = (activeKey: string) => {
        reinitTab(activeKey)
        setCurActiveKey(activeKey)
    }

    useEffect(() => { dispatch(ordersActions.setColumns(columns)) }, [columns])
    useEffect(() => { dispatch(ordersActions.getOrders({ ...payload, sellerCode: getBirCode()! })) }, [payload])
    useEffect(() => { dispatch(ordersActions.setLastVisitedTabOrders(curActiveKey)) }, [curActiveKey])

    const refreshOrders = useCallback(() => {
        dispatch(ordersActions.getOrders({ ...payload, sellerCode: getBirCode()! }));
        dispatch(ordersActions.setColumns(columns));
        dispatch(ordersActions.setLastVisitedTabOrders(curActiveKey));
    }, [columns, curActiveKey, payload]);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshOrders();
        }, 60000);
        return () => clearInterval(interval);
    }, [refreshOrders]);

    const tabBarExtraContent = (
        <>
            <Button onClick={() => handleReinitFilters(curActiveKey)}>{t("Generic.Actions.ReinitialiseFilters")}</Button>
            <Button onClick={() => refreshOrders()}>{t("Generic.Actions.RefreshOrders")}</Button>
        </>
    );

    const locale = useConfigTranslate();
    useEffect(() => {
        if (!navigation?.location?.state)
            handleReinitFilters(curActiveKey)
    }, [locale])

    return (
        <PageContainer>
            <SwitchOrderRpartsStore />
            <Tabs
                tabBarExtraContent={tabBarExtraContent}
                destroyInactiveTabPane={true}
                defaultActiveKey={defaultActiveKey}
                onChange={onChange}
                items={Object.keys(orderTrackingData).map((key: string) => {
                    return {
                        key: orderTrackingData[key].key,
                        label: orderTrackingData[key].tab,
                        children: <>{country && <OrdersTable
                            setPayload={setPayload}
                            payload={{ ...payload }}
                            columns={[...columns]}
                            setColumns={setColumns}
                            key={tableKey}
                            naturalKey={orderTrackingData[key].naturalKey}
                        />}

                            {
                                !country && <CenteredSpin />
                            }
                        </>

                    }
                })}
            />
        </PageContainer>
    );
};