import { CheckCircleTwoTone } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import stringFilterDropdown from "shared/components/Filters/StringFilterDropdown";
import { StockExchangeLnk } from "../stores";

export function useStockExchangeLnkColumns(): ColumnsType<StockExchangeLnk> {
    const { t } = useTranslation();

    const COLUMNS: ColumnsType<StockExchangeLnk> = [
        {
            title: t("StockExchange.Columns.r1"),
            dataIndex: 'r1',
            key: 'r1',
            ...stringFilterDropdown('StockExchange.Columns.r1')
        },
        {
            title: t("StockExchange.Columns.countrycode"),
            dataIndex: 'countryCode',
            key: 'countrycode'
        },
        {
            title: t("Orders.Stock.Available"),
            // dataIndex: 'qty',
            key: 'qty',
            render: () => <CheckCircleTwoTone />
        },
        {
            title: t("StockExchange.Columns.stockDate"),
            dataIndex: 'stockDateTime',
            key: 'stockDate',
            render: (date: Date) => date ? dayjs(date).format('L LT') : null,
        },
        {
            title: t("StockExchange.Columns.commercialName"),
            dataIndex: 'commercialName',
            key: 'commercialName',
            render: (_, record) => record.stockExchangeContact?.commercialName
        },
        {
            title: t("StockExchange.Columns.address1"),
            dataIndex: 'address1',
            key: 'address1',
            render: (_, record) => record.stockExchangeContact?.address1
        },
        {
            title: t("StockExchange.Columns.city"),
            dataIndex: 'city',
            key: 'city',
            render: (_, record) => record.stockExchangeContact?.city
        },
        {
            title: t("StockExchange.Columns.zipCode"),
            dataIndex: 'zipCode',
            key: 'zipCode',
            render: (_, record) => record.stockExchangeContact?.postalCode
        },
        {
            title: t("StockExchange.Columns.phone"),
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => record.stockExchangeContact?.phone
        },
        {
            title: t("StockExchange.Columns.mail"),
            dataIndex: 'mail',
            key: 'mail',
            render: (_, record) => record.stockExchangeContact?.mail
        },
        {
            title: t("StockExchange.Columns.lastname"),
            dataIndex: 'lastname',
            key: 'lastname',
            render: (_, record) => record.stockExchangeContact?.lastname
        },
        {
            title: t("StockExchange.Columns.firstname"),
            dataIndex: 'firstname',
            key: 'firstname',
            render: (_, record) => record.stockExchangeContact?.firstname
        },
    ]

    return COLUMNS;
}