import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import stringFilterDropdown from 'shared/components/Filters/StringFilterDropdown';
import { useRowAsLink } from 'shared/hooks/useRowAsLink';
import { getClientCodePrincipal } from '../stores';
import { Buyer } from '../stores/types';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'shared/utils/utils';

export interface MyColumnType<RecordType> extends ColumnType<RecordType> {
    filterIndex?: string
}
export type MyColumnsType<RecordType = unknown> = (ColumnGroupType<RecordType> | MyColumnType<RecordType>)[];

export function useBuyersColumns(): MyColumnsType<Buyer> {
    const { t } = useTranslation()

    const DEFAULT_COLUMNS: MyColumnsType<Buyer> = [{
        filterIndex: "organizationName",
        title: t("Account.Columns.organisationName"),
        key: 'accountBuyer.organizationName',
        dataIndex: ["account", "organizationName"],
        ...stringFilterDropdown(t("Account.Columns.organisationName"), "organizationName"),
        sorter: true
    },
    {
        filterIndex: "tresorCode",
        title: t("Account.Columns.SellersTable.tresorcode"),
        key: 'accountBuyer.tresorCode',
        dataIndex: ["account", "tresorCode"],
        ...stringFilterDropdown(t('Account.Columns.tresorCode'), "tresorCode"),
        sorter: true
    },
    {
        filterIndex: "buyerBirCode",
        title: t("Account.Columns.bircode"),
        key: 'accountBuyer.birCode',
        dataIndex: ["account", "sellerCode"],
        ...stringFilterDropdown(t('Account.Columns.bircode'), "birCode"),
        sorter: true
    },
    {
        title: capitalizeFirstLetter(t("Account.Columns.active")),
        key: 'active',
        render: (record: Buyer) => { 
            return record.accountCommercialLinks.active ? 
                <CheckCircleTwoTone /> : 
                <CloseCircleTwoTone twoToneColor="#eb2f96" />;  
        },
        sorter: true
    },
    {
        title: t("Account.Columns.validitystartdate"),
        key: 'validityStartDate',
        render: (record: Buyer) => { return record.accountCommercialLinks.validityStartDate ? dayjs(record.accountCommercialLinks.validityStartDate).format('L') : null },
        sorter: true,
    },
    {
        title: t("Account.Columns.validityenddate"),
        key: 'validityEndDate',
        render: (record: Buyer) => { return record.accountCommercialLinks.validityEndDate ? dayjs(record.accountCommercialLinks.validityEndDate).format('L') : null },
        sorter: true,
    },
    {
        title: t("Account.Columns.clienttype"),
        dataIndex: ["accountCommercialLinks", "type"],
        key: 'type',
        sorter: true
    },
    {
        filterIndex: "clientCode",
        title: t("Account.Columns.clientcode"),
        key: 'clientCode',
        render: (record: Buyer) => getClientCodePrincipal(record.accountClientCodes).clientCode,
        ...stringFilterDropdown(t('Account.Columns.clientcode'), "clientCode"),
        sorter: true
    }
    ]

    return useRowAsLink<Buyer>(DEFAULT_COLUMNS, (record: Buyer) => `buyer/${record.account.tresorCode.toString()}`)
}