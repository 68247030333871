
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import { DatabaseType, myBIRCodeDataSelector, myRoleListSelector, myRolePrivilegeSelector, RolePrivilege } from 'screens/context/stores';
import { getBirCode } from 'shared/utils/utils';
import { FC } from 'react';

export const AuthRequired = () => {
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);

  if ((isAuthenticated && roles !== undefined)) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

interface CheckMyBirCodeDatabaseTypeProps {
  databaseType: DatabaseType
}

export const CheckMyBirCodeDatabaseType: FC<CheckMyBirCodeDatabaseTypeProps> = ({ databaseType }) => {
  const myBirCodeData = useSelector(myBIRCodeDataSelector)
  if (myBirCodeData?.databaseType === databaseType) {
    return <Outlet />
  } else {
    return <Navigate to="/home" />
  }
}

export const BirCodeRequired = () =>
  getBirCode() ?
    <Outlet /> : <Navigate to="/home" />

export const NotCountrySubscriptionRequired = () =>
  useSelector(myRolePrivilegeSelector) !== RolePrivilege.COUNTRY_SUBSCRIPTION ?
    <Outlet /> :
    <Navigate to="/home" />
