import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { TroncatedText } from "shared/components/TroncatedText/TroncatedText";
import { OrderEvents } from "../stores/types";

export function useEventsColumns(): ColumnsType<OrderEvents> {
    const { t } = useTranslation();

    const DEFAULT_COLUMNS: ColumnsType<OrderEvents> = [
        {
            title: t("Orders.Columns.eventType"),
            dataIndex: 'eventType',
            key: 'eventType',
        },
        {
            title: t("Orders.Columns.eventDate"),
            dataIndex: 'eventDateTime',
            key: 'eventDateTime',
            render: (date: Date) => dayjs(date).format('L LT'),
        },
        {
            title: t("Orders.Columns.eventComment"),
            dataIndex: 'eventComment',
            key: 'eventComment',
            render: (value: string) => <TroncatedText value={value} lengthToBreakOn={50} />
        }
    ]
    return DEFAULT_COLUMNS
}