import { createSelector } from 'reselect'
import { RootState } from 'shared/stores'

export const errorsStateSelector = (state: RootState) => state.rp4.oracle.errorLogs

export const errorLogsLoadingSelector = createSelector(errorsStateSelector, state => state.loading)
export const errorLogsPayloadSelector = createSelector(errorsStateSelector, state => state.errorLogsPayload)
export const errorLogsDataSelector = createSelector(errorsStateSelector, state => state.errorLogsData)
export const errorLogsColumnsSelector = createSelector(errorsStateSelector, state => state.errorLogsColumns)

export const toggleNavigation = createSelector(errorsStateSelector, state => state.showNavigation)
export const navSelectedKey = createSelector(errorsStateSelector, state => state.navigationSelectedKey)