import { Spin } from "antd";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useCountry } from "screens/accounts_oracle/hooks/useCountry";
import { orderByRefDataSelector } from "screens/orders_oracle/stores";
import { getSelectedCountry } from "shared/utils/utils";
import store from "shared/stores/store";

interface PriceRenderProps {
    value: string | number
}

export const PriceRender: FC<PriceRenderProps> = ({ value }) => {
    const orderByRefData = useSelector(orderByRefDataSelector)
    const country = useCountry();
    const isLoading = (!country && !orderByRefData) ? true : false

    let res = "";
    if (country) res = `${value} ${country.currencycode ? country.currencycode : ""}`
    else res = `${value} ${orderByRefData?.currencyCode ? orderByRefData?.currencyCode : ""}`
    return <>{isLoading ? <Spin /> : res}</>
}

export const priceRender = (value: string | number) => {
    const orderByRefData = store.getState().rp4.oracle.orders.orderByRef.data;
    const country = getSelectedCountry();
    let res = "";
    if (country) res = `${value} ${country.currencycode ? country.currencycode : ""}`
    else res = `${value} ${orderByRefData?.currencyCode ? orderByRefData?.currencyCode : ""}`
    return res
}