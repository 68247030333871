import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useResolvedPath } from 'react-router-dom';
import { actionCreators, navSelectedKey, toggleNavigation } from './stores';
import { OrderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SiderMenu } from 'shared/components/SiderMenu/SiderMenu';

/**
 * Navigation of module ErrorLogs
 */
export const ErrorLogsNavigation: FC = () => {
  const { t } = useTranslation();
  const url = useResolvedPath("").pathname;
  const showMenu = useSelector(toggleNavigation);
  const selectedKey = useSelector(navSelectedKey);
  const dispatch = useDispatch();
  const toggleCollapsed = () => {
    dispatch(actionCreators.toggleNavigationOpen());
  };

  const handleClick = (e: any) =>
    dispatch(actionCreators.navigationSelectedKey([e.key]));

  return <SiderMenu
    items={[{
      key: "/tracking",
      icon: <OrderedListOutlined />,
      label: <NavLink to={`${url}/tracking`}>{t("Generic.Menu.ErrorLogs")}</NavLink>
    }]}
    showMenu={showMenu}
    toggleCollapsed={toggleCollapsed}
    handleClick={handleClick}
    selectedKeys={selectedKey}
  />
};
