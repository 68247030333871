import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResolvedPath, NavLink } from 'react-router-dom';
import { actionCreators, navSelectedKey, toggleNavigation } from './stores';
import { OrderedListOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SiderMenu } from 'shared/components/SiderMenu/SiderMenu';

/**
 * Navigation of module Orders
 */
export const OrdersNavigation: FC = () => {
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation();

  const showMenu = useSelector(toggleNavigation);
  const selectedKey = useSelector(navSelectedKey);
  const dispatch = useDispatch();

  const toggleCollapsed = () => dispatch(actionCreators.toggleNavigationOpen())
  const handleClick = (e: any) => dispatch(actionCreators.navigationSelectedKey([e.key]));

  return <SiderMenu items={[{
    key: '/tracking',
    label: <NavLink to={`${url}/tracking`}>{t("Orders.Navigation.OrdersFollowing")}</NavLink>,
    icon: <OrderedListOutlined />
  }]}
    handleClick={handleClick}
    selectedKeys={selectedKey}
    showMenu={showMenu}
    toggleCollapsed={toggleCollapsed}
  />
};
