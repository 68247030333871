import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next"
import { OrderEvents } from "../stores/types";

export function useEventsColumns(): ColumnsType<OrderEvents> {
    const { t } = useTranslation();

    const DEFAULT_COLUMNS: ColumnsType<OrderEvents> = [
        {
            title: t("Orders.Columns.eventType"),
            dataIndex: 'eventType',
            key: 'eventType',
        },
        {
            title: t("Orders.Columns.eventDate"),
            dataIndex: 'eventDate',
            key: 'eventDate'
        }
    ]
    return DEFAULT_COLUMNS
}