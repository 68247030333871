import { UserAddOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useResolvedPath } from 'react-router-dom';
import { SiderMenu } from 'shared/components/SiderMenu/SiderMenu';
import { accountOracleActions, toggleNavigation } from './stores';

export const AccountsNavigation: FC = () => {
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation();
  const showMenu = useSelector(toggleNavigation);
  const dispatch = useDispatch();
  const toggleCollapsed = () => dispatch(accountOracleActions.toggleNavigationOpen());

  return <SiderMenu
    items={[{
      key: '/tracking',
      label: <NavLink to={`${url}/tracking`}>{t("Account.Navigation.R1LinkedAccount")}</NavLink>,
      icon: <UserAddOutlined />
    }]}
    selectedKeys={[]}
    showMenu={showMenu}
    toggleCollapsed={toggleCollapsed}
  />
};
