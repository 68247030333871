import { ColumnsType } from "antd/lib/table";
import { createAction } from "redux-actions";
import { ActionSuccess, ApiActionLaunch } from "shared/utils/types";
import { Buyer, FetchApiAccounts, UpdateBuyerAdhesionEnum } from "./types";

export enum Type {
    GET_BUYER = "GET_BUYER",
    GET_BUYERS = "GET_BUYERS",
    UPDATE_BUYERS_ADHESION = "UPDATE_BUYERS_ADHESION",
    UPDATE_BUYERS = "UPDATE_BUYERS",
    GET_COUNTRIES = "GET_COUNTRIES",
    GET_SELLER = "GET_SELLER",
    UPDATE_SELLERS = "UPDATE_SELLERS",

    GET_BUYER_SUCCESS = "GET_BUYER_SUCCESS",
    GET_BUYERS_SUCCESS = "GET_BUYERS_SUCCESS",
    UPDATE_BUYERS_ADHESION_SUCCESS = "UPDATE_BUYERS_ADHESION_SUCCESS",
    UPDATE_BUYERS_SUCCESS = "UPDATE_BUYERS_SUCCESS",
    GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS",
    GET_SELLER_SUCCESS = "GET_SELLER_SUCCESS",
    UPDATE_SELLERS_SUCCESS = "UPDATE_SELLERS_SUCCESS",

    GET_BUYER_ERROR = "GET_BUYER_ERROR",
    GET_BUYERS_ERROR = "GET_BUYERS_ERROR",
    UPDATE_BUYERS_ADHESION_ERROR = "UPDATE_BUYERS_ADHESION_ERROR",
    UPDATE_BUYERS_ERROR = "UPDATE_BUYERS_ERROR",
    GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR",
    GET_SELLER_ERROR = "GET_SELLER_ERROR",
    UPDATE_SELLERS_ERROR = "UPDATE_SELLERS_ERROR",

    SET_BUYER_COLUMNS = "SET_BUYER_COLUMNS",
    SET_BUYER_LAST_VISITED_TAB = "SET_BUYER_LAST_VISITED_TAB",
    SET_BUYER_SELECTED_ROWS = "SET_BUYER_SELECTED_ROWS",
    SET_BUYER_ACTION_TYPE = "SET_BUYER_ACTION_TYPE"
}

export const accountPostgreActions = {
    getBuyer: createAction<ApiActionLaunch<FetchApiAccounts["getBuyer"]>, ApiActionLaunch<FetchApiAccounts["getBuyer"]>>(Type.GET_BUYER, payload => payload),
    getBuyerSuccess: createAction<ActionSuccess<FetchApiAccounts["getBuyer"]>, ActionSuccess<FetchApiAccounts["getBuyer"]>>(Type.GET_BUYER_SUCCESS, payload => payload),
    getBuyerError: createAction(Type.GET_BUYER_ERROR),

    getBuyers: createAction<ApiActionLaunch<FetchApiAccounts["getBuyers"]>, ApiActionLaunch<FetchApiAccounts["getBuyers"]>>(Type.GET_BUYERS, payload => payload),
    getBuyersSuccess: createAction<ActionSuccess<FetchApiAccounts["getBuyers"]>, ActionSuccess<FetchApiAccounts["getBuyers"]>>(Type.GET_BUYERS_SUCCESS, payload => payload),
    getBuyersError: createAction(Type.GET_BUYERS_ERROR),

    updateBuyersAdhesion: createAction<ApiActionLaunch<FetchApiAccounts["updateBuyersAdhesion"]>, ApiActionLaunch<FetchApiAccounts["updateBuyersAdhesion"]>>(Type.UPDATE_BUYERS_ADHESION, payload => payload),
    updateBuyersAdhesionSuccess: createAction<ActionSuccess<FetchApiAccounts["updateBuyersAdhesion"]>, ActionSuccess<FetchApiAccounts["updateBuyersAdhesion"]>>(Type.UPDATE_BUYERS_ADHESION_SUCCESS, payload => payload),
    updateBuyersAdhesionError: createAction(Type.UPDATE_BUYERS_ADHESION_ERROR),

    updateBuyers: createAction<ApiActionLaunch<FetchApiAccounts["updateBuyers"]>, ApiActionLaunch<FetchApiAccounts["updateBuyers"]>>(Type.UPDATE_BUYERS, payload => payload),
    updateBuyersSuccess: createAction<ActionSuccess<FetchApiAccounts["updateBuyers"]>, ActionSuccess<FetchApiAccounts["updateBuyers"]>>(Type.UPDATE_BUYERS_SUCCESS, payload => payload),
    updateBuyersError: createAction(Type.UPDATE_BUYERS_ERROR),

    getCountries: createAction(Type.GET_COUNTRIES),
    getCountriesSuccess: createAction<ActionSuccess<FetchApiAccounts["getCountries"]>, ActionSuccess<FetchApiAccounts["getCountries"]>>(Type.GET_COUNTRIES_SUCCESS, payload => payload),
    getCountriesError: createAction(Type.GET_COUNTRIES_ERROR),

    getSeller: createAction<ApiActionLaunch<FetchApiAccounts["getSeller"]>, ApiActionLaunch<FetchApiAccounts["getSeller"]>>(Type.GET_SELLER, payload => payload),
    getSellerSuccess: createAction<ActionSuccess<FetchApiAccounts["getSeller"]>, ActionSuccess<FetchApiAccounts["getSeller"]>>(Type.GET_SELLER_SUCCESS, payload => payload),
    getSellerError: createAction(Type.GET_SELLER_ERROR),

    updateSellers: createAction<ApiActionLaunch<FetchApiAccounts["updateSellers"]>, ApiActionLaunch<FetchApiAccounts["updateSellers"]>>(Type.UPDATE_SELLERS, payload => payload),
    updateSellersSuccess: createAction<ActionSuccess<FetchApiAccounts["updateSellers"]>, ActionSuccess<FetchApiAccounts["updateSellers"]>>(Type.UPDATE_SELLERS_SUCCESS, payload => payload),
    updateSellersError: createAction(Type.UPDATE_SELLERS_ERROR),

    setBuyersColumns: createAction<ColumnsType<Buyer>, ColumnsType<Buyer>>(Type.SET_BUYER_COLUMNS, payload => payload),
    setBuyersLastVisitedTab: createAction<string, string>(Type.SET_BUYER_LAST_VISITED_TAB, payload => payload),
    setBuyersSelectedRows: createAction<Buyer[], Buyer[]>(Type.SET_BUYER_SELECTED_ROWS, payload => payload),
    setBuyersActionType: createAction<UpdateBuyerAdhesionEnum, UpdateBuyerAdhesionEnum>(Type.SET_BUYER_ACTION_TYPE, payload => payload),
}