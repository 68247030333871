import { Popover, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { percentRender } from 'shared/components/Render/PercentRender';
import { PriceRender } from 'shared/components/Render/PriceRender';
import { twoDigitRounded } from 'shared/components/Render/TwoDigitsRounder';
import { Rem, FinalAmount } from 'shared/components/Styles';
import { ID } from 'shared/utils/types';
import {
  actionCreators, mapOrderEvents, OrderEvents, orderEventsLoadingSelector, orderEventsSelector,
  OrderItem, orderItemsLoadingSelector, orderItemsSelector
} from '../stores';
import {
  OrderLink,
  ThingsContainer, ThingsHelperTable
} from '../Styles';
import { OrderStatusTag } from './OrderStatusTag';

/**
 * Component helps to display Order- Items & Events from the OrderTable
 */
interface OrderTableTooltipProps {
  orderId: ID;
  orderRef: string;
}

export const OrderTableTooltip: FC<OrderTableTooltipProps> = ({
  orderId,
  orderRef,
}) => {
  const { t } = useTranslation();
  const loadingItems = useSelector(orderItemsLoadingSelector);
  const loadingEvents = useSelector(orderEventsLoadingSelector);
  const orderItems = useSelector(orderItemsSelector);
  const orderEvents = useSelector(orderEventsSelector);
  const dispatch = useDispatch();

  const title = t("Orders.Others.Details", { orderId: orderRef });

  const content = () => {
    const itemsToDisplay = orderItems?.map(
      (item: OrderItem) => {
        return {
          ...item,
          creationDate: dayjs(item.creationDate).format('L LT'),
          lastUpdateDate: dayjs(item.lastUpdateDate).format('L LT'),
        };
      }
    );

    const eventsToDisplay = orderEvents ? mapOrderEvents(orderEvents) : undefined;

    return (
      <Space direction="vertical">
        <>
          {!loadingItems && itemsToDisplay?.length ? (
            <ThingsContainer>
              <h5>{t("Orders.Others.Products")}</h5>
              <ThingsHelperTable>
                <tr>
                  <th>{t("Orders.Columns.reference")}</th>
                  <th>{t("Orders.Columns.designation")}</th>
                  <th>{t("Orders.Columns.wantedQuantity")}</th>
                  <th>{t("Orders.Columns.unitPrice")}</th>
                  <th>{t("Orders.Columns.discountRate")}</th>
                  <th>{t("Orders.Columns.amount")}</th>
                  <th>{t("Orders.Columns.status")}</th>
                </tr>
                {itemsToDisplay.map((item: OrderItem) => {
                  return (
                    <tr>
                      <td>{item.reference}</td>
                      <td>{item.designation || '_'}</td>
                      <td>{item.wantedQuantity}</td>
                      <td>
                        <PriceRender value={twoDigitRounded(item.price / item.wantedQuantity)} />
                      </td>
                      <td>
                        <Rem>{percentRender(item.discountRate)}</Rem>
                      </td>
                      <td>
                        <FinalAmount className="finalAmount">
                          <PriceRender value={twoDigitRounded(item.price * (1 - item.discountRate / 100))} />
                        </FinalAmount>
                      </td>
                      <td>
                        <OrderStatusTag status={item.currentItemStatus} />
                      </td>
                    </tr>
                  );
                })}
              </ThingsHelperTable>
            </ThingsContainer>
          ) : <Spin />}
          {!loadingEvents && eventsToDisplay?.length ? (
            <ThingsContainer>
              <h5>{t("Orders.Others.Events")}</h5>
              <ThingsHelperTable>
                <tr>
                  <th>{t("Orders.Columns.eventDate")}</th>
                  <th>{t("Orders.Columns.eventType")}</th>
                </tr>
                {eventsToDisplay.map((event: OrderEvents) => {
                  const { eventDate, eventType } = event
                  if (eventType) {
                    return (
                      <tr>
                        <td>{eventDate}</td>
                        <td>{eventType}</td>
                      </tr>
                    );
                  }
                  return <></>
                })}
              </ThingsHelperTable>
            </ThingsContainer>
          ) : <Spin />}
        </>
      </Space>
    );
  };

  const handleVisibleChange = (visible: any) => {
    if (visible && orderId) {
      dispatch(actionCreators.getOrderItems(orderId));
      dispatch(actionCreators.getOrderEvents(orderId));
    }
  };

  return (
    <Popover
      placement="right"
      content={content}
      title={title}
      onOpenChange={handleVisibleChange}
    >
      <OrderLink>{orderRef}</OrderLink>
    </Popover>
  );
};
