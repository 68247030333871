import { Tooltip } from "antd";
import { FC } from "react";

interface TroncatedTextProps {
    lengthToBreakOn?: number
    value: string
}

export const TroncatedText: FC<TroncatedTextProps> = ({ value, lengthToBreakOn = 25 }) => {
    return value?.length > lengthToBreakOn ? <Tooltip placement="bottom" title={value}>
        {value.substring(0, lengthToBreakOn)} ...
    </Tooltip> : <span>{value}</span>
}