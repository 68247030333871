
import api, { unwrapAxiosResult } from 'shared/utils/api'
import { API_URLs } from 'shared/utils/constants'
import { IPage } from 'shared/utils/types'
import { getBirCode, getArgs } from 'shared/utils/utils'
import { ErrorLogsRequestPayload, ErrorLogs } from './types'

export const getErrorLogs = (payload: ErrorLogsRequestPayload) =>
  unwrapAxiosResult<IPage<ErrorLogs>>(api.get<IPage<ErrorLogs>>(
    `${API_URLs.ORACLE.ERROR_LOGS}/dms-files-integrations-status/search/by-seller-bir-code?r1GarageId=${getBirCode()}&${getArgs(payload)}`
  ))