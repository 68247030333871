import { List } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { financialMonitoringLinksLoadingSelector, financialMonitoringLinksSelector } from "../stores";

export const FinancialMonitoringLinksList: FC = () => {
    const { t } = useTranslation()

    const financialMonitoringLinks = useSelector(financialMonitoringLinksSelector);
    const financialMonitoringLinksLoading = useSelector(financialMonitoringLinksLoadingSelector);

    return <List style={{ width: "300px", paddingLeft: "20px", backgroundColor: "#fff" }}
        loading={financialMonitoringLinksLoading}
        itemLayout="horizontal"
        dataSource={financialMonitoringLinks}
        renderItem={item => (
            <List.Item
                actions={[<a href={"https://ressources.ram.aws.renault.com/" + item}>{t("Generic.Actions.Download")}</a>]}
            >
                {item.replace(".xlsx", "").split("_")[item.replace(".xlsx", "").split("_").length - 1]}
            </List.Item>
        )}
    />
}