import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { MenuProps } from 'antd';
import { FlagOutlined } from '@ant-design/icons';
import { StyledMenu } from '../Styles';

export const TranslateMenu: FC = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
        key: string,
        iconCountryCode: string,
        label: string,
        onClickLanguage: string
    ): MenuItem {
        return {
            key,
            label: <span><ReactCountryFlag
                countryCode={iconCountryCode}
                cdnSuffix="svg"
                title={label}
                style={{ marginRight: '10px' }}
                svg
            /> {label}</span>,
            onClick: () => changeLanguage(onClickLanguage),
            style: { margin: '0px', minWidth: "100%", backgroundColor: i18n.language === onClickLanguage ? '#F1F1F1' : 'inherit' }
        } as MenuItem;
    }

    const items: MenuProps['items'] = [
        {
            key: "translateMenu",
            icon: <FlagOutlined />,
            children: [
                getItem("/french", "FR", t("Generic.Languages.French"), 'fr'),
                getItem("/dutch", "NL", t("Generic.Languages.Dutch"), 'nl'),
                getItem('/english', 'GB', t("Generic.Languages.English"), 'en'),
                getItem('/german', 'DE', t("Generic.Languages.German"), 'de'),
                getItem('/de-at', 'AT', t("Generic.Languages.GermanAT"), 'de-at'),
                getItem('/italian', 'IT', t("Generic.Languages.Italian"), 'it'),
                getItem('/polish', 'PL', t("Generic.Languages.Polish"), 'pl'),
                getItem('/portuguese', 'PT', t("Generic.Languages.Portuguese"), 'pt'),
                getItem('/romanian', 'RO', t("Generic.Languages.Romanian"), 'ro'),
                getItem('/russian', 'RU', t("Generic.Languages.Russian"), 'ru'),
                getItem('/spanish', 'ES', t("Generic.Languages.Spanish"), 'es'),
                getItem('/turkish', 'TR', t("Generic.Languages.Turkish"), 'tr'),
                getItem('/ukrainian', 'UA', t("Generic.Languages.Ukrainian"), 'uk'),
            ]
        }
    ]

    return <StyledMenu mode="horizontal" items={items} />

}