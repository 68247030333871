import { ColumnsType } from 'antd/lib/table'
import { Action, handleActions } from 'redux-actions'
import { IPage } from 'shared/utils/types'
import { Type } from './actions'
import { StockExchange, StockExchangeLnk, StockExchangeLnkRequestPayload, StockExchangeRequestPayload, StockExchangeState } from './types'

export const defaultStockExchangePayload = {
    page: 0,
    size: 20,
}

export const defaultStockExchangeLnkPayload = {
    page: 0,
    size: 20,
}

const INITIAL_STATE: StockExchangeState = {
    stockExchangeLoading: false,
    stockExchangePayload: defaultStockExchangePayload,
    stockExchangeData: undefined,
    stockExchangeColumns: undefined,
    stockExchangeLnkLoading: false,
    stockExchangeLnkPayload: defaultStockExchangeLnkPayload,
    stockExchangeLnkData: undefined,
    stockExchangeLnkColumns: undefined,
    navigationSelectedKey: [''],
    showNavigation: false,
}

export default handleActions<StockExchangeState, any>({
    // LOAD STOCK EXCHANGE
    [Type.LOAD_STOCK_EXCHANGE]: (state: StockExchangeState, action: Action<StockExchangeRequestPayload>) => ({
        ...state,
        stockExchangeLoading: true,
        stockExchangePayload: action.payload,
    }),
    [Type.LOAD_STOCK_EXCHANGE_SUCCESS]: (state: StockExchangeState, action: Action<IPage<StockExchange>>) => ({
        ...state,
        stockExchangeLoading: false,
        stockExchangeData: action.payload
    }),
    [Type.LOAD_STOCK_EXCHANGE_ERROR]: (state: StockExchangeState) => ({
        ...state,
        stockExchangeLoading: false,
        stockExchangeData: undefined
    }),
    [Type.SET_STOCK_EXCHANGE_COLUMNS]: (state: StockExchangeState, action: Action<ColumnsType<StockExchange>>) => ({
        ...state,
        stockExchangeColumns: action.payload
    }),

    // LOAD STOCK EXCHANGE LNK
    [Type.LOAD_STOCK_EXCHANGE_LNK]: (state: StockExchangeState, action: Action<StockExchangeLnkRequestPayload>) => ({
        ...state,
        stockExchangeLnkLoading: true,
        stockExchangeLnkPayload: action.payload,
    }),
    [Type.LOAD_STOCK_EXCHANGE_LNK_SUCCESS]: (state: StockExchangeState, action: Action<IPage<StockExchangeLnk>>) => ({
        ...state,
        stockExchangeLnkLoading: false,
        stockExchangeLnkData: action.payload
    }),
    [Type.LOAD_STOCK_EXCHANGE_LNK_ERROR]: (state: StockExchangeState) => ({
        ...state,
        stockExchangeLnkLoading: false,
        stockExchangeLnkData: undefined
    }),
    [Type.SET_STOCK_EXCHANGE_LNK_COLUMNS]: (state: StockExchangeState, action: Action<ColumnsType<StockExchangeLnk>>) => ({
        ...state,
        stockExchangeLnkColumns: action.payload
    }),

    // User PREFs
    [Type.TOGGLE_NAVIGATION_MENU]: (state: StockExchangeState) => ({
        ...state,
        showNavigation: !state.showNavigation
    }),
    [Type.NAVIGATION_SELECTED_KEY]: (state: StockExchangeState, action: Action<['']>) => ({
        ...state,
        navigationSelectedKey: action.payload
    })
}, INITIAL_STATE)