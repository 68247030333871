import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { Dispatch, FC } from "react"
import { useSelector } from "react-redux"
import { handleTableChangeV2 } from "shared/utils/utils"
import { ErrorLogs, GetErrorLogsPayload, errorLogsSelectors } from "../stores"

interface ErrorLogsTableProps {
    columns?: ColumnsType<ErrorLogs>
    setColumns: Dispatch<ColumnsType<ErrorLogs>>
    payload?: GetErrorLogsPayload
    setPayload: Dispatch<GetErrorLogsPayload>
}

export const ErrorLogsTable: FC<ErrorLogsTableProps> = (
    { columns, setColumns, payload, setPayload }
) => {

    const errorLogs = useSelector(errorLogsSelectors.getErrorLogs)
    const errorLogsData = errorLogs.data;
    const errorLogsLoading = errorLogs.loading;

    const pagination: TablePaginationConfig = {
        showSizeChanger: true,
        pageSize: errorLogsData?.size,
        total: errorLogsData?.totalElements,
        current: errorLogsData?.number ? errorLogsData.number + 1 : undefined
    }

    return <Table
        size="middle"
        loading={errorLogsLoading}
        pagination={pagination}
        dataSource={errorLogsData?.content}
        columns={columns}
        onChange={handleTableChangeV2(payload, setPayload, columns, setColumns)}
    >
    </Table>

}