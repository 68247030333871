import Table, { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import { Dispatch, FC } from "react"
import { useSelector } from "react-redux"
import { handleTableChange } from "shared/utils/utils"
import { stockExchangeDataSelector, stockExchangeLoadingSelector } from "../stores"
import { StockExchange, StockExchangeRequestPayload } from "../stores/types"

interface StockExchangeTableProps {
    columns: ColumnsType<StockExchange>
    setColumns: Dispatch<ColumnsType<StockExchange>>
    payload: StockExchangeRequestPayload
    setPayload: Dispatch<StockExchangeRequestPayload>
}

export const StockExchangeTable: FC<StockExchangeTableProps> = ({ columns, setColumns, payload, setPayload }) => {

    const stockExchangeData = useSelector(stockExchangeDataSelector)
    const stockExchangeLoading = useSelector(stockExchangeLoadingSelector)

    const pagination: TablePaginationConfig = {
        showSizeChanger: true,
        pageSize: stockExchangeData?.size,
        total: stockExchangeData?.totalElements,
        current: stockExchangeData?.number ? stockExchangeData.number + 1 : undefined
    }

    return <Table
        rowKey={(record) => record.productReference + " - " + record.countryCode}
        size="middle"
        loading={stockExchangeLoading}
        pagination={pagination}
        dataSource={stockExchangeData?.content}
        columns={columns}
        onChange={handleTableChange(payload, setPayload, columns, setColumns)}
    >
    </Table>

}