import { Action } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { Type, contextActions } from "./actions";
import { BircodeData, DatabaseType, Provider } from "./types";

import { apiCallWrapper } from "shared/utils/api";
import { getRp4Subscriber } from "./utils";

function* setMyBirCodeData(action: Action<BircodeData>) {
    try {
        const birCodeData = action.payload;

        const rp4Subscriber: Provider = yield apiCallWrapper<Provider>(getRp4Subscriber, birCodeData.bircode);

        if (rp4Subscriber && rp4Subscriber.sia === "RP4") {
            birCodeData.databaseType = DatabaseType.POSTGRE;
        }
        else if (rp4Subscriber && rp4Subscriber.sia === "R2A") {
            birCodeData.databaseType = DatabaseType.ORACLE;
        }
        // TODO: que faire si rp4provider pas dispo ?

        localStorage.setItem('bircodeData', JSON.stringify(birCodeData));

        yield put(contextActions.setMyBirCodeDataSuccess(action.payload))

    } catch (error) {
        yield put(contextActions.setMyBirCodeDataError(error))
    }
}

export default function* () {
    yield takeLatest(Type.SET_MY_BIRCODE_DATA, setMyBirCodeData)
}