import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  RolePrivilege,
  contextActions,
  garagePopoverVisibleSelector,
  isRolePrivilegeGranted,
  myBIRCodeDataSelector,
  myRoleListSelector,
  myRolePrivilegeSelector
} from '../stores'

import { myCountriesLoadingSelector } from 'screens/accounts_oracle/stores'

import { Popover, PopoverProps } from 'antd'
import { Loader } from 'shared/components/Loader/Loader'
import { ContextSpinner, HeaderToggleButton } from '../Styles'
import { GarageSelect } from './GarageSelect'

export const GaragePopover: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loading = useSelector(myCountriesLoadingSelector)
  const selectedBircode = useSelector(myBIRCodeDataSelector)
  const roles = useSelector(myRoleListSelector)
  const rolePrivilege = useSelector(myRolePrivilegeSelector);
  const visible = useSelector(garagePopoverVisibleSelector)
  const setVisible = (visible: boolean) => dispatch(contextActions.setGaragePopoverVisible(visible))

  const popoverProps: PopoverProps = {
    destroyTooltipOnHide: true,
    open: visible,
    trigger: "click",
    placement: "top",
    onOpenChange: () => setVisible(!visible),
    content: <Loader Spin={ContextSpinner} loading={loading}> <GarageSelect /> </Loader>
  }

  if (roles === undefined || !isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION)) return <></>

  const res = () => {
    if (selectedBircode &&
      selectedBircode.bircode &&
      selectedBircode.city &&
      selectedBircode.businessName) {
      return `${selectedBircode.businessName} - ${selectedBircode.bircode}`
    } else if (rolePrivilege === RolePrivilege.R1) {
      return ""
    } else {
      return t("Countries.garage")
    }
  }

  return <Popover {...popoverProps}>
    <HeaderToggleButton>
      {res()}
    </HeaderToggleButton>
  </Popover>
};