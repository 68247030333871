import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountry } from 'screens/accounts_oracle/hooks/useCountry';
import { useUrgentCountriesList } from 'screens/accounts_oracle/hooks/useUrgentCountriesList';
import { PriceRender } from 'shared/components/Render/PriceRender';
import { OrderStatusTag } from '../components/OrderStatusTag';
import { OrderTableMessage } from '../components/OrderTableMessage';
import { OrderTableTooltip } from '../components/OrderTableTooltip';
import { Order } from "../stores";

export function useOrderColumns(): ColumnsType<Order> {
    const { t } = useTranslation();
    const urgentCountriesList = useUrgentCountriesList();

    const DEFAULT_COLUMNS: ColumnsType<Order> = [
        {
            title: t("Orders.Columns.orderRef"),
            dataIndex: 'orderRef',
            key: 'orderRef',
            render: (_, record) => {
                if (record && record.id && record.orderRef) {
                    return <OrderTableTooltip orderId={record.id} orderRef={record.orderRef} />
                } else {
                    return _
                }
            },
            sorter: true
        },
        {
            title: 'RPartStore',
            dataIndex: 'superOrderRef',
            key: 'superOrderRef'
        },
        {
            title: t("Orders.Columns.distributedBy"),
            dataIndex: 'distributedBy',
            key: 'distributedBy',
        },
        {
            title: t("Orders.Columns.buyerName"),
            dataIndex: 'buyerName',
            key: 'buyerName',
        },
        {
            title: t("Orders.Columns.takenIntoAccount"),
            dataIndex: 'takenIntoAccount',
            key: 'takenIntoAccount',
            render: (_, record: Order) => record.takenIntoAccount ?
                <CheckCircleTwoTone /> :
                <CloseCircleTwoTone twoToneColor="#eb2f96" />
        },
        {
            title: t("Orders.Columns.urgentFlag"),
            dataIndex: 'urgentFlag',
            key: 'urgentFlag',
            render: (_, record: Order) => record.urgentFlag ?
                <CheckCircleTwoTone /> :
                <CloseCircleTwoTone twoToneColor="#eb2f96" />
        },
        {
            title: t("Orders.Columns.creationDate"),
            dataIndex: 'creationDateTime',
            key: 'creationDate',
            render: (date: Date) => dayjs(date).format('L LT'),
            sorter: true,
            defaultSortOrder: 'descend'
        },
        {
            title: t("Orders.Columns.totalTaxFree"),
            dataIndex: 'totalTaxFree',
            key: 'totalTaxFree',
            render: (value: number) => <PriceRender value={value} />,
        },
        {
            title: t("Orders.Columns.status"),
            key: 'status',
            dataIndex: 'status',
            render: (status: number) => <OrderStatusTag status={status} />,
        },
        {
            title: t("Orders.Columns.message"),
            key: 'message',
            dataIndex: 'messageToSeller',
            render: (messageToSeller: string, record: any) =>
                <Space size="middle">
                    {messageToSeller && <OrderTableMessage message={messageToSeller} order={record} />}
                </Space>
        }
    ]

    const [columns, setColumns] = useState(DEFAULT_COLUMNS);

    const country = useCountry();

    useEffect(() => {
        const filteredColumns = (columns: any) => {
            if (!country || urgentCountriesList.includes(country.countrycode))
                return columns

            return (columns.filter((column: any) => column.dataIndex !== 'urgentFlag'))
        }

        setColumns(filteredColumns(DEFAULT_COLUMNS))

    }, [country])

    return columns
}