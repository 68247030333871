import { Modal, Card } from "antd";
import styled from "styled-components";

export const ActionsModal = styled(Modal)`
  .ant-modal-footer {
    background: #F0F0F5;
  }
`;

export const Presentation = styled(Card)`
  .ant-card-body {
    background: #F0F2F5;
  }
`;