import { Col, Divider, Form, Row, Select } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDmsTypeList } from "../hooks/useDmsTypeList";
import { useLinkTypeList } from "../hooks/useLinkTypeList";
import { useRegionalStockList } from "../hooks/useRegionalStockList";
import { capitalizeFirstLetter } from "shared/utils/utils";
import { CustomGreenRedSwitch } from "../Styles";

export const SellerFormDmsInfo: FC = () => {
    const { t } = useTranslation();
    const { Option } = Select;
    const regionalStockList = useRegionalStockList();
    const dmsTypeList = useDmsTypeList();
    const linkTypeList = useLinkTypeList();

    const gutter = 16;
    const titleSpan = 5;

    return <>
        <Divider orientation="left">{t("Account.Details.DmsInfo")}</Divider>
        <Row gutter={gutter}>
            <Col span={titleSpan}>
                <Form.Item
                    label={capitalizeFirstLetter(t("Account.Details.activeDms"))}
                    name={["dmsR1", "active"]}
                    valuePropName="checked"
                >
                    <CustomGreenRedSwitch />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}><span>{t("Account.Details.LogisticType")} :</span></Col>
            <Form.Item
                name={["dmsR1", "logisticType"]}
                rules={[{ required: true }]}
            >
                <Select>
                    <Option key={"WHOLESALER"} value={"WHOLESALER"}>
                        {t("Account.Details.WholesalerLogistic")}
                    </Option>
                    <Option key={"DIRECT"} value={"DIRECT"}>
                        {t("Account.Details.DirectLogistic")}
                    </Option>
                </Select>
            </Form.Item>
        </Row>
        <Row gutter={gutter}>
            <Col span={titleSpan}>
                <span>{t("Account.Details.RegionalStock")} :</span>
            </Col>
            <Col span={5}>
                <Form.Item
                    name={["dmsR1", "regionalStock"]}
                    rules={[{ required: true }]}>
                    <Select>{regionalStockList.map(regionalStock =>
                        <Option key={regionalStock.value} value={regionalStock.value}>{regionalStock.label}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col span={5}>
                <span>{t("Account.Details.DmsType")} :</span>
                <Form.Item name={["dmsR1", "dmsType"]}>
                    <Select>
                        {dmsTypeList.map(dmsType =>
                            <Option key={dmsType.value} value={dmsType.value}>{dmsType.label}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={5}>
                <span>{t("Account.Details.DmsFlux")} :</span>
                <Form.Item name={["dmsR1", "linkType"]}>
                    <Select>
                        {
                            linkTypeList.map(linkType =>
                                <Option key={linkType.value} value={linkType.value}>{linkType.label}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>

        </Row>
    </>
}