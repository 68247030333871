import { Action, handleActions } from 'redux-actions'
import { Type } from './actions'
import { FinancialMonitoringResponse, FinancialMonitoringState } from './types'

const INITIAL_STATE: FinancialMonitoringState = {
    financialMonitoringLinksLoading: false,
    financialMonitoringLinks: undefined,
    navigationSelectedKey: [''],
    showNavigation: false,
}

export default handleActions<FinancialMonitoringState, any>({
    // LOAD FINANCIAL MONITORING LINKS
    [Type.LOAD_FINANCIAL_MONITORING_LINKS]: (state: FinancialMonitoringState) => ({
        ...state,
        financialMonitoringLinksLoading: true
    }),
    [Type.LOAD_FINANCIAL_MONITORING_LINKS_SUCCESS]: (state: FinancialMonitoringState, action: Action<FinancialMonitoringResponse>) => ({
        ...state,
        financialMonitoringLinksLoading: false,
        financialMonitoringLinks: action.payload.files
    }),
    [Type.LOAD_FINANCIAL_MONITORING_LINKS_ERROR]: (state: FinancialMonitoringState) => ({
        ...state,
        financialMonitoringLinksLoading: false,
        financialMonitoringLinks: undefined
    }),

    // User PREFs
    [Type.TOGGLE_NAVIGATION_MENU]: (state: FinancialMonitoringState) => ({
        ...state,
        showNavigation: !state.showNavigation
    }),
    [Type.NAVIGATION_SELECTED_KEY]: (state: FinancialMonitoringState, action: Action<['']>) => ({
        ...state,
        navigationSelectedKey: action.payload
    })
}, INITIAL_STATE)