import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useRowAsLink } from 'shared/hooks/useRowAsLink';
import { GarageData } from '../stores/types';

export function useSellersColumns(): ColumnsType<GarageData> {
  const { t } = useTranslation()

  const DEFAULT_COLUMNS: ColumnsType<GarageData> = [
    {
      title: t("Account.Columns.commercialName"),
      dataIndex: 'commercialName',
      key: 'commercialName'
    },
    {
      title: t("Account.Columns.tresorCode"),
      dataIndex: 'tresorCode',
      key: 'tresorCode'
    },
    {
      title: t("Account.Columns.bircode"),
      dataIndex: 'birCode',
      key: 'birCode'
    },
    {
      title: t("Account.Columns.establishmentNumber"),
      dataIndex: 'establishmentNumber',
      key: 'establishmentNumber',
    },
  ]

  return useRowAsLink<GarageData>(DEFAULT_COLUMNS, (record: GarageData) => `seller`)
}