import { FC } from 'react';
import { Card, Col, Table } from 'antd';
import { useSelector } from 'react-redux';
import { AccountClientCode, accountsSelectors } from '../stores';
import dayjs from 'dayjs';
import { useBuyersClientCodesColumns } from '../hooks/useBuyersClientCodesColumns';

interface BuyersClientCodesTableProps {
  accountClientCodes: AccountClientCode[]
}

export const BuyersClientCodesTable: FC<BuyersClientCodesTableProps> = ({ accountClientCodes }) => {
  const { loading } = useSelector(accountsSelectors.getSeller);

  const columns = useBuyersClientCodesColumns();

  const dataSource = accountClientCodes.filter(item => {
    const now = dayjs();
    const validityStartDate = dayjs(item?.validityStartDate);
    const validityEndDate = dayjs(item?.validityEndDate);

    return ((now >= validityStartDate) && (now <= validityEndDate)) || (!item?.validityEndDate)
  });

  return (
    <>
      {dataSource && <Col span={24}>
        <Card bordered={false}>
          <Table
            rowKey="id"
            size="small"
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Card>
      </Col>}
    </>
  );
};

