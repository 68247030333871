import { FC } from "react"
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, Order, OrdersPayloadSelector } from '../stores';
import { useTranslation } from "react-i18next";

interface OrderTableMessageProps {
  message: string;
  order: Order;
}

export const OrderTableMessage: FC<OrderTableMessageProps> = ({ message, order }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const OrdersPayload = useSelector(OrdersPayloadSelector)

  const markAsRead = () => {
    if (order) {
      const updateOrder: Order = {
        ...order,
        r1MessageReadStatus: "Y",
      };

      dispatch(actionCreators.updateOrder(updateOrder));
      dispatch(actionCreators.loadOrders(OrdersPayload));
    }
  };

  const title = <>
    {t(`Orders.Others.Order`)} {order.orderRef}
  </>;

  return (
    <Popover placement="left" title={title} content={
      <>
        <p>{message} </p>
        <p>{order.r1MessageReadStatus !== "Y" &&
          <Button type="primary" onClick={markAsRead}>{t("Orders.Actions.MarkAsRead")}</Button>
        }</p>
      </>}
    >
      {order.r1MessageReadStatus === "Y" ? <InfoCircleOutlined /> : <InfoCircleTwoTone />}

    </Popover>
  );
};
