import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ordersActions, ordersSelectors } from '../stores';
import { Table } from 'antd';
import { TableWrapper } from 'shared/components/Styles';
import { ColumnsType } from 'antd/lib/table';
import { PageContainer } from 'shared/GlobalStyles';
import { useOrderColumns } from '../hooks/useOrderColumns';
import { useRenderMessage } from '../hooks/useRenderMessage';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * Super Order Tracking page - Used to view/select among orders from a super order
 */
export const SuperOrderTracking: FC = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const columns = useOrderColumns();
    const data = useSelector(ordersSelectors.ordersFromSuperOrder);
    const renderMessage = useRenderMessage();

    function useTableExporter<T>(columns: ColumnsType<T>) {
  
        columns = columns.map(x => x.key === "message" ? {
            ...x,
            render: renderMessage
        } : x);

        return columns;
    }

    return (
        <PageContainer>
            <TableWrapper>
            <Table
                rowKey="id"
                size="middle"
                dataSource={data}
                columns={useTableExporter(columns)}
                onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        dispatch(ordersActions.getOrderByRef({
                            payload: {
                                orderRef: record.orderRef
                            }, navigate
                        }))
                      }
                    };
                  }}
            />
            </TableWrapper>
        </PageContainer>
    );
}