import { createSelector } from 'reselect'
import { RootState } from 'shared/stores'

export const errorLogsStateSelector = (state: RootState) => state.rp4.postgre.errorLogs

export const errorLogsSelectors = {
    getErrorLogs: createSelector(errorLogsStateSelector, state => state.getErrorLogs),
    getErrorLogsDetails: createSelector(errorLogsStateSelector, state => state.getErrorLogsDetails),
    errorLogsColumns: createSelector(errorLogsStateSelector, state => state.errorLogsColumns),
    errorLogsDetailsColumns: createSelector(errorLogsStateSelector, state => state.errorLogsDetailsColumns)
}