import { Col, Row, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatabaseType, contextActions, myBIRCodeDataSelector, rpartsStoreSelector } from 'screens/context/stores';

export const SwitchOrderRpartsStore: FC = () => {
    const myBIRCodeData = useSelector(myBIRCodeDataSelector);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const rpartsStore = useSelector(rpartsStoreSelector);
    const navigate = useNavigate()

    const onChange = (checked: boolean) => {
        setLoading(true)
        dispatch(contextActions.setRpartsStore(checked))
    }

    useEffect(() => {
        setTimeout(() => {
            if (rpartsStore) {
                navigate("/orders-RDS/tracking")
            } else {
                navigate("/orders/tracking")
            }
            setLoading(false)
        }, 300)
    }, [rpartsStore])

    if (myBIRCodeData?.databaseType === DatabaseType.ORACLE) {
        return <></>
    }

    return <Row gutter={16}>
        <Col>Rparts</Col>
        <Col><Switch loading={loading} checked={rpartsStore} onChange={onChange}></Switch></Col>
        <Col>Rparts Store</Col>
    </Row>

}