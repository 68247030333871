import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertSelector } from './stores/selectors';
import { alertActions } from './stores/actions';
import { StyledAlert } from './Styles';
import { IAlertType } from './stores/types';
import { useTranslation } from 'react-i18next';

/**
 * RPAlert Rp4 alert component
 */
export const RPAlert: FC = () => {
  const { t } = useTranslation();
  const current = useSelector(alertSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (current && !current.displayed) {
      const timer = setTimeout(
        () => dispatch(alertActions.alertDisplay(true)),
        5000
      );
      return () => clearTimeout(timer);
    }
  }, [current, dispatch]);

  const messageBuilder = (alertMsg: IAlertType) => {
    const messageOut = alertMsg.code
      ? alertMsg.code
      : alertMsg.type
        ? t(`Generic.Status.${alertMsg.type.toUpperCase()}`) + ` : ${alertMsg.message}`
        : alertMsg.message;
    return messageOut;
  };

  return (
    <>
      {current && !current.displayed && (
        <StyledAlert
          {...current}
          message={messageBuilder(current)}
          showIcon
          closable
        />
      )}
    </>
  );
};
