import styled from 'styled-components';

export const FullScreenHeight = styled.div`
  height: 100vh;
`
export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

export const SimplePageContainer = styled.div`
  min-height: 480px;
  display: flex;
  position: relative;
`;

export const BasicContainer = styled.section`
  display: flex;
  position: relative;
`;
