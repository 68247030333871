import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { MyColumnsType } from "screens/accounts_postgre/hooks/useBuyersColumns";
import { ErrorLogsDetails } from "../stores";

export function useErrorLogsDetailsColumns(): ColumnsType<ErrorLogsDetails> {
    const { t } = useTranslation();

    const COLUMNS: MyColumnsType<ErrorLogsDetails> = [
        {
            title: t("ErrorLogs.Columns.interfaceId"),
            dataIndex: 'errorType',
            key: 'errorType',
        },
        {
            title: t("ErrorLogs.Columns.fileLotNumber"),
            dataIndex: 'datasetNumber',
            key: 'datasetNumber',
        },
        {
            title: t("ErrorLogs.Columns.fileCreatedDate"),
            dataIndex: 'creationDateTime',
            key: 'date',
            defaultSortOrder: "descend",
            sorter: true,
            render: value => value ? dayjs(value).format("L LTS") : null,
        },
        {
            title: t("ErrorLogs.Columns.lineType"),
            dataIndex: 'lineType',
            key: 'lineType',
        },
        {
            title: t("ErrorLogs.Columns.errorCode"),
            dataIndex: 'errorCode',
            key: 'errorCode',
        },
        {
            title: t("ErrorLogs.Columns.errorLabel"),
            dataIndex: 'errorLabel',
            key: 'errorLabel',
        },
    ]

    return COLUMNS
}