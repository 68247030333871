import { Action, handleActions } from 'redux-actions'
import { Type } from './actions'
import { ExadisState, UploadFileResponse } from './types'

const INITIAL_STATE: ExadisState = {
    uploadLoading: false,
    navigationSelectedKey: [''],
    showNavigation: false,
}

export default handleActions<ExadisState, any>({
    [Type.UPLOAD_FILE]: (state: ExadisState) => ({
        ...state,
        uploadLoading: true
    }),
    [Type.UPLOAD_FILE_SUCCESS]: (state: ExadisState, action: Action<UploadFileResponse>) => ({
        ...state,
        uploadLoading: false,
        uploadResponse: action.payload
    }),
    [Type.UPLOAD_FILE_ERROR]: (state: ExadisState) => ({
        ...state,
        uploadLoading: false
    }),

    // User PREFs
    [Type.TOGGLE_NAVIGATION_MENU]: (state: ExadisState) => ({
        ...state,
        showNavigation: !state.showNavigation
    }),
    [Type.NAVIGATION_SELECTED_KEY]: (state: ExadisState, action: Action<['']>) => ({
        ...state,
        navigationSelectedKey: action.payload
    })
}, INITIAL_STATE)