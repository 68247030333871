import i18n from 'assets/i18n';
import dayjs from 'dayjs';
import api, { unwrapAxiosResult } from 'shared/utils/api';
import { API_URLs } from 'shared/utils/constants';
import { ID, IPage, IRestData, Payload } from 'shared/utils/types';
import { getArgs, getBirCode } from 'shared/utils/utils';
import { Order, OrderEvents, OrderItem, OrderList, UpdateOrderItems } from './types';

export const ORDERS_URI = {
  BY_SELLER_BIRCODE: (sellerBirCode: string) => `${API_URLs.ORACLE.ORDERS}/search/by-seller-bir-code?sellerBirCode=${sellerBirCode}`,
  RETRANSMITTED_TO_DMS: (orderId: ID) => `${API_URLs.ORACLE.ORDERS}/${orderId}/retry`,
  UPDATE_ORDERS_ITEM_WANTED_QUANTITY: (orderId: ID, orderItemId: ID, wantedQuantity: number) => `${API_URLs.ORACLE.ORDERS}/${orderId}/order-items/${orderItemId}?wantedQuantity=${wantedQuantity}`,
  GET_ORDER_ITEM_BY_ORDER_ID: (orderId: ID) => `${API_URLs.ORACLE.ORDERS}/${orderId}/order-items`,
  GET_ORDER_ITEM_WITH_STOCKS_BY_ORDER_ID: (orderId: ID) => `${API_URLs.ORACLE.ORDERS}/${orderId}/order-items?withStocks=true`,
  GET_ORDER_EVENTS_BY_ORDER_ID: (orderId: ID) => `${API_URLs.ORACLE.ORDERS}/${orderId}/order-events`,
  GET_ORDER_BY_REF: (orderRef: string) => `${API_URLs.ORACLE.ORDERS}/references/${orderRef}`
}

export const getOrders = (payload: Payload): Promise<IPage<Order>> => unwrapAxiosResult(api.get(`${ORDERS_URI.BY_SELLER_BIRCODE(getBirCode()!)}&${getArgs(payload)}`))
export const putOrder = (payload: Order): Promise<Order> => unwrapAxiosResult(api.put(`${API_URLs.ORACLE.ORDERS}/${payload.id}`, payload));
export const putOrders = (payload: OrderList): Promise<OrderList> => unwrapAxiosResult(api.put(API_URLs.ORACLE.ORDERS, payload));
export const retransmissionDMS = (payload: ID): Promise<Order> => unwrapAxiosResult(api.post(ORDERS_URI.RETRANSMITTED_TO_DMS(payload)));
export const orderItemsWantedQuantity = (payload: UpdateOrderItems): Promise<OrderItem> => unwrapAxiosResult(api.patch(ORDERS_URI.UPDATE_ORDERS_ITEM_WANTED_QUANTITY(payload.orderId, payload.orderItemId, payload.fields.wantedQuantity)))
export const getOrderItemsByOrderId = (payload: ID): Promise<IRestData<OrderItem>> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_ITEM_BY_ORDER_ID(payload)))
export const getOrderItemsWithStocksByOrderId = (payload: ID): Promise<IRestData<OrderItem>> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_ITEM_WITH_STOCKS_BY_ORDER_ID(payload)))
export const getOrderEventsByOrderId = (payload: ID): Promise<IRestData<OrderItem>> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_EVENTS_BY_ORDER_ID(payload)))
export const getByRef = (payload: string): Promise<Order> => unwrapAxiosResult(api.get(ORDERS_URI.GET_ORDER_BY_REF(payload)));

export const mapOrderEvents = (orderEvents: OrderEvents[]) => {
  if (orderEvents) {
    const baseEvents: any = orderEvents[0];
    let resEvents: Array<any> = [];
    baseEvents?.eventDate.forEach(
      (eventDate: Date, index: number) => {
        if (eventDate)
          resEvents = [...resEvents, {
            key: index,
            eventDate: dayjs(eventDate).format('L LT'),
            eventType: baseEvents.eventType && baseEvents.eventType[index] ? i18n.t(`Orders.Status.${baseEvents.eventType[index]}`) : null,
            eventComment: baseEvents.eventComment && baseEvents.eventComment[index] ? baseEvents.eventComment[index] : null,
            isNew: baseEvents.isNew && baseEvents?.isNew[index] ? baseEvents.isNew[index] : null,
            orderAccepted: baseEvents.orderAccepted && baseEvents.orderAccepted[index] ? baseEvents.orderAccepted[index] : null
          }]
      }
    )
    return resEvents;
  }
}