import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MassActionModal } from "shared/components/Modal/MassActionModal";
import { accountPostgreActions, accountsSelectors } from "../stores";
import { BuyersTableMassActionModal } from "./BuyersTableMassActionModal";

export const OrderTableMassAction: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const buyersMassActionType = useSelector(accountsSelectors.buyersMassActionType);
    const buyersSelectedRows = useSelector(accountsSelectors.buyersSelectedRows);

    const handleOk = () => {
        dispatch(accountPostgreActions.updateBuyersAdhesion({
            commercialLinkIds: buyersSelectedRows.map(x => x.accountCommercialLinks.id),
            type: buyersMassActionType
        }))

        dispatch(accountPostgreActions.setBuyersSelectedRows([]));
    };

    return <>
        {
            buyersSelectedRows && buyersSelectedRows.length > 0 && <>
                <MassActionModal onOk={handleOk} title={t("Generic.Actions.MassEdit")} >
                    <BuyersTableMassActionModal />
                </MassActionModal>
            </>
        }
    </>
} 