import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableWrapper } from 'shared/components/Styles';
import { Payload } from 'shared/utils/types';
import { handleTableChange } from 'shared/utils/utils';
import { Order, ordersActions, ordersSelectors } from '../stores';
import { OrderTableMassAction } from './OrderTableMassAction';

interface OrdersTableProps {
  columns: ColumnsType<Order>
  setColumns: Dispatch<ColumnsType<Order>>
  payload: Payload
  setPayload: Dispatch<Payload>
  naturalKey: string
}

export const OrdersTable: FC<OrdersTableProps> = ({ payload, setPayload, columns, setColumns, naturalKey }) => {
  const dispatch = useDispatch();

  const { data: currentOrders, loading } = useSelector(ordersSelectors.getOrders);
  const ordersSelectedRows = useSelector(ordersSelectors.ordersSelectedRows);

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    onChange: (_: any, selectedRows: any) => {
      dispatch(ordersActions.setSelectedOrders(selectedRows));
    },
    selectedRowKeys: ordersSelectedRows.map(x => x.id)
  };

  const data = currentOrders?.content.map(item => {

    let res = { ...item }

    return res
  });

  const pagination: TablePaginationConfig = {
    showSizeChanger: true,
    pageSize: currentOrders?.size,
    total: currentOrders?.totalElements,
  };

  if (payload && payload.page) {
    pagination.defaultCurrent = payload.page + 1
  }

  const rowClassName = (record: Order) =>
    `clickable ${record.dmsReemission && (naturalKey === "IN_PROGRESS" || naturalKey === "ALL") ? " orderEventIsLate" : ""}`

  return (
    <TableWrapper>
      <Table
        rowKey="id"
        size="middle"
        loading={loading}
        rowSelection={rowSelection}
        pagination={pagination}
        dataSource={data}
        columns={columns}
        onChange={handleTableChange(payload, setPayload, columns, setColumns)}
        rowClassName={rowClassName}
      />
      <OrderTableMassAction />
    </TableWrapper>
  );
};
