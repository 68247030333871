import { ColumnsType } from 'antd/lib/table';
import { createAction } from 'redux-actions';
import { ActionSuccess, ApiActionLaunch } from 'shared/utils/types';
import { ErrorLogs, ErrorLogsDetails, FetchApiErrorLogs } from './types';

export enum Type {
  GET_ERROR_LOGS = "GET_ERROR_LOGS",
  GET_ERROR_LOGS_DETAILS = "GET_ERROR_LOGS_DETAILS",

  GET_ERROR_LOGS_SUCCESS = "GET_ERROR_LOGS_SUCCESS",
  GET_ERROR_LOGS_DETAILS_SUCCESS = "GET_ERROR_LOGS_DETAILS_SUCCESS",

  GET_ERROR_LOGS_ERROR = "GET_ERROR_LOGS_ERROR",
  GET_ERROR_LOGS_DETAILS_ERROR = "GET_ERROR_LOGS_DETAILS_ERROR",

  SET_ERROR_LOGS_COLUMNS = "SET_ERROR_LOGS_COLUMNS",
  SET_ERROR_LOGS_DETAILS_COLUMNS = "SET_ERROR_LOGS_DETAILS_COLUMNS"
}

export const errorLogsPostgreActions = {
  getErrorLogs: createAction<ApiActionLaunch<FetchApiErrorLogs["getErrorLogs"]>, ApiActionLaunch<FetchApiErrorLogs["getErrorLogs"]>>(Type.GET_ERROR_LOGS, payload => payload),
  getErrorLogsSuccess: createAction<ActionSuccess<FetchApiErrorLogs["getErrorLogs"]>, ActionSuccess<FetchApiErrorLogs["getErrorLogs"]>>(Type.GET_ERROR_LOGS_SUCCESS, payload => payload),
  getErrorLogsError: createAction(Type.GET_ERROR_LOGS_ERROR),

  getErrorLogsDetails: createAction<ApiActionLaunch<FetchApiErrorLogs["getErrorLogsDetails"]>, ApiActionLaunch<FetchApiErrorLogs["getErrorLogsDetails"]>>(Type.GET_ERROR_LOGS_DETAILS, payload => payload),
  getErrorLogsDetailsSuccess: createAction<ActionSuccess<FetchApiErrorLogs["getErrorLogsDetails"]>, ActionSuccess<FetchApiErrorLogs["getErrorLogsDetails"]>>(Type.GET_ERROR_LOGS_DETAILS_SUCCESS, payload => payload),
  getErrorLogsDetailsError: createAction(Type.GET_ERROR_LOGS_DETAILS_ERROR),

  setErrorLogsColumns: createAction<ColumnsType<ErrorLogs>, ColumnsType<ErrorLogs>>(Type.SET_ERROR_LOGS_COLUMNS, payload => payload),
  setErrorLogsDetailsColumns: createAction<ColumnsType<ErrorLogsDetails>, ColumnsType<ErrorLogsDetails>>(Type.SET_ERROR_LOGS_DETAILS_COLUMNS, payload => payload),
}