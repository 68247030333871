import { put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import * as actions from './actions'
import { ErrorLogs, ErrorLogsRequestPayload } from './types';
import { getErrorLogs } from './utils';
import { IPage } from 'shared/utils/types'
import { apiCallWrapper } from 'shared/utils/api';

function* loadErrorLogs(action: Action<ErrorLogsRequestPayload>) {
  try {
    const errorLogsPage: IPage<ErrorLogs> = yield apiCallWrapper<IPage<ErrorLogs>>(getErrorLogs, action.payload)
    yield put(actions.actionCreators.loadLogsSuccess(errorLogsPage))
  } catch (error) {
    yield put(actions.actionCreators.loadLogsError(error))
  }
}

export default function* () {
  yield takeLatest(actions.Type.LOAD_ERROR_LOGS, loadErrorLogs)
}
