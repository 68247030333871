import { createAction } from 'redux-actions';
import { FinancialMonitoringResponse } from './types';

export enum Type {
    LOAD_FINANCIAL_MONITORING_LINKS = "LOAD_FINANCIAL_MONITORING_LINKS",
    LOAD_FINANCIAL_MONITORING_LINKS_SUCCESS = "LOAD_FINANCIAL_MONITORING_LINKS_SUCCESS",
    LOAD_FINANCIAL_MONITORING_LINKS_ERROR = "LOAD_FINANCIAL_MONITORING_LINKS_ERROR",

    TOGGLE_NAVIGATION_MENU = "TOGGLE_NAVIGATION_MENU",
    NAVIGATION_SELECTED_KEY = "NAVIGATION_SELECTED_KEY",
}

export const actionCreators = {
    loadFinancialMonitoringLinks: createAction(Type.LOAD_FINANCIAL_MONITORING_LINKS),
    loadFinancialMonitoringSuccess: createAction<FinancialMonitoringResponse, FinancialMonitoringResponse>(Type.LOAD_FINANCIAL_MONITORING_LINKS_SUCCESS, (financialMonitoringLinks: FinancialMonitoringResponse) => financialMonitoringLinks),
    loadFinancialMonitoringError: createAction(Type.LOAD_FINANCIAL_MONITORING_LINKS_ERROR),

    toggleNavigationOpen: createAction(Type.TOGGLE_NAVIGATION_MENU),
    navigationSelectedKey: createAction<string[], string[]>(Type.NAVIGATION_SELECTED_KEY, (payload: string[]) => payload)
}