import React, { FC } from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { ToggleButton } from 'shared/components/Styles';
import { Action } from 'redux-actions';
import { useLocation } from 'react-router-dom';

const { Sider } = Layout;

interface SiderMenuProps {
    items: MenuProps['items']
    showMenu: boolean
    toggleCollapsed: () => void
    handleClick?: (e: any) => Action<string[]>
    selectedKeys: string[]
}

export const SiderMenu: FC<SiderMenuProps> = ({ items, showMenu, toggleCollapsed, handleClick, selectedKeys }) => {
    let location = useLocation();
    let locate = JSON.stringify(location.pathname.substring(location.pathname.lastIndexOf("/"), location.pathname.length));
    return (
        <Sider trigger={null} collapsible collapsed={showMenu} style={{ backgroundColor: '#fff' }}>
            <ToggleButton
                onClick={toggleCollapsed}
                type="primary"
                $collapsed={showMenu}
            >
                {React.createElement(
                    showMenu ? MenuUnfoldOutlined : MenuFoldOutlined
                )}
            </ToggleButton>
            <Menu
                selectedKeys={[...selectedKeys, locate]}
                onClick={handleClick}
                mode="inline"
                items={items}
            />
        </Sider>
    );
};