import api, { unwrapAxiosResult } from 'shared/utils/api';
import { API_URLs } from 'shared/utils/constants';
import { getArgs } from 'shared/utils/utils';
import { AccountClientCode, FetchApiAccounts } from './types';

const BUYERS_URL = `${API_URLs.POSTGRE.ACCOUNT}/buyers`;
const SELLERS_URL = `${API_URLs.POSTGRE.ACCOUNT}/sellers`;

export const ACCOUNT_POSTGRE_FETCHS: FetchApiAccounts = {
    getBuyers: payload => unwrapAxiosResult(
        api.get(`${SELLERS_URL}/${payload.sellerCode}/buyers?${getArgs(payload)}`)
    ),
    getBuyer: payload => unwrapAxiosResult(
        api.get(`${SELLERS_URL}/${payload.sellerCode}/buyers?sellerCode=${payload.sellerCode}&buyerTresorCode=${payload.buyerTresorCode}`)
    ),
    updateBuyersAdhesion: payload => unwrapAxiosResult(
        api.post(`${API_URLs.POSTGRE.ACCOUNT}/commercial-links?${getArgs(payload)}`)
    ),
    updateBuyers: payload => unwrapAxiosResult(
        api.put(BUYERS_URL, payload)
    ),
    getCountries: () => unwrapAxiosResult(
        api.get(`${API_URLs.POSTGRE.ACCOUNT}/countries`)
    ),
    getSeller: birCode => unwrapAxiosResult(
        api.get(`${SELLERS_URL}/${birCode}`)
    ),
    updateSellers: payload => unwrapAxiosResult(
        api.put(SELLERS_URL, payload)
    )
}

export const getClientCodePrincipal = (clientCodes: AccountClientCode[]) => {
    return clientCodes.filter(x => x.principal).length > 0 ? clientCodes.filter(x => x.principal)[0] : clientCodes[0];
}