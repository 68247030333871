import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionsModal } from "./Styles";

export const MassActionModal: FC<{
    onOk: (() => void) | undefined,
    title: string,
    children: React.ReactNode
}> = ({ onOk, title, children }) => {

    const { t } = useTranslation();
    const [isActionModalVisible, setActionModalVisible] = useState(false);

    const showModal = () => setActionModalVisible(true);
    const hideModal = () => setActionModalVisible(false);

    const handleOk = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        hideModal();
        onOk && onOk();
    }

    const handleCancel = () => hideModal();

    return <>
        <Button
            type="default"
            icon={<PlusCircleOutlined />}
            onClick={showModal}
        >
            {t("Generic.Actions.MassEdit")}
        </Button>
        <ActionsModal
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
            title={title}
            open={isActionModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {children}
        </ActionsModal>
    </>
}