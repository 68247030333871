import { Input, InputRef } from "antd";

interface InputStringFilterDropdownProps {
    ref: { current: InputRef | null }
    value: any
    onChange: any
    onPressEnter: any
    title: string
    style: any
}

const InputStringFilterDropdown = (props: InputStringFilterDropdownProps) => {
    const { ref, value, onChange, onPressEnter, title, style } = props;

    const placeholder = `${title.toLowerCase()} ...`;

    return (
        <Input
            autoFocus
            ref={ref}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onPressEnter={onPressEnter}
            style={style}
        />
    )
}

export default InputStringFilterDropdown

