import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { Dispatch, FC } from "react"
import { useSelector } from "react-redux"
import { handleTableChangeV2 } from "shared/utils/utils"
import { ErrorLogsDetails, GetErrorLogsDetailsPayload, errorLogsSelectors } from "../stores"

interface ErrorLogsDetailsTableProps {
    columns?: ColumnsType<ErrorLogsDetails>
    setColumns: Dispatch<ColumnsType<ErrorLogsDetails>>
    payload?: GetErrorLogsDetailsPayload
    setPayload: Dispatch<GetErrorLogsDetailsPayload>
}

export const ErrorLogsDetailsTable: FC<ErrorLogsDetailsTableProps> = (
    { columns, setColumns, payload, setPayload }
) => {

    const errorLogsDetails = useSelector(errorLogsSelectors.getErrorLogsDetails);
    const errorLogsDetailsData = errorLogsDetails.data;
    const errorLogsDetailsLoading = errorLogsDetails.loading;

    const pagination: TablePaginationConfig = {
        showSizeChanger: true,
        pageSize: errorLogsDetailsData?.size,
        total: errorLogsDetailsData?.totalElements,
        current: errorLogsDetailsData?.number ? errorLogsDetailsData.number + 1 : undefined
    }

    return <Table
        size="middle"
        loading={errorLogsDetailsLoading}
        pagination={pagination}
        dataSource={errorLogsDetailsData?.content}
        columns={columns}
        onChange={handleTableChangeV2(payload, setPayload, columns, setColumns)}
    />
}