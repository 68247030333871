import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MassActionModal } from "shared/components/Modal/MassActionModal";
import { UpdateOrderActionEnum, ordersActions, ordersSelectors } from "../stores";
import { OrderTableMassActionModal } from "./OrderTableMassActionModal";

export const OrderTableMassAction: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ordersSelectedRows = useSelector(ordersSelectors.ordersSelectedRows);
    const [updateOrderStatus, setUpdateOrderStatus] = useState<UpdateOrderActionEnum>(UpdateOrderActionEnum.MARK_AS_READ)

    const handleOk = () => {

        dispatch(ordersActions.updateOrderList({
            orderIds: ordersSelectedRows.map(x => x.id),
            action: updateOrderStatus
        }))

        dispatch(ordersActions.setSelectedOrders([]))
    };

    return <>
        {
            ordersSelectedRows && ordersSelectedRows.length > 0 && <>
                <MassActionModal
                    onOk={handleOk}
                    title={t("Generic.Actions.MassEdit")}
                >
                    <OrderTableMassActionModal selectedRows={ordersSelectedRows} actionType={updateOrderStatus} setActionType={setUpdateOrderStatus} />
                </MassActionModal>
            </>
        }
    </>
} 