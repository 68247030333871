import { Tooltip } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { OrderItem, Warehouse, WarehouseCode, WarehouseCodeLabel } from "../stores/types";
import { ItemsStocksWrapper, StockCaps, ItemsStocksAvailableQuantity } from "../Styles";

interface ItemsStocksProps {
    orderItem: OrderItem
}

export const ItemsStocks: FC<ItemsStocksProps> = ({ orderItem }) => {
    const warehouses = orderItem?.warehouses;
    const { t } = useTranslation();

    if (!orderItem.isStockAvailable || !warehouses)
        return <StockCaps stock={WarehouseCode.DIFF} />

    const getCode = (warehouses: Warehouse[]) => {
        let code: WarehouseCode = WarehouseCode.DMS;
        let label: WarehouseCodeLabel = "DMS";

        warehouses.forEach(x => {
            if (WarehouseCode[x.code] > code) {
                code = WarehouseCode[x.code]
                label = x.code
            }
        })
        return { code, label }
    }

    const { code, label } = getCode(warehouses)
    const LOCAL_WAREHOUSE = warehouses.filter(x => x.code === "DMS");

    return <ItemsStocksWrapper>
        <Tooltip title={t(`Orders.Stock.${label}`)}>
            <StockCaps stock={code} />
        </Tooltip>
        {LOCAL_WAREHOUSE.length > 0 &&
            <ItemsStocksAvailableQuantity>
                {t('Orders.Stock.Available')} : {LOCAL_WAREHOUSE[0].availableQuantity}
            </ItemsStocksAvailableQuantity>
        }
    </ItemsStocksWrapper>
}