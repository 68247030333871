import { ConfigProvider, Spin, theme } from 'antd';
import { useSelector } from 'react-redux';
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AccountsNavigation } from 'screens/accounts_oracle/AccountsNavigation';
import { AccountsTracking } from 'screens/accounts_oracle/pages/AccountsTracking';
import { BuyerDetails } from 'screens/accounts_oracle/pages/BuyerDetails';
import { SellerDetails } from 'screens/accounts_oracle/pages/SellerDetails';
import { myCountriesLoadingSelector } from 'screens/accounts_oracle/stores';
import { DatabaseType, myRoleListSelector } from 'screens/context/stores';
import { FinancialMonitoringNavigation } from 'screens/financialMonitoring/FinancialMonitoringNavigation';
import FinancialMonitoringTracking from 'screens/financialMonitoring/pages/FinancialMonitoringTracking';
import { Home } from 'screens/home/Home';
import { Login } from 'screens/login/Login';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import { NotFound } from 'screens/notFound/NotFound';
import { OidcCallback } from 'screens/oidc/OidcCallback';
import { OidcCallbackSilent } from 'screens/oidc/OidcCallbackSilent';
import { OrdersNavigation } from 'screens/orders_oracle/OrdersNavigation';
import OrderDetails from 'screens/orders_oracle/pages/OrderDetails';
import OrdersTracking from 'screens/orders_oracle/pages/OrdersTracking';
import { StockExchangeNavigation } from 'screens/stockExchange/StockExchangeNavigation';
import StockExchangeLnkTracking from 'screens/stockExchange/pages/StockExchangeLnkTracking';
import StockExchangeTracking from 'screens/stockExchange/pages/StockExchangeTracking';
import { Footer } from 'shared/components/Footer/Footer';
import { Header } from 'shared/components/Header/Header';
import { SecondaryHeader } from 'shared/components/Header/SecondaryHeader';
import { RPAlert } from 'shared/components/RPAlert/RPAlert';
import { LayoutWithSider } from 'shared/layouts/LayoutWithSider';
import './App.less';
import { AuthRequired, BirCodeRequired, CheckMyBirCodeDatabaseType, NotCountrySubscriptionRequired } from './AppRoutes';
import { Container, FullScreenHeight } from './Styles';
import { useConfigTranslate } from './shared/hooks/useConfigTranslate';

import { AccountsNavigation as AccountsNavigationRDS } from 'screens/accounts_postgre/AccountsNavigation';
import { AccountsTracking as AccountsTrackingRDS } from 'screens/accounts_postgre/pages/AccountsTracking';
import { BuyerDetails as BuyerDetailsRDS } from 'screens/accounts_postgre/pages/BuyerDetails';
import { SellerDetails as SellerDetailsRDS } from 'screens/accounts_postgre/pages/SellerDetails';

import { ErrorLogsNavigation } from 'screens/errorLogs_oracle/ErrorLogsNavigation';
import { ErrorLogsTracking } from 'screens/errorLogs_oracle/pages/ErrorLogsTracking';
import { ExadisNavigation } from 'screens/exadis/ExadisNavigation';
import ExadisTracking from 'screens/exadis/pages/ExadisTracking';

import { OrdersNavigation as OrdersNavigationRDS } from 'screens/orders_postgre/OrdersNavigation';
import { OrdersDetails as OrdersDetailsRDS } from 'screens/orders_postgre/pages/OrdersDetails';
import { OrdersTracking as OrdersTrackingRDS } from 'screens/orders_postgre/pages/OrdersTracking';
import { SuperOrderTracking } from 'screens/orders_postgre/pages/SuperOrderTracking';

import { ErrorLogsNavigation as ErrorLogsNavigationRDS } from 'screens/errorLogs_postgre/ErrorLogsNavigation';
import { ErrorLogsDetailsPage as ErrorLogsRDSDetails } from 'screens/errorLogs_postgre/pages/ErrorLogsDetails';
import { ErrorLogsTracking as ErrorLogsRDSTracking } from 'screens/errorLogs_postgre/pages/ErrorLogsTracking';

const AppLayout = () => {

  const { token } = theme.useToken();

  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector)
  const locale = useConfigTranslate();
  const loading = useSelector(myCountriesLoadingSelector)

  return <>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: process.env.NODE_ENV === "development" ? '#FC3' : token.colorPrimary,
          motionDurationSlow: '0.2s',
          motionDurationMid: '0.1s'
        }
      }}
      locale={locale}>
      <FullScreenHeight id="App">
        <RPAlert />
        <Spin spinning={loading}>
          <Container>
            <Header />
            {(!isAuthenticated || roles === undefined) && <SecondaryHeader />}
            <Outlet />
            <Footer />
          </Container>
        </Spin>
      </FullScreenHeight>
    </ConfigProvider>
  </>
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path="/login" element={<Login />} />
      <Route path="/1ss-Oidc-Callback" element={<OidcCallback />} />
      <Route path="/1ss-Oidc-Callback-Silent" element={<OidcCallbackSilent />} />
      <Route element={<AuthRequired />}>
        <Route path="/home" element={<Home />} />
        <Route path='*' element={<NotFound />} />
        <Route element={<BirCodeRequired />}>
          <Route element={<CheckMyBirCodeDatabaseType databaseType={DatabaseType.ORACLE} />}>
            <Route path="/accounts" element={<LayoutWithSider children={<AccountsNavigation />} />}>
              <Route path="tracking" element={<AccountsTracking />} />
              <Route path="tracking/seller" element={<SellerDetails />} />
              <Route path="tracking/buyer/:id" element={<BuyerDetails />} />
            </Route>
            <Route path="/error-logs" element={<LayoutWithSider children={<ErrorLogsNavigation />} />}>
              <Route path="tracking" element={<ErrorLogsTracking />} />
            </Route>
          </Route>

          <Route element={<CheckMyBirCodeDatabaseType databaseType={DatabaseType.POSTGRE} />}>
            <Route path="/accounts-RDS" element={<LayoutWithSider children={<AccountsNavigationRDS />} />}>
              <Route path="tracking" element={<AccountsTrackingRDS />} />
              <Route path="tracking/seller" element={<SellerDetailsRDS />} />
              <Route path="tracking/buyer/:id" element={<BuyerDetailsRDS />} />
            </Route>
            <Route path="/error-logs-RDS" element={<LayoutWithSider children={<ErrorLogsNavigationRDS />} />}>
              <Route path="tracking" element={<ErrorLogsRDSTracking />} />
              <Route path="tracking/details/:errorLogsSlug" element={<ErrorLogsRDSDetails />} />
            </Route>
          </Route>
          <Route element={<NotCountrySubscriptionRequired />} >
            <Route path="/orders" element={<LayoutWithSider children={<OrdersNavigation />} />}>
              <Route path="tracking" element={<OrdersTracking />} />
              <Route path="tracking/:ref" element={<OrderDetails />} />
            </Route>
          </Route>
          <Route path="/orders-RDS" element={<LayoutWithSider children={<OrdersNavigationRDS />} />}>
            <Route path="tracking" element={<OrdersTrackingRDS />} />
            <Route path="tracking/:id" element={<OrdersDetailsRDS />} />
            <Route path="tracking/by-ref/:ref" element={<OrdersDetailsRDS />} />
            <Route path="tracking/by-ref/multiple-orders/:ref" element={<SuperOrderTracking />} />
          </Route>
          <Route path="/stock-exchange" element={<LayoutWithSider children={<StockExchangeNavigation />} />} >
            <Route path="tracking" element={<StockExchangeTracking />} />
            <Route path="tracking/lnk/:countrycode/:ref" element={<StockExchangeLnkTracking />} />
          </Route>
          <Route path="/financial-monitoring" element={<LayoutWithSider children={<FinancialMonitoringNavigation />} />}>
            <Route path="tracking" element={<FinancialMonitoringTracking />} />
          </Route>
          <Route path="/exadis" element={<LayoutWithSider children={<ExadisNavigation />} />}>
            <Route path="tracking" element={<ExadisTracking />} />
          </Route>
        </Route>
      </Route>
    </Route >
  )
)

export default function App() {
  return (
    <RouterProvider router={router}></RouterProvider>
  );
}
