import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { useSelector } from "react-redux";
import { sellerDataSelector, sellerLoadingSelector } from "../stores";
import { CenteredSpin, InfosRow, Field, Value, DetailsCard } from "shared/components/Styles";

export const SellerInfo: FC = () => {
    const { t } = useTranslation();

    const seller = useSelector(sellerDataSelector);
    const loading = useSelector(sellerLoadingSelector);
    if (loading || !seller) return <CenteredSpin size="large" />

    const { postalCode, city, addressLine, tresorCode, birCode, establishmentNumber, commercialName } = seller.garageData;

    return (
        <InfosRow gutter={[8, 8]}>
            <Col>
                <DetailsCard>
                    <Field>
                        <span>{t("Account.Details.CommercialName")} :</span>
                        <Value>{commercialName}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.TresorCode")} :</span>
                        <Value>{tresorCode}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.Bircode")} :</span>
                        <Value>{birCode}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.BuildingNumber")} :</span>
                        <Value>{establishmentNumber}</Value>
                    </Field>
                </DetailsCard>
            </Col>
            <Col>
                <DetailsCard>
                    <Field>
                        <span>{t("Account.Details.Address")} :</span>
                        <Value>{addressLine}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.city")} :</span>
                        <Value>{city}</Value>
                    </Field>
                    <Field>
                        <span>{t("Account.Details.zipcode")} :</span>
                        <Value>{postalCode}</Value>
                    </Field>
                </DetailsCard>
            </Col>
        </InfosRow>
    );
}