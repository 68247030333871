import { FormProps } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UpdateRecordRequest } from "shared/utils/types";
import { DmsR1EntityLogisticTypeEnum, Seller, SellerForm, accountPostgreActions, accountsSelectors } from "../stores";

export function useSellerForm(): FormProps {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    interface IFormProps extends FormProps {
        key?: number // used to refresh UI each time data is submitted
    }

    const { data } = useSelector(accountsSelectors.getSeller);
    const [formProps, setFormProps] = useState<IFormProps>({});
    const [key, setKey] = useState(0);

    useEffect(() => {
        data && setFormProps({
            key,
            validateMessages: {
                required: t("Generic.Alert.RequiredField")
            },
            initialValues: {
                ...data,
                dmsR1OpeningHours: [
                    ...data.dmsR1OpeningHours.map((x, index: number) => {
                        let day = "15";
                        if (index === 1) day = "6"
                        if (index === 2) day = "7"
                        return ({
                            ...x,
                            startTime: dayjs(x.startTime, 'HH:mm:ss'),
                            endTime: dayjs(x.endTime, 'HH:mm:ss'),
                            day
                        })
                    })
                ],
                dmsR1: { ...data.dmsR1, isLogisticDirect: data.dmsR1.logisticType === DmsR1EntityLogisticTypeEnum.DIRECT },
            },
            onFinish: (value: SellerForm) => {
                setKey(key + 1)
                if (data) {
                    const sellers: UpdateRecordRequest<Seller> = {
                        records: [{
                            ...data,
                            ...value,
                            dmsR1OpeningHours: value.dmsR1OpeningHours.reduce((acc: any, p: any, index: number) => {
                                let day = "15";
                                if (index === 1) day = "6"
                                if (index === 2) day = "7"

                                return [
                                    ...acc,
                                    {
                                        startTime: p.startTime.format("HH:mm:ss"),
                                        endTime: p.endTime.format("HH:mm:ss"),
                                        day
                                    }
                                ]
                            }, [])
                        }]
                    };

                    dispatch(accountPostgreActions.updateSellers(sellers))
                }

            },
        })
    }, [data])

    return formProps
}