import { useTranslation } from "react-i18next";

export function useDmsFluxList() {
    const { t } = useTranslation();

    const dmsFluxList = [
        "BTC",
        "TRL"
    ]

    let result: { value: string, label: string }[] = [];

    dmsFluxList.forEach(dmsFlux => {
        result = [
            ...result,
            {
                value: dmsFlux,
                label: t(`Account.DmsFlux.${dmsFlux}`)
            }
        ]
    })

    return result
}