import { FC } from "react";
import { Outlet } from "react-router-dom";
import { BottomPart, Container, MainContainer, MainWrapper, RPNavigation } from "shared/GlobalStyles";

interface Props {
    children?: React.ReactNode
}

export const LayoutWithSider: FC<Props> = ({ children }) => {
    return (
        <MainContainer>
            <MainWrapper>
                <Container>
                    <RPNavigation>
                        {children}
                    </RPNavigation>
                    <Outlet />
                </Container>
                <BottomPart />
            </MainWrapper>
        </MainContainer>
    )
}