import api, { unwrapAxiosResult } from "shared/utils/api";
import { API_URLs } from "shared/utils/constants";
import { ID, IPage, Payload } from "shared/utils/types";
import { getArgs, getBirCode } from "shared/utils/utils";
import { Buyer, BuyerSubscribeRequest, BuyerSubscribeResponse, Country, DmsAccountInfo, Seller } from "./types";

export const ACCOUNTS_URI = {
  DMS_INFO: (id: ID) => `${API_URLs.ORACLE.ACCOUNT}/dms-account-infos/${id}`,
  SELLER_BY_BIRCODE: (birCode: string) => `${API_URLs.ORACLE.ACCOUNT}/sellers?birCode=${birCode}`,
  DMS_R1: (birCode: string) => `${API_URLs.ORACLE.ACCOUNT}/sellers/${birCode}/dms-r1`,
  BUYERS_BY_BIRCODE: (birCode: string, args: string) => `${API_URLs.ORACLE.ACCOUNT}/buyers?birCode=${birCode}&${args}`,
  BUYER_BY_ID: (buyersId: ID, birCode: string) => `${API_URLs.ORACLE.ACCOUNT}/buyers/${buyersId}?birCode=${birCode}`,
  SUBSCRIBE_MASS: `${API_URLs.ORACLE.ACCOUNT}/buyers/subscribe`,
  UNSUBSCRIBE_MASS: `${API_URLs.ORACLE.ACCOUNT}/buyers/unsubscribe`,
  COUNTRIES: `${API_URLs.ORACLE.ACCOUNT}/countries`
}

export const postSubscribeMass = (payload: BuyerSubscribeRequest) =>
  unwrapAxiosResult(api.post<BuyerSubscribeResponse[]>(ACCOUNTS_URI.SUBSCRIBE_MASS, payload));

export const postUnsubscribeMass = (payload: BuyerSubscribeRequest) =>
  unwrapAxiosResult(api.post<BuyerSubscribeResponse[]>(ACCOUNTS_URI.UNSUBSCRIBE_MASS, payload));

export const getCountries = (): Promise<Country[]> =>
  unwrapAxiosResult(api.get(ACCOUNTS_URI.COUNTRIES));

export const getSellerByBirCode = () =>
  unwrapAxiosResult<IPage<Seller>>(api.get<IPage<Seller>>(ACCOUNTS_URI.SELLER_BY_BIRCODE(getBirCode()!)))

export const getBuyersByBirCode = (payload: Payload) =>
  unwrapAxiosResult<IPage<Buyer>>(api.get<IPage<Buyer>>(ACCOUNTS_URI.BUYERS_BY_BIRCODE(getBirCode()!, getArgs(payload))))

export const getBuyerById = (payload: ID) =>
  unwrapAxiosResult<Buyer>(api.get<Buyer>(ACCOUNTS_URI.BUYER_BY_ID(payload, getBirCode()!)))

export const putDmsInfo = (payload: DmsAccountInfo) =>
  unwrapAxiosResult(api.put<DmsAccountInfo>(ACCOUNTS_URI.DMS_INFO(payload.dmsaccountinfoid), payload))

export const patchDmsR1 = (payload: Seller) =>
  unwrapAxiosResult(api.patch<Seller>(ACCOUNTS_URI.DMS_R1(getBirCode()!), payload))