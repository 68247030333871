import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { accountPostgreActions, UpdateBuyerAdhesionEnum, accountsSelectors } from "../stores";
import { Presentation } from "shared/components/Modal/Styles";
import { Select, Space } from "antd";
import { capitalizeFirstLetter } from "shared/utils/utils";

const { Option } = Select;

export const BuyersTableMassActionModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const buyersSelectedRows = useSelector(accountsSelectors.buyersSelectedRows);
    const buyersMassActionType = useSelector(accountsSelectors.buyersMassActionType);

    const handleOnChange = (value: UpdateBuyerAdhesionEnum) =>
        dispatch(accountPostgreActions.setBuyersActionType(value));

    return <Presentation>
        <Space direction="vertical">
            <Select onChange={handleOnChange} defaultValue={buyersMassActionType}>
                <Option value={UpdateBuyerAdhesionEnum.SUBSCRIBE}>
                    {t("Generic.Actions.Subscribe")}
                </Option>
                <Option value={UpdateBuyerAdhesionEnum.UNSUBSCRIBE}>
                    {t("Generic.Actions.Unsubscribe")}
                </Option>
                <Option value={UpdateBuyerAdhesionEnum.ENABLE}>
                    {capitalizeFirstLetter(t("Generic.Actions.enable"))}
                </Option>
                <Option value={UpdateBuyerAdhesionEnum.DISABLE}>
                    {capitalizeFirstLetter(t("Generic.Actions.disable"))}
                </Option>
            </Select>
            {
                buyersSelectedRows.map(buyer => buyer.account.commercialName)
            }
        </Space>
    </Presentation>
}