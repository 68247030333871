import { Tabs, TabsProps } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { PageContainer } from "shared/GlobalStyles"
import { useConfigTranslate } from "shared/hooks/useConfigTranslate"
import { getBirCode } from "shared/utils/utils"
import { ErrorLogsDetailsTable } from "../components/ErrorLogsDetailsTable"
import { useErrorLogsDetailsColumns } from "../hooks/useErrorLogsDetailsColumns"
import { ErrorLogsDetails, GetErrorLogsDetailsPayload, defaultPayload, errorLogsPostgreActions, errorLogsSelectors } from "../stores"

export const ErrorLogsDetailsPage: FC = () => {

    const [datasetNumber, setDatasetNumber] = useState<string>();
    const [errorType, setErrorType] = useState<string>();
    const { errorLogsSlug } = useParams();

    useEffect(() => {
        const splittedArgs = errorLogsSlug?.split("-");
        if (splittedArgs && splittedArgs.length >= 2) {
            setDatasetNumber(splittedArgs[0]);
            setErrorType(splittedArgs[1]);
        }
    }, [])


    const dispatch = useDispatch()
    const { t } = useTranslation()
    const defaultColumns = useErrorLogsDetailsColumns();
    const errorLogsDetailsColumns = useSelector(errorLogsSelectors.errorLogsDetailsColumns)
    const errorLogsDetails = useSelector(errorLogsSelectors.getErrorLogsDetails);

    const columns = errorLogsDetailsColumns ? errorLogsDetailsColumns : defaultColumns;

    const setPayload = (payload: GetErrorLogsDetailsPayload) => { dispatch(errorLogsPostgreActions.getErrorLogsDetails(payload)) }
    const setColumns = (columns: ColumnsType<ErrorLogsDetails>) => { dispatch(errorLogsPostgreActions.setErrorLogsDetailsColumns(columns)) }

    const [key, setKey] = useState(0)

    useEffect(() => {
        setPayload(errorLogsDetails.payload!);
    }, [])

    const locale = useConfigTranslate();

    useEffect(() => {
        handleReinitFilters();
    }, [locale])

    const handleReinitFilters = () => {
        reinitTab()
        setKey(key + 1)
    }

    const reinitTab = () => {
        setColumns(defaultColumns)
        datasetNumber && errorType && setPayload({
            ...defaultPayload,
            sellerCode: getBirCode()!,
            datasetNumber,
            errorType
        })
    }

    const items: TabsProps['items'] = [{
        key: key.toString(),
        label: t("ErrorLogs.Navigation.Tabs"),
        children: <>

            <ErrorLogsDetailsTable
                columns={columns}
                payload={errorLogsDetails.payload}
                setColumns={setColumns}
                setPayload={setPayload}
            />
        </>
    }]

    return <PageContainer>
        <Tabs
            items={items}
        />
    </PageContainer>
}