import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AccountClientCode } from '../stores/types';

export function useBuyersClientCodesColumns(): ColumnsType<AccountClientCode> {
    const { t } = useTranslation()

    const DEFAULT_COLUMNS: ColumnsType<AccountClientCode> = [
        {
            title: t("Account.Columns.BuyersClientCodes.clientcode"),
            dataIndex: 'clientCode',
            key: 'clientCode',
        },
        {
            title: t("Account.Columns.BuyersClientCodes.validitystartdate"),
            dataIndex: 'validityStartDate',
            key: 'validityStartDate',
            render: (value: Date) => value ? dayjs(value).format('L LT') : null,
        },
        {
            title: t("Account.Columns.BuyersClientCodes.validityenddate"),
            dataIndex: 'validityEndDate',
            key: 'validityEndDate',
            render: (value: Date) => value ? dayjs(value).format('L LT') : null,
        },
        {
            title: t("Account.Columns.BuyersClientCodes.creationdate"),
            dataIndex: 'creationDate',
            key: 'creationDate',
            render: (value: Date) => value ? dayjs(value).format('L LT') : null,
        },
        {
            title: t("Account.Columns.BuyersClientCodes.lastupdatedate"),
            dataIndex: 'lastUpdateDate',
            key: 'lastUpdateDate',
            render: (value: Date) => value ? dayjs(value).format('L LT') : null,
        },
    ]
    return DEFAULT_COLUMNS;
}