import { ApiData, ID, IPage, Pageable, UpdateRecordRequest, UpdateRecordResponse } from "shared/utils/types";
import { MyColumnsType } from "../hooks/useBuyersColumns";

export type FetchApiAccounts = {
    getBuyers: (payload: GetBuyersPayload) => Promise<IPage<Buyer>>
    getBuyer: (payload: GetBuyersPayload) => Promise<IPage<Buyer>>
    updateBuyersAdhesion: (payload: UpdateBuyersAdhesion) => Promise<UpdateRecordResponse<Buyer>>
    updateBuyers: (payload: UpdateRecordRequest<Buyer>) => Promise<UpdateRecordResponse<Buyer>>
    getCountries: () => Promise<GetCountriesResponse>
    getSeller: (birCode: string) => Promise<Seller>,
    updateSellers: (payload: UpdateRecordRequest<Seller>) => Promise<UpdateRecordResponse<Seller>>
}

export interface AccountsState {
    getBuyer: ApiData<FetchApiAccounts["getBuyer"]>
    getBuyers: ApiData<FetchApiAccounts["getBuyers"]>
    updateBuyersAdhesion: ApiData<FetchApiAccounts["updateBuyersAdhesion"]>
    updateBuyers: ApiData<FetchApiAccounts["updateBuyers"]>
    getCountries: ApiData<FetchApiAccounts["getCountries"]>
    getSeller: ApiData<FetchApiAccounts["getSeller"]>
    updateSellers: ApiData<FetchApiAccounts["updateSellers"]>

    buyersSelectedRows: Buyer[]
    buyersLastVisitedTab?: string
    buyersColumns?: MyColumnsType<Buyer>
    buyersMassActionType: UpdateBuyerAdhesionEnum
}

export type GetCountriesResponse = {
    countries: Country[]
}

export type Buyer = {
    account: Account
    accountAddrs: AccountAddr[]
    accountClientCodes: AccountClientCode[]
    accountCommercialLinks: AccountCommercialLinks
    dmsAccountInfo: DmsAccountInfo
    countryCode: string
}

export type Seller = {
    account: Account
    accountAddrs: AccountAddr[]
    dmsR1: DmsR1
    dmsR1OpeningHours: DmsR1OpeningHours[]
    dmsR1Users: DmsR1User[]
}

export type SellerForm = {
    account: Account
    accountAddrList: AccountAddr[]
    dmsR1: DmsR1Form
    dmsR1OpeningHours: DmsR1OpeningHours[]
    dmsR1UserList: DmsR1User[]
}

export type DmsR1Form = {
    id: ID

    creationDateTime: Date
    lastUpdateDateTime: Date

    logisticType: DmsR1EntityLogisticTypeEnum

    dmsType: string

    linkType: DmsR1EntityLinkTypeEnum

    regionalStock: DmsR1EntityRegionalStockEnum

    opened24h: boolean
    sendEmailOnEvent: boolean
    forceOrderStatusOnComment: boolean
    active: boolean
}

export type Country = {
    id: ID
    countryCode: string
    currencyCode: string
    countryLogistic: string
    timezone: string
    urgentFlagFeature: boolean
    urgentFlagDefault: boolean
    active: boolean
    accountList: Account[]
}

export interface GetBuyersPayload extends Pageable {
    sellerCode: string
    organizationName?: string
    tresorCode?: string
    clientCode?: string
    buyerBirCode?: string
    buyerTresorCode?: string
}

export interface UpdateBuyersAdhesion {
    commercialLinkIds: ID[]
    type: UpdateBuyerAdhesionEnum
}

export type Account = {
    id: ID
    creationDateTime: Date
    lastUpdateDateTime: Date
    tresorCode: string
    sellerCode: string
    localEntityNumber: string
    establishmentNumber: string
    organizationName: string
    commercialName: string
    city: string
    r1: boolean
}

export type AccountAddr = {
    id: ID
    creationDateTime: Date
    lastUpdateDateTime: Date
    address1: string
    address2: string
    address3: string
    address4: string
    city: string
    postalCode: string
    territorialDivision: string
    phone: string
    fax: string
    mail: string
    addressType: string
    principal: boolean
}

export type AccountClientCode = {
    id: ID
    creationDateTime: Date
    lastUpdateDateTime: Date
    validityStartDate: Date
    validityEndDate: Date
    clientCode: string
    principal: boolean
}

export type AccountCommercialLinks = {
    id: ID
    sellerId: ID
    buyerId: ID
    creationDateTime: Date
    lastUpdateDateTime: Date
    validityStartDate: Date
    validityEndDate: Date
    regionalDirectorate: string
    type: string
    subscribeAllowed: boolean
    unsubscribeAllowed: boolean
    active: boolean
}

export type DmsAccountInfo = {
    id: ID
    creationDateTime: Date
    lastUpdateDateTime: Date
    deliveryMethod: string
    automaticOrderTransfer: boolean
    manageCreditLimit: boolean
    maxOrderAmount: number
    maxInvoiceAmount: number
    actualInvoiceAmount: number
}

export type DmsR1 = {
    id: ID
    creationDateTime: Date
    lastUpdateDateTime: Date
    logisticType: DmsR1EntityLogisticTypeEnum
    dmsType: string
    linkType: DmsR1EntityLinkTypeEnum
    regionalStock: DmsR1EntityRegionalStockEnum
    opened24h: boolean
    sendEmailOnEvent: boolean
    forceOrderStatusOnComment: boolean
    active: boolean
}

export type DmsR1OpeningHours = {
    id: ID
    day: number
    startTime: string
    endTime: string
}

export type DmsR1User = {
    id: ID
    lastname: string
    firstname: string
    phone: string
    email: string
    receiveEmailOnEvent: string
}

export enum DmsR1EntityLogisticTypeEnum {
    WHOLESALER = "WHOLESALER",
    DIRECT = "DIRECT"
}

export enum DmsR1EntityLinkTypeEnum {
    API = "API",
    FLATFILES = "FLATFILES"
}

export enum DmsR1EntityRegionalStockEnum {
    RPSDISBE = "RPSDISBE",
    RPSDISPL = "RPSDISPL",
    RPSDISRO = "RPSDISRO",
    RPSDISRU = "RPSDISRU",
    RPSDISUK = "RPSDISUK",
    DPRDIS = "DPRDIS",
    RPSDISTR = "RPSDISTR",
    RPSDISDE = "RPSDISDE",
    ORDENAL = "ORDENAL",
    RPSDISNL = "RPSDISNL",
    RPSDISAT = "RPSDISAT",
    RPSDISIT = "RPSDISIT",
    ORDENALPT = "ORDENALPT",
    LOCAL = "LOCAL"
}

export enum UpdateBuyerAdhesionEnum {
    SUBSCRIBE = "SUBSCRIBE",
    UNSUBSCRIBE = "UNSUBSCRIBE",
    ENABLE = "ENABLE",
    DISABLE = "DISABLE"
}