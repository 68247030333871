import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { useSellersColumns } from '../hooks/useSellersColumns';
import { accountsSelectors, Seller } from "../stores"

export const SellerTable: FC = () => {
  const columns = useSellersColumns();
  const seller = useSelector(accountsSelectors.getSeller)
  const pagination = false

  return <Table
    size="middle"
    loading={seller.loading}
    dataSource={seller.data ? [seller.data] : []}
    pagination={pagination}
    columns={columns}
    rowClassName={"clickable"}
    rowKey={(record: Seller) => record.account.id}
  />
};
