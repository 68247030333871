import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountOracleActions, myCountriesDataSelector, myCountriesLoadingSelector } from 'screens/accounts_oracle/stores';
import { BIRCodeDataListSelector, myRoleListSelector, myRolePrivilegeSelector, RolePrivilege } from 'screens/context/stores';
import { loginActions } from 'screens/login/stores/actions';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import * as utils from 'screens/login/stores/utils';
import { alertActions } from 'shared/components/RPAlert/stores/actions';
import { getBirCode } from 'shared/utils/utils';
import {
  ContentWrapper, PrimaryHeader
} from '../Styles';
import { HeaderApp } from './HeaderApp';
import { HeaderLogin } from './HeaderLogin';

/**
 * Black Header Component
 */
export const Header: FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(userIsAuthenticated);
  const roles = useSelector(myRoleListSelector);
  const rolePrivilege = useSelector(myRolePrivilegeSelector);
  const countriesOracle = useSelector(myCountriesDataSelector);
  const countriesLoadingOracle = useSelector(myCountriesLoadingSelector);
  const BIRCodeDataList = useSelector(BIRCodeDataListSelector);

  useEffect(() => {

    if (!countriesOracle && !countriesLoadingOracle && isAuthenticated && roles) {
      dispatch(accountOracleActions.loadCountries());
    }

    if (isAuthenticated && roles && rolePrivilege === RolePrivilege.R1) {
      let disconnect = true;
      const bircode = getBirCode();

      if (bircode && BIRCodeDataList) {
        BIRCodeDataList.forEach((bircodeData) => {
          if (bircodeData.bircode === bircode) disconnect = false;
        })

        if (disconnect) {
          dispatch(loginActions.authLogout())
          dispatch(alertActions.alertMsg(utils.createAlert(
            {
              message: {
                statusText: "Unauthorized"
              }
            }
          )))
        }
      }

    }
  }, [
    // countriesPostgre,
    countriesOracle,
    isAuthenticated,
    roles,
    rolePrivilege,
  ])

  return (
    <PrimaryHeader>
      <ContentWrapper>
        {!isAuthenticated || roles === undefined ? <HeaderApp /> : <HeaderLogin />}
      </ContentWrapper>
    </PrimaryHeader>
  );
};
