import { InputRef, Space, Tag } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { useTranslation } from "react-i18next";
import { ButtonSearch, ButtonReset } from "shared/components/Filters/Buttons";
import { OrderStatus, OrderStatusColors } from "../stores";
import { StatusFilterWrapper, StatusFilterList, StatusFilterInput } from "../Styles";

export function useOrderStatusFilterDropdown() {
    const { t } = useTranslation();

    const searchInput: { current: InputRef | null } = { current: null };
    let orderStatus: OrderStatus[] = [];

    for (const status in OrderStatus) {
        if (!Number.isNaN(Number(status))) orderStatus = [...orderStatus, Number(status)]
    }

    function handleSearch(_: any, confirm: any) {
        confirm();
    };

    function handleReset(clearFilters: any) {
        clearFilters();
    };

    return {
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }: FilterDropdownProps) => (
            <div style={{
                padding: 8,
                maxHeight: 250,
                maxWidth: 228,
                overflowY: "scroll",
                overflowX: "hidden"
            }}>

                <StatusFilterWrapper style={{}}>
                    <Space direction='vertical'>
                        {
                            orderStatus.map((status: OrderStatus, index: number) => {
                                const statusLabel = t(`Orders.StatusName.${status}`)
                                return (
                                    <StatusFilterList key={index}>
                                        <StatusFilterInput
                                            checked={selectedKeys[0] === status.toString()}
                                            type='radio'
                                            name='status-filter'
                                            value={status}
                                            onChange={(e: any) => setSelectedKeys(e.currentTarget.value ? [e.currentTarget.value] : [])}
                                        />
                                        <Tag key={status} color={OrderStatusColors[status]} title={statusLabel}>{statusLabel}</Tag>
                                    </StatusFilterList>
                                )
                            })
                        }
                    </Space>
                </StatusFilterWrapper>
                <Space>
                    <ButtonSearch onClick={() => handleSearch(selectedKeys, confirm)} />
                    <ButtonReset onClick={() => handleReset(clearFilters)} />
                </Space>
            </div>
        ),

        onFilter: (value: any) => {
            return value
        },

        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput && searchInput.current && searchInput.current?.select(), 100);
            }
        }
    }


}