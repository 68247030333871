import { Action, handleActions } from 'redux-actions';
import { MyColumnsType } from 'screens/accounts_postgre/hooks/useBuyersColumns';
import { FetchReduceFn } from 'shared/utils/types';
import { fetchReduceFn } from 'shared/utils/utils';
import { Type } from './actions';
import { ErrorLogs, ErrorLogsDetails, ErrorLogsState, FetchApiErrorLogs } from './types';

export const defaultPayload = {
  page: 0,
  size: 20,
  sort: "date,desc"
}

const INITIAL_STATE: ErrorLogsState = {
  getErrorLogs: { data: undefined, loading: false, payload: undefined },
  getErrorLogsDetails: { data: undefined, loading: false, payload: undefined },

  errorLogsColumns: undefined,
  errorLogsDetailsColumns: undefined,
}

const fetchReduce: FetchReduceFn<FetchApiErrorLogs> = fetchReduceFn

export default handleActions<ErrorLogsState, any>(
  {
    ...fetchReduce({
      param: "getErrorLogs",
      start: Type.GET_ERROR_LOGS,
      success: Type.GET_ERROR_LOGS_SUCCESS,
      error: Type.GET_ERROR_LOGS_ERROR
    }),

    ...fetchReduce({
      param: "getErrorLogsDetails",
      start: Type.GET_ERROR_LOGS_DETAILS,
      success: Type.GET_ERROR_LOGS_DETAILS_SUCCESS,
      error: Type.GET_ERROR_LOGS_DETAILS_ERROR
    }),

    [Type.SET_ERROR_LOGS_COLUMNS]: (state: ErrorLogsState, action: Action<MyColumnsType<ErrorLogs>>) => ({
      ...state,
      errorLogsColumns: action.payload
    }),

    [Type.SET_ERROR_LOGS_DETAILS_COLUMNS]: (state: ErrorLogsState, action: Action<MyColumnsType<ErrorLogsDetails>>) => ({
      ...state,
      errorLogsDetailsColumns: action.payload
    }),
  },
  INITIAL_STATE
)