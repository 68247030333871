import { Button, Card, Form, Input } from "antd";
import { Dispatch, FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StockExchangeRequestPayload, stockExchangePayloadSelector } from "../stores";

interface StockExchangeFormProps {
    payload: StockExchangeRequestPayload
    setPayload: Dispatch<StockExchangeRequestPayload>
}

export const StockExchangeForm: FC<StockExchangeFormProps> = ({ payload, setPayload }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleOnFinish = (values: any) => {
        Object.keys(values).forEach(key => {
            const value = values[key];
            if (!value) {
                delete values[key]
                delete payload[key]
            }
        })
        setPayload({
            ...payload,
            ...values
        })
    }

    const stockExchangePayload = useSelector(stockExchangePayloadSelector);

    return <Card style={{ margin: "15px 0px" }}>
        <Form
            form={form}
            onFinish={handleOnFinish}
            layout="inline"
            initialValues={{
                productReference: stockExchangePayload.productReference,
                shortName: stockExchangePayload.shortName,
                countrycode: stockExchangePayload.countrycode,
            }}
        >
            <Form.Item label={t("StockExchange.Columns.productReference")} name="productReference">
                <Input />
            </Form.Item>

            <Form.Item label={t("StockExchange.Columns.shortName")} name="shortName">
                <Input />
            </Form.Item>

            <Form.Item label={t("StockExchange.Columns.countrycode")} name="countryCode">
                <Input />
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" type="primary">{t("Generic.Actions.Validate")}</Button>
            </Form.Item>
        </Form>
    </Card>
}