import { Button, Tabs } from "antd"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { myCountriesDataSelector } from "screens/accounts_oracle/stores"
import { PageContainer } from "shared/GlobalStyles"
import { getBirCode } from "shared/utils/utils"
import { FinancialMonitoringLinksList } from "../components/FinancialMonitoringLinksList"
import { actionCreators } from "../stores"

const FinancialMonitoringTracking: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const birCode = getBirCode();
    const countries = useSelector(myCountriesDataSelector)

    const handleLoadFinancialMonitoringLinks = () => {
        dispatch(actionCreators.loadFinancialMonitoringLinks())
    }

    useEffect(() => {
        if (birCode && countries) {
            handleLoadFinancialMonitoringLinks();
        }
    }, [birCode, countries])

    const tabBarExtraContent = <Button onClick={handleLoadFinancialMonitoringLinks}>{t("Generic.Actions.Refresh")}</Button>

    return <PageContainer>
        <Tabs items={[{
            key: '1',
            label: t("FinancialMonitoring.Navigation.Tabs"),
            children: <FinancialMonitoringLinksList />
        }]} tabBarExtraContent={tabBarExtraContent} />
    </PageContainer>
}

export default FinancialMonitoringTracking