import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { ErrorLogs } from "../stores/types";
import dayjs from 'dayjs';

export function useErrorLogsColumns(): ColumnsType<ErrorLogs> {
    const { t } = useTranslation();

    const COLUMNS: ColumnsType<ErrorLogs> = [
        // {
        //     title: t("ErrorLogs.Columns.r1GarageId"),
        //     dataIndex: 'r1GarageId',
        //     key: 'r1GarageId'
        // },
        {
            title: t("ErrorLogs.Columns.interfaceId"),
            dataIndex: 'interfaceId',
            key: 'interfaceId',
            // ...useErrorLogsInterfaceCodeFilterDropdown()
        },
        {
            title: t("ErrorLogs.Columns.fileLotNumber"),
            dataIndex: 'fileLotNumber',
            key: 'fileLotNumber',
            // ...stringFilterDropdown('ErrorLogs.Columns.fileLotNumber')
        },
        {
            title: t("ErrorLogs.Columns.fileCreatedDate"),
            dataIndex: 'fileCreatedDate',
            key: 'fileCreatedDate',
            defaultSortOrder: "descend",
            sorter: true,
            render: value => value ? dayjs(value).format("L LTS") : null,
            // ...dateFilterDropdown('ErrorLogs.Columns.fileCreatedDate')
        },
        {
            title: t("ErrorLogs.Columns.fileSize"),
            dataIndex: 'fileSize',
            key: 'fileSize',
        },
        {
            title: t("ErrorLogs.Columns.linesInFile"),
            dataIndex: 'linesInFile',
            key: 'linesInFile',
        },
        {
            title: t("ErrorLogs.Columns.linesInError"),
            dataIndex: 'linesInError',
            key: 'linesInError',
        },
        {
            title: t("ErrorLogs.Columns.fileStatus"),
            dataIndex: 'fileStatus',
            key: 'fileStatus',
            // ...stringFilterDropdown('ErrorLogs.Columns.fileStatus')
        },
        {
            title: t("ErrorLogs.Columns.fileErrorComment"),
            dataIndex: 'fileErrorComment',
            key: 'fileErrorComment',
            render: value => <div style={{ maxWidth: '280px' }}>{value}</div>
        },
    ]

    return COLUMNS;
}