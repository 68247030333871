import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FooterContainer,
  FooterEndContainer,
  GDPR,
  TermsOfService,
  Cookies,
  Copyright
} from '../Styles';
import { RPARTS_DOCUMENTATION_EN, RPARTS_DOCUMENTATION_FR } from 'shared/utils/constants';

const {
  REACT_APP_GDPR_URI,
  REACT_APP_TERMS_URI,
  REACT_APP_COOKIES_URI
} = process.env;

/**
 * Black Footer Component
 */
export const Footer: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <FooterContainer>
      <FooterEndContainer>
        <Link to={{ pathname: REACT_APP_GDPR_URI }} target="_blank">
          <GDPR>
            {t("Generic.footer.GDPR")}
          </GDPR>
        </Link>
        <Link to={{ pathname: REACT_APP_TERMS_URI }} target="_blank">
          <TermsOfService>
            {t("Generic.footer.termsOfService")}
          </TermsOfService>
        </Link>
        <Link to={{ pathname: REACT_APP_COOKIES_URI }} target="_blank">
          <Cookies>
            {t("Generic.footer.cookies")}
          </Cookies>
        </Link>
        <Link to={{ pathname: i18n.language === 'fr-FR' ? RPARTS_DOCUMENTATION_FR : RPARTS_DOCUMENTATION_EN }} target="_blank">
          <Cookies>
            {t("Generic.footer.Documentation")}
          </Cookies>
        </Link>
        <Copyright>{t("Generic.footer.copyright")}</Copyright>
      </FooterEndContainer>
    </FooterContainer>
  );
};
