import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TroncatedText } from "shared/components/TroncatedText/TroncatedText";
import { DataWithRender } from "shared/utils/types";
import { OrderStatusTag } from "../components/OrderStatusTag";
import { ordersSelectors } from "../stores";

export function useOrderDetailsInfo() {
    const { t } = useTranslation();
    const { data } = useSelector(ordersSelectors.getOrder);
    const order = data?.content[0]

    const displayDate = () =>
        order?.lastUpdateDateTime
            ? dayjs(order?.lastUpdateDateTime).format('L LT')
            : dayjs(order?.creationDateTime).format('L LT');

    const orderDetailsMainInfos: DataWithRender[] = [
        {
            label: t("Account.Columns.bircode"),
            value: order?.sellerCode
        },
        {
            label: t("Orders.Details.OrderNum"),
            value: order?.orderRef
        },
        !!order && !!order.superOrderRef ? {
            label: "RPartStore",
            value: order?.superOrderRef
        } : { label: "", void: true },
        {
            label: t("Orders.Columns.takenIntoAccount"),
            value: order?.takenIntoAccount ? t("Generic.Actions.Yes") : t("Generic.Actions.No")
        },
        {
            label: t("Orders.Columns.dmsnbr"),
            value: order?.buyerDmsClientCode
        },
        {
            label: t("Orders.Details.DateHours"),
            value: displayDate()
        },
        {
            label: t("Orders.Details.OrderedBy"),
            value: order?.buyerName
        },
        {
            label: t("Orders.Details.TreatedBy"),
            value: order?.sellerName
        }
    ].filter(x => !x.void);
    const orderDetailsDeliveryInfos: DataWithRender[] = [
        {
            label: t("Orders.Details.Status"),
            value: order && t(`Orders.StatusName.${order.status}`),
            render: order && <OrderStatusTag status={order.status} />
        },
        {
            label: t("Orders.Details.Delivery"),
            value: t(`Generic.DeliveryMethod.${order?.deliveryMethod}`)
        },
        {
            label: t("Orders.Details.OrderMark"),
            value: order?.orderComment
        },
        {
            label: t("Orders.Columns.message"),
            value: order?.messageToSeller,
            render: order && <TroncatedText value={order.messageToSeller} />
        },
        {
            label: t("Orders.Columns.R1TreatedBy"),
            value: order && order.sellerTreatedByFirstname && order.sellerTreatedByLastname ?
                `${order?.sellerTreatedByFirstname} ${order?.sellerTreatedByLastname}` : ""
        },
    ]

    return {
        orderDetailsMainInfos,
        orderDetailsDeliveryInfos
    }
}