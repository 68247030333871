import { FC } from "react"
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { OrderStatus, OrdersStatusColors } from "../stores/types";

interface OrderStatusTagProps {
  status: OrderStatus;
}

export const OrderStatusTag: FC<OrderStatusTagProps> = ({ status }) => {
  const { t } = useTranslation();
  const color: any = OrdersStatusColors[status]

  const statusLabel = t(`Orders.StatusName.${status}`)

  return (
    <Tag
      color={color}
      key={status}
      title={statusLabel}
    >
      {statusLabel}
    </Tag>
  );
};