import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { apiCallWrapper } from 'shared/utils/api';
import { ApiActionLaunch, IPage } from 'shared/utils/types';
import * as actions from './actions';
import { errorLogsPostgreActions } from './actions';
import { ErrorLogs, ErrorLogsDetails, FetchApiErrorLogs } from './types';
import { ERRORLOGS_POSTGRE_FETCHS } from './utils';

function* getErrorLogs(action: Action<ApiActionLaunch<FetchApiErrorLogs["getErrorLogs"]>>) {
  try {
    const errorLogs: IPage<ErrorLogs> = yield apiCallWrapper(ERRORLOGS_POSTGRE_FETCHS.getErrorLogs, action.payload)
    yield put(errorLogsPostgreActions.getErrorLogsSuccess(errorLogs))
  } catch (error) {
    yield put(errorLogsPostgreActions.getErrorLogsError(error))
  }
}

function* getErrorLogsDetails(action: Action<ApiActionLaunch<FetchApiErrorLogs["getErrorLogsDetails"]>>) {
  try {
    const errorLogsDetails: IPage<ErrorLogsDetails> = yield apiCallWrapper(ERRORLOGS_POSTGRE_FETCHS.getErrorLogsDetails, action.payload)
    yield put(errorLogsPostgreActions.getErrorLogsDetailsSuccess(errorLogsDetails))
  } catch (error) {
    yield put(errorLogsPostgreActions.getErrorLogsDetailsError(error))
  }
}

export default function* () {
  yield takeLatest(actions.Type.GET_ERROR_LOGS, getErrorLogs)
  yield takeLatest(actions.Type.GET_ERROR_LOGS_DETAILS, getErrorLogsDetails)
}
